@import "../config/*";

#flow_purchase {
  .page_img_wrap {
    .txt_box {
      margin-bottom: 24px;
      padding-top: 16px;

      p {
        font-size: 15px;
      }
    }
  }

  .flow_purchase_block {
    margin-bottom: 50px;
  }

  .g_frame_double,
  .g_frame_single {
    .g_frame {
      border: 7px solid $COLOR_PRIMARY;
      border-radius: 5px;
      border-top: 0;
      flex: 1;
      overflow: hidden;

      &:not(:first-child) {
        @include MQ("pc"){
          margin-left: 20px;
        }

        @include MQ("sp"){
          margin-top: 20px;
        }
      }

      h3 {
        background-color: $COLOR_PRIMARY;
        color: #ffffff;
        font-size: 40px;
        font-weight: bold;
        padding: 10px 10px 6px 10px;

        @include MQ("sp"){
          font-size: 26px;
        }

        span {
          margin-right: 25px;
          vertical-align: middle;

          @include MQ("sp"){
            margin-right: 5px;
          }

          img {
            height: 42px;
            width: auto;

            @include MQ("sp"){
              height: 35px;
            }
          }

        }

      }

      .txt_box {
        @include MQ("pc") {
          padding: 20px 24px 30px 24px;
        }

        @include MQ("sp") {
          padding: 20px;
        }

        .green_txt {
          font-size: 28px;
          margin-bottom: 15px;

          @include MQ("sp"){
            font-size: 23px;
          }
        }

        ul{
          li{
            margin-top: 0;
            padding-left: 1em;
            text-indent: -1em;

            @include MQ("pc") {
              letter-spacing: -0.8px;
            }

          }
        }

      }

    }

  }

  .g_frame_double {
    display: flex;

    @include MQ("sp"){
      flex-direction: column;
    }
  }

  .g_frame_single{
    .g_frame{
      border-radius: 20px;

      h3 {
        @include MQ("pc"){
          font-size: 53px;
          text-align: center;
        }

        @include MQ("sp"){
          font-size: 23px;
        }
      }
    }

  }

  .green_bdr_txt{
    @include MQ("pc") {
      margin-bottom: 40px;
    }

  }

  .flow_purchase_list {
    ul {
      display: flex;

      @include MQ("sp"){
        flex-wrap: wrap;
        justify-content: space-between;
      }

      .flow_purchase_item {
        &:not(:first-child) {
          margin-left: 15px;

          @include MQ("sp"){
            margin-left: 0;
          }
        }

        &:nth-of-type(n+5){
          @include MQ("sp") {
            margin-top: 15px;
          }

        }

        @include MQ("sp"){
          width: calc(25% - 7.5px);
        }

        p {
          text-align: center;

          @include MQ("sp"){
            font-size: 13px;
          }
        }
      }
    }

    .txt_box {
      color: #ce0000;
      margin-top: 30px;

      p {
        font-size: 15.5px;
      }
    }

  }

  .flow_purchase_row {
    display: flex;

    @include MQ("sp"){
      flex-direction: column;
    }
  }

  .flow_purchase_exteria {
    flex: 1;
    text-align: center;

    &:not(:first-child){
      @include MQ("pc"){
        margin-left: 23px;
      }

      @include MQ("sp"){
        margin-top: 20px;
      }
    }

    .green_txt{
      @include MQ("pc") {
        font-size: 28px;
        margin-bottom: 20px;
      }

    }

    .img_box {
      .img_box_contents{
        img {
          vertical-align: bottom;
        }

      }

    }

    .g_bg_list {
      ul {
        li {
          align-items: center;
          background-color: #c1f0e3;
          border-radius: 5px;
          display: flex;
          font-weight: bold;
          height: 77px;
          justify-content: center;
          vertical-align: baseline;

          &:not(:first-child){
            margin-top: 10px;
          }

          @include MQ("sp"){
            height: 90px;
            padding: 0 10px;
          }

        }

      }

    }
  }

  .btn_c {
    display: flex;
    justify-content: center;
    margin-top: 70px;

    .btn-org-md {
      border-radius: 10px;
      position: relative;

      @include MQ("pc"){
        font-size: 25px;
        height: 84px;
        max-width: 470px;
        padding: 28px 0 28px 40px;
      }

      @include MQ("sp"){
        padding: 12px 0;
      }

      &::before {
        background-image: url("../../../images/portal/flow_purchase/flow_purchase_btn_icon.png");
        background-repeat: no-repeat;
        background-size: auto 47px;
        content: "";
        height: 46px;
        left: 33px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 46px;

        @include MQ("sp"){
          background-size: auto 20px ;
          flex-direction: column;
          height: 20px;
          left: 27%;
        }
      }

    }

  }

  .question_list{
    li{
      pointer-events: none;

      @include MQ("pc") {
        padding-top: 12px;
      }

      h4{
        opacity: 1;
        padding-left: 2em;
        text-indent: -2em;

        @include MQ("pc") {
          font-size: 23px;
          letter-spacing: -0.5px;
        }

      }

    }

  }

}
