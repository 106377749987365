/* ==================================================
  カラー
================================================== */

$COLOR_WHITE: #ffffff;
$COLOR_BLACK: #1e1e1e;
$COLOR_GRAY: #777777;
$COLOR_ORANGE: #ff6f21;
$COLOR_RED: #ff5f44;
$COLOR_GREEN: #6abf6a;
$COLOR_PRIMARY: #35b597;
$COLOR_BORDER: #dddddd;
$COLOR_BASE: #f1f1f1;

/* ==================================================
  シャドウ
================================================== */

$SHADOW_B: 0 1px 5px rgba(0, 0, 0, 0.2);
$SHADOW_R: 1px 0 5px rgba(0, 0, 0, 0.2);

/* ==================================================
  ブレイクポイント
================================================== */

$BLAKE_POINT: 769px;
