@import "../config/*";

/* ==================================================
   エラー
================================================== */

.error {
  background: #ffdddd;
  color: #ff0000;
  line-height: 2.5em;
  margin-bottom: 30px;
  padding: 20px 40px;

  @include MQ("sm") {
    margin-bottom: 20px;
    padding: 20px;
  }

}
