@import "../config/*";

.question_unker_list{
  overflow: hidden;

  @include MQ("pc") {
    margin: 0 auto 50px;
    width: 980px;
  }

  @include MQ("sp") {
    margin-bottom: 20px;
  }

  li{
    border-radius: 6px;
    float: left;
    overflow: hidden;

    @include MQ("pc") {
      margin-right: 40px;
      width: 300px;
    }

    @include MQ("sp") {
      margin-bottom: 10px;
      width: 100%;
    }

    &:last-child{
      margin-right: 0;
    }

    a{
      background-color: #56b298;
      color: #ffffff;
      display: block;
      font-weight: bold;
      text-align: center;

      @include MQ("pc") {
        font-size: 18px;
        line-height: 46px;
      }

      @include MQ("sp") {
        line-height: 40px;
      }

    }

  }

}

.question_ttl{
  margin-bottom: 20px;
}

.question_list{
  li{
    border-bottom: 1px solid #dddddd;
    cursor: pointer;
    padding-bottom: 12px;
    padding-top: 10px;

    &:hover{
      .green_txt{
        opacity: 0.5;
        -webkit-transition: all 0.15s ease;
        transition: all 0.15s ease;
      }

    }

    .green_txt{
      display: block;
      margin-bottom: 0;

      @include MQ("sp") {
        padding-left: 1.8em;
        text-indent: -1.8em;
      }

    }

    p{
      display: none;
      margin-bottom: 5px;
      margin-top: 15px;
    }

    &:first-child{
      padding-top: 0;
    }

    &.active{
      p{
        display: block;
      }

    }

  }

}

#question{
  .block{
    @include MQ("pc") {
      margin-bottom: 60px;
    }

  }

}
