@import "../config/*";

body {
  color: #1e1e1e;
  font-family: "Noto Sans JP", sans-serif;
  position: relative;
  -webkit-text-size-adjust: 100%;
  word-break: break-all;

  @include MQ("pc") {
    line-height: 1.7;
    min-width: 1100px;
  }

  @include MQ("sp") {
    font-size: 16px;
    line-height: 1.3;
    overflow-x: hidden;
  }

}

.wrapper{
  margin: 0 auto;

  @include MQ("pc") {
    max-width: 1100px;
  }

  @include MQ("sp") {
    padding: 0 10px 0;
    width: 100%;
  }

}

p, td, th, li {
  font-size: 16px;

  @include MQ("sp"){
    line-height: 1.5;
  }
}

p + p {
  margin-top: 1.6rem;
}

a{
  color: #1e1e1e;
  text-decoration: none;

  &:hover{
    opacity: 0.5;
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }

}

img {
  max-width: 100%;
}

input[type="submit"],
input[type="button"],
input[type="text"],
button,
select,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  font-size: 16px;
  outline: none;
}

textarea {
  padding: 10px;
  resize: vertical;
  width: 100%;
}

input[type="text"],
textarea{
  border: 1px solid #d9d9d9;
  border-radius: 0;

  @include MQ("sp") {
    display: block;
    padding: 10px;
    width: 100%;
  }

}


table{
  width: 100%;

  tr{
    @include MQ("sp") {
      border-left: solid 1px #dddddd;
      border-right: solid 1px #dddddd;
    }

    &:last-child{
      @include MQ("sp") {
        border-bottom: solid 1px #dddddd;
      }
    }

    th,td{
      vertical-align: middle;

      @include MQ("sp") {
        padding: 15px 15px;
        text-align: left;
      }

    }

    th{
      background-color: #d8f8ef;
      font-size: 14px;
      font-weight: bold;

      @include MQ("pc") {
        border: solid 1px #dddddd;
        padding: 12px 15px;
        text-align: left;
        width: 215px;
      }

      @include MQ("sp") {
        border-bottom: solid 1px #dddddd;
        border-top: solid 1px #dddddd;
      }

    }

    td{
      font-size: 16px;
      padding: 15px 15px;

      @include MQ("pc") {
        border: solid 1px #dddddd;
        width: calc(50% - 214px);
      }

    }

  }

  tr,th,td{
    @include MQ("sp") {
      display: block;
      width: 100%;
    }

  }

  &.type_b{
    tr{
      @include MQ("sp") {
        border-left: none;
        border-right: none;
      }
      
    }

    th,td{
      border-left: none;
      border-right: none;
      
      @include MQ("sp") {
        text-align: left;
      }
      
    }

    th{
      background: none;

      @include MQ("pc") {
        padding: 15px;
        vertical-align: top;
        width: 263px;
      }

      span{
        position: relative;

        @include MQ("pc") {
          font-size: 18px;
          padding-left: 20px;
        }

        @include MQ("sp") {
          padding-left: 15px;
        }

        &::before {
          background-color: #56b298;
          bottom: 0;
          content: "";
          left: 0;
          margin: auto;
          position: absolute;
          top: 0;

          @include MQ("pc") {
            height: 26px;
            width: 4px;
          }

          @include MQ("sp") {
            height: 20px;
            width: 3px;
          }

        }

      }

    }

    td{
      @include MQ("pc") {
        padding-left: 0;
      }

      p + p {
        margin-top: 1.6rem;
      }

    }


  }

}

.pc {
  @include MQ("pc") {
    display: block;
  }

  @include MQ("sp") {
    display: none;
  }
}

.sp {
  @include MQ("pc") {
    display: none;
  }

  @include MQ("sp") {
    display: block;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}


.block{
  @include MQ("pc") {
    margin-bottom: 45px;
  }

  @include MQ("sp") {
    margin-bottom: 30px;
  }

  &:last-child{
    margin-bottom: 0;
  }

}

//見出し：ページタイトル
.page_ttl_txt{
  color: #ffffff;
  font-weight: bold;
  letter-spacing: 0.7px;
  margin-bottom: 5px;
  text-align: center;

  @include MQ("pc") {
    font-size: 36px;
  }

  @include MQ("sp") {
    font-size: 24px;
  }

}

//見出し：帯テキスト（大）
.obi_wrap{
  display: flex;
  width: 100%;

  @include MQ("pc") {
    border-bottom: 4px solid #dddddd;
    margin-bottom: 40px;
  }

  @include MQ("sp") {
    border-bottom: 3px solid #dddddd;
    margin-bottom: 30px;
  }

  .main_ttl{
    color: #575757;
    display: inline-block;
    font-weight: bold;
    position: relative;
    vertical-align: middle;

    @include MQ("pc") {
      font-size: 28px;
      line-height: 28px;
    }

    @include MQ("sp") {
      font-size: 20px;
      //white-space: nowrap;
    }

  }

  .obi_wrap_l{
    margin-right: 20px;
    padding-bottom: 15px;
    position: relative;

    @include MQ("sp"){
      padding-bottom: 10px;
    }

    &::before {
      background: #56b298;
      content: "";
      left: 0;
      position: absolute;
      width: 100%;

      @include MQ("pc") {
        bottom: -4px;
        height: 4px;
      }

      @include MQ("sp") {
        bottom: -3px;
        height: 3px;
      }

    }

  }

  .obi_wrap_r{
    padding-bottom: 15px;

    @include MQ("pc") {
      line-height: 28px;
      vertical-align: middle;
    }

  }

  .sub_ttl{
    margin-right: 20px;
    vertical-align: middle;

    @include MQ("pc") {
      font-size: 18px;
      line-height: 30px;
    }

    @include MQ("sp") {
      display: block;
      font-size: 14px;
      margin-bottom: 5px;
    }

  }

  .cat{
    display: inline-block;

    span{
      background-color: #bdbdbd;
      border-radius: 4px;
      color: #ffffff;
      display: inline-block;
      font-size: 14px;
      font-weight: bold;
      line-height: 28px;
      margin-right: 3px;
      padding: 0 15px;
      vertical-align: middle;

      @include MQ("sp") {
        font-size: 12px;
        line-height: 20px;
      }

      &:last-child{
        margin-right: 0;
      }

    }

    &.sp {
      @include MQ("pc") {
        display: none;
      }

      @include MQ("sp") {
        display: block;
      }
    }

  }

}

//見出し：背景付き帯テキスト（小）
.bg_txt{
  background: #f7f7f7;
  display: block;
  font-weight: bold;

  @include MQ("pc") {
    font-size: 18px;
    margin-bottom: 30px;
    padding: 13px;
  }

  @include MQ("sp") {
    font-size: 14px;
    margin-bottom: 20px;
    padding: 10px;
  }

}

//見出し：緑色キャッチ
.green_txt{
  color: #56b298;
  font-weight: bold;
  line-height: 1.46;
  margin-bottom: 10px;

  @include MQ("pc") {
    font-size: 24px;
  }

  @include MQ("sp") {
    font-size: 18px;
  }

}

.green_bdr_txt{
  border-bottom: 1px solid #dddddd;
  color: #56b298;
  font-weight: bold;
  line-height: 1.46;

  @include MQ("pc") {
    font-size: 24px;
    margin-bottom: 15px;
    padding-bottom: 15px;
  }

  @include MQ("sp") {
    font-size: 18px;
    margin-bottom: 10px;
    padding-bottom: 10px;
  }

}

//見出し：太字テキスト
.bold_txt{
  font-weight: bold;
  margin-bottom: 15px;
}

//セレクト要素
.select_wrapper{
  border: 1px solid #dadada;
  overflow: hidden;
  position: relative;
  vertical-align: middle;

  @include MQ("pc") {
    display: inline-block;
  }

  @include MQ("sp") {
    display: block;
  }

  select{
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background: none transparent;
    border: 0;
    border-radius: 0;
    color: #1e1e1e;
    font-size: 16px;
    line-height: 46px;
    margin: 0;
    padding: 0;
    padding-left: 20px;
    vertical-align: middle;
    width: 100%;
  }

  &::before {
    background: #eeeeee;
    bottom: 0;
    content: "";
    height: 100%;
    margin: auto;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    width: 50px;
  }

  &::after {
    background-image: url("../../../images/portal/common/arrow.png");
    background-repeat: no-repeat;
    background-size: contain;
    content: "";
    height: 12px;
    position: absolute;
    right: 20px;
    top: 18px;
    transform: rotate(90deg);
    width: 8px;
    z-index: 10;
  }

}

//お問い合わせリンク
.contact_link_area{
  background-color: #fbfbfb;
  display: none;
  text-align: center;

  @include MQ("pc") {
    padding: 35px 0 45px 0;
  }

  @include MQ("sp") {
    padding: 30px 10px;
  }

  p{
    margin-bottom: 20px;

    @include MQ("pc") {
      font-size: 18px;
    }

  }

  ul{
    @include MQ("pc") {
      margin: 0 auto;
      overflow: hidden;
      width: 975px;
    }

    li{
      @include MQ("pc") {
        float: left;
        margin-right: 35px;
        width: 470px;
      }

      @include MQ("sp") {
        margin-bottom: 10px;
      }

      a{
        background: #ff6f21;
        color: #ffffff;
        display: block;
        font-weight: bold;
        position: relative;

        @include MQ("pc") {
          border-radius: 10px;
          font-size: 28px;
          line-height: 84px;
        }

        @include MQ("sp") {
          border-radius: 6px;
          font-size: 16px;
          line-height: 42px;
          padding-left: 20px;
        }

        &::before {
          background-image: url("../../../images/portal/common/icn_mail.png");
          background-repeat: no-repeat;
          background-size: contain;
          bottom: 0;
          content: "";
          height: 20px;
          margin: auto;
          position: absolute;
          top: 0;
          width: 24px;

          @include MQ("pc") {
            left: 30px;
          }

          @include MQ("sp") {
            left: 12px;
          }

        }

      }

      &:first-child{
        a{
          @include MQ("pc") {
            padding-left: 20px;
          }
        }
      }

      &:last-child{
        margin-bottom: 0;
        margin-right: 0;

        a{
          &::before {
            background-image: url("../../../images/portal/common/icn_tel.png");
            background-repeat: no-repeat;
            background-size: contain;
            height: 30px;
            width: 18px;

            @include MQ("pc") {
              left: 40px;
            }

            @include MQ("sp") {
              left: 15px;
            }

          }

        }

      }

    }

  }

}

//一覧表示
.list_wrap{
  &.type_a{
    ul{
      li{
        @include MQ("pc") {
          margin-bottom: 80px;
        }

        @include MQ("sp") {
          margin-bottom: 50px;
        }

      }

    }

  }

  &.type_b{
    ul{
      li{
        @include MQ("pc") {
          margin-bottom: 30px;
        }

        @include MQ("sp") {
          margin-bottom: 30px;
        }

        .txt_box{
          text-align: left;
        }

      }

    }

  }

  ul{
    overflow: hidden;

    @include MQ("pc") {
      margin-bottom: 60px;
    }

    @include MQ("sp") {
      margin-bottom: 30px;
    }

    li{
      @include MQ("pc") {
        float: left;
        margin-right: 24px;
        width: 257px;
      }

      &:nth-child(4n){
        margin-right: 0;
      }

      &:last-child{
        @include MQ("sp") {
          margin-bottom: 0;
        }

      }

      &.soldout{
        a{
          pointer-events: none;

          .img_box{
            background: #000000;

            img{
              opacity: 0.5;
            }

          }

        }
      }

      a{
        display: block;
      }

      .img_box{
        background: #dbdbdb;
        display: table;
        position: relative;
        width: 257px;

        @include MQ("pc") {
          margin-bottom: 10px;
        }

        @include MQ("sp") {
          margin: 0 auto 10px;
        }

        .new{
          background: #ff0000;
          color: #ffffff;
          display: inline-block;
          font-size: 12px;
          left: 0;
          padding: 2px 10px;
          position: absolute;
          top: 0;
        }

        .img_box_contents{
          display: table-cell;
          margin: 0 auto;
          text-align: center;
          vertical-align: middle;
          width: 100%;

          @include MQ("pc") {
            height: 190px;
          }

          @include MQ("sp") {
            height: 190px;
          }

          img{
            @include MQ("pc") {
              max-height: 191px;
              max-width: 257px;
            }

            @include MQ("sp") {
              max-height: 190px;
              max-width: 257px;
            }

            vertical-align: middle;
          }

        }

      }

      .txt_box{
        text-align: center;

        .green_txt{
          font-size: 16px;
          font-weight: bold;
          line-height: 1.38;
        }

        .info_txt{
          margin-bottom: 10px;

          .info_store_name{
            display: block;
            font-size: 14px;
          }

          .info_item_name{
            display: block;
            font-size: 14px;
          }

        }

        .price_txt{
          display: table;
          margin: 0 auto 10px;
          text-align: center;

          .icn_include_price,
          .icn_only_price{
            border-radius: 6px;
            color: #ffffff;
            display: table-cell;
            font-size: 11px;
            font-weight: bold;
            height: 38px;
            letter-spacing: -0.3px;
            line-height: 1.17;
            text-align: center;
            vertical-align: middle;
            width: 50px;

            &.icn_include_price{
              background: #ff6f21;
            }

            &.icn_only_price{
              background: #56b298;
            }

          }

          .price{
            color: #ff6f21;
            display: inline-block;
            font-size: 30px;
            font-weight: bold;
            line-height: 38px;
            margin-left: 10px;
            vertical-align: middle;

            .tax{
              color: #1e1e1e;
              font-size: 12px;
              font-weight: normal;
              margin-left: 4px;
              vertical-align: middle;
            }

          }

        }

        .list_price{
          font-size: 12px;
          margin-bottom: 5px;
        }

        .cat_txt{
          display: inline-block;

          span{
            background-color: #bdbdbd;
            border-radius: 4px;
            color: #ffffff;
            display: inline-block;
            font-size: 14px;
            font-weight: bold;
            line-height: 28px;
            margin: 3px;
            padding: 0 15px;
            vertical-align: middle;

            @include MQ("sp") {
              font-size: 12px;
              line-height: 20px;
            }

            &:last-child{
              margin-right: 0;
            }
          }
        }
      }

    }

    a {
      &:nth-child(4n){
        li {
          margin-right: 0;
        }
      }
    }

  }

  .btn-md{
    margin: 0 auto 0;
    max-width: 426px;
  }

}

//一覧表示(画像、モーダル)
.modal_img_list.list_wrap{
  ul{
    li{
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0;
      }

    }

    a{
      @include MQ("pc"){
        &:nth-child(n+5){
          margin-top: 30px;
        }
      }

      @include MQ("sp"){
        float: left;
        margin-bottom: 30px;
        width: 49%;

        &:nth-child(2n - 1){
          margin-right: 2%;
        }

        li {
          .img_box {
            margin: 0;
            width: auto;

            .img_box_contents {
              height: calc(38vw - 20px);

              img {
                width: 100%;
              }
            }
          }
        }
      }

    }

  }
}

.lg-backdrop.in{
  opacity: 0.7;
}

.lg-slide{
  .lg-toolbar {
    #lg-download {
      display: none;
    }
  }

  .lg-actions {
    .lg-prev,.lg-next {
      background-color: #000000;
      font-size: 30px;
      padding: 10px 10px 11px;
      top: 48.5%;
    }

    .lg-prev {
      left: calc(50% - 324px);
    }

    .lg-next {
      right: calc(50% - 324px);
    }
  }
}

//詳細検索サイドバー
.serch_sidebar{
  position: relative;
  z-index: 100;

  .serch_sidebar_contents{
    background: #ffffff;
    position: fixed;
    right: -100%;
    top: 0;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    width: 615px;
    z-index: 2;

    @include MQ("pc") {
      height: 100vh;
      padding: 35px 40px;
      width: 615px;
    }

    @include MQ("sp") {
      max-height: calc(100vh - 20px);
      overflow-y: auto;
      padding: 20px;
      top: 10px;
      width: calc(100% - 20px);
    }

  }

  &.active{
    display: block;

    .serch_sidebar_bg {
      background-color: rgba(0,0,0,0.75);
      height: 100vh;
      left: 0;
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 1;
    }

    .serch_sidebar_contents{
      right: 0;

      @include MQ("sp") {
        left: 0;
        margin: 0 auto;
        top: 10px;
      }

      ul{
        @include MQ("pc") {
          margin-bottom: 40px;
        }

        @include MQ("sp") {
          margin-bottom: 30px;
        }

        li{
          @include MQ("pc") {
            margin-bottom: 20px;
          }

          @include MQ("sp") {
            margin-bottom: 15px;
          }

          overflow: hidden;

          &:last-child{
            margin-bottom: 0;
          }

          span{
            @include MQ("pc") {
              float: left;
              font-size: 18px;
              line-height: 50px;
              width: calc(100% - 350px);
            }

            @include MQ("sp") {
              display: block;
              margin-bottom: 10px;
            }

          }

          input[type="text"]{
            font-size: 16px;
            height: 50px;
            padding: 10px;
            vertical-align: middle;
          }

          .checkbox_wrapper{
            label{
              margin-right: 10px;

              @include MQ("pc") {
                line-height: 50px;
              }

              @include MQ("sp") {
                line-height: 20px;
              }

              &:last-child{
                margin-right: 0;
              }

              input[type="radio"]{
                margin: 0;
                margin-right: 5px;
              }

            }

          }

          input[type="text"],.select_wrapper,.checkbox_wrapper{
            @include MQ("pc") {
              display: inline-block;
              float: right;
              width: 350px;
            }

            @include MQ("sp") {
              display: block;
              width: 100%;
            }

          }

        }

      }

    }

    .btn-org-md{
      margin: 0 auto 20px;
    }

    .close_btn{
      display: block;
      text-align: right;

      span{
        background: #777777;
        border-radius: 5px;
        color: #ffffff;
        cursor: pointer;
        display: inline-block;
        font-size: 20px;
        font-weight: bold;
        line-height: 40px;
        text-align: center;
        -webkit-transition: all 0.15s ease;
        transition: all 0.15s ease;
        width: 40px;

        &:hover{
          opacity: 0.5;
        }
      }

    }

  }

}

//店舗ブロック
.store_block{
  @include MQ("pc") {
    margin-bottom: 30px;
  }

  @include MQ("sp") {
    margin-bottom: 20px;
  }

  &:last-child{
    margin-bottom: 0;
  }

  .store_contents{
    border: 1px solid #dddddd;
    margin-bottom: 20px;
    overflow: hidden;

    @include MQ("pc") {
      padding: 20px;
    }

    @include MQ("sp") {
      padding: 10px;
    }

    &:last-child{
      margin-bottom: 0;
    }

    .store_contents_l{
      @include MQ("pc") {
        float: left;
        width: 320px;
      }

      @include MQ("sp") {
        margin: 0 auto 10px;
        width: 225px;
      }

      .img_box{
        background: #dddddd;
        display: table;
        margin-bottom: 10px;
        width: 100%;

        .img_box_contents{
          display: table-cell;
          margin: 0 auto;
          position: relative;
          text-align: center;
          vertical-align: middle;
          width: 100%;

          @include MQ("pc") {
            height: 213px;
          }

          @include MQ("sp") {
            height: 150px;
          }

          img{
            @include MQ("pc") {
              max-height: 216px;
              max-width: 320px;
            }

            @include MQ("sp") {
              max-height: 150px;
              max-width: 100%;
            }

            vertical-align: middle;
          }

        }

      }

    }

    .store_contents_r{
      @include MQ("pc") {
        float: left;
        padding-left: 30px;
        width: calc(100% - 320px);
      }

      .store_info{
        @include MQ("pc") {
          margin-bottom: 25px;
        }

        @include MQ("sp") {
          margin-bottom: 20px;
        }

      }

      .btn-lg{
        max-width: 100%;

        @include MQ("pc") {
          padding-left: 45px;
        }

        @include MQ("sp") {
          font-size: 14px;
          line-height: 42px;
          margin-bottom: 15px;
          padding: 0 0 0 35px ;
        }

        span{
          position: relative;

          &::before {
            background-image: url("../../../images/portal/common/icn_store.png");
            background-repeat: no-repeat;
            background-size: contain;
            bottom: 0;
            content: "";
            margin: auto;
            position: absolute;
            top: 0;

            @include MQ("pc") {
              height: 30px;
              left: -45px;
              width: 30px;
            }

            @include MQ("sp") {
              height: 20px;
              left: -30px;
              width: 20px;
            }

          }

        }

      }

    }

  }

  .no_store_txt{
    display: block;
    text-align: center;

    @include MQ("pc") {
      font-size: 18px;
      margin-top: 30px;
    }

  }

}

//店舗情報
.store_info{
  ul{
    li{
      @include MQ("pc") {
        margin-bottom: 0;
        overflow: hidden;
      }

      @include MQ("sp") {
        margin-bottom: 10px;
      }

      span{
        font-weight: bold;

        @include MQ("pc") {
          float: left;
          margin-bottom: 0;
          width: 170px;
        }

        @include MQ("sp") {
          display: block;
          margin-bottom: 5px;
        }

      }

      p{
        @include MQ("pc") {
          float: left;
          padding-left: 20px;
          width: calc(100% - 170px);
        }

      }

    }

  }

}

//フリーテキストエリア
.freetxt_area{
  @include MQ("pc") {
    margin-bottom: 50px;
  }

  @include MQ("sp") {
    margin-bottom: 30px;
  }

}

//ログイン時：価格タイプ
.price_type{
  display: block;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;

  @include MQ("sp") {
    margin-top: 15px;
  }

  &:last-of-type{
    margin-top: 15px;
  }

  &.partner{
    color: #ff6f21;
  }

}

//エコエクをご利用にあたって
.caution{
  border: 1px solid #dddddd;
  margin-bottom: 20px;
  overflow: hidden;

  @include MQ("pc") {
    margin-bottom: 50px;
    padding: 20px;
    text-align: center;
  }

  @include MQ("sp") {
    margin-bottom: 30px;
    padding: 10px;
  }

  .bold_txt{
    color: #56b298;
    font-size: 14px;
    margin-bottom: 10px;
  }

  p{
    font-size: 12px;
  }

}
