@import "../config/*";

#about {
  .page_img {
    position: relative;

    .txt_box {
      bottom: 18px;
      left: 50%;
      position: absolute;
      text-align: center;
      transform: translateX(-50%);
      width: 100%;

      @include MQ("sp"){
        margin-top: 15px;
        position: static;
        text-align: left;
        transform: unset;
      }

      .green_txt {
        color: $COLOR_PRIMARY;
        font-size: 28px;
        margin-bottom: 25px;

        @include MQ("sp"){
          font-size: 18px;
          margin-bottom: 15px;

        }
      }

      p {
        font-size: 15px;
      }
    }
  }

  .graph_img{
    @include MQ("pc") {
      margin: 40px auto 60px auto;
      width: 840px;
    }

    @include MQ("sp") {
      margin: 20px auto 30px auto;
      width: 100%;
    }

    img{
      width: 100%;
    }

  }

  .about_block:not(:first-child) {
    margin-top: 35px;
  }

  .green_bdr_txt {
    padding-left: 20px;
    position: relative;

    &::before {
      background-color: $COLOR_PRIMARY;
      content: "";
      height: calc(1em * 1.42);
      left: 0;
      position: absolute;
      top: 0;
      width: 6px;
    }
  }

  .about_contents {
    display: flex;

    @include MQ("sp"){
      flex-direction: column;
    }

    .about_contents_l {
      width: calc(100% - 280px);

      @include MQ("sp"){
        order: 2;
        width: 100%;
      }
    }

    .about_contents_r {
      margin-left: 40px;
      width: 240px;

      @include MQ("sp"){
        margin-bottom: 10px;
        margin-left: 0;
        order: 1;
        width: 100%;
      }

      .img_box {
        .img_box_contents{
          text-align: center;
        }
      }
    }

  }

  .g_frame_double {
    display: flex;

    @include MQ("sp"){
      flex-direction: column;
    }

    .g_frame {
      border: 7px solid $COLOR_PRIMARY;
      border-radius: 5px;
      border-top: 0;
      flex: 1;
      overflow: hidden;

      &:not(:first-child) {
        @include MQ("pc"){
          margin-left: 20px;
        }

        @include MQ("sp"){
          margin-top: 20px;
        }
      }

      h3 {
        background-color: $COLOR_PRIMARY;
        color: #ffffff;
        font-size: 40px;
        font-weight: bold;
        padding: 10px 0 10px 10px;

        @include MQ("sp"){
          font-size: 20px;
        }

        span {
          height: 42px;
          margin-right: 25px;
          vertical-align: text-top;

          img {
            height: 42px;
            width: auto;
          }

        }

      }

      .txt_box {
        height: 240px;
        letter-spacing: -0.07em;

        @include MQ("pc") {
          padding: 20px 24px 30px 24px;
        }

        @include MQ("sp") {
          height: auto;
          padding: 20px;
        }

        .green_txt {
          font-size: 28px;
          letter-spacing: -1px;
          margin-bottom: 15px;

          @include MQ("sp"){
            font-size: 23px;
          }
        }
      }

      .btn-wrap{
        padding: 0 28px 30px;

        @include MQ("sp"){
          padding: 0 20px 20px 20px;
        }

        .btn-org-md {
          border-radius: 10px;
          position: relative;

          @include MQ("pc"){
            font-size: 25px;
            height: 84px;
            max-width: 100%;
            padding: 28px 0 28px 40px;
          }

          @include MQ("sp"){
            font-size: 14px;
          }

          &::before {
            background-repeat: no-repeat;
            background-size: auto 46px;
            content: "";
            height: 46px;
            left: 40px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 76px;

            @include MQ("sp"){
              background-size: auto 20px ;
              flex-direction: column;
              height: 20px;
              left: 12%;
            }
          }
        }

      }

      &.buy{
        .btn-wrap{
          .btn-org-md::before{
            background-image: url("../../../images/portal/about/about_btn_icon01.png");
          }
        }
      }

      &.sell{
        .btn-wrap{
          .btn-org-md::before{
            background-image: url("../../../images/portal/about/about_btn_icon02.png");
          }
        }
      }
    }
  }

}//#about end
