@import "../config/*";

/* Pannellum 2.5.6, https://github.com/mpetroff/pannellum */

.pnlm-container {
  background: #f4f4f4 url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2267%22%20height%3D%22100%22%20viewBox%3D%220%200%2067%20100%22%3E%0A%3Cpath%20stroke%3D%22%23ccc%22%20fill%3D%22none%22%20d%3D%22M33.5%2C50%2C0%2C63%2C33.5%2C75%2C67%2C63%2C33.5%2C50m-33.5-50%2C67%2C25m-0.5%2C0%2C0%2C75m-66.5-75%2C67-25m-33.5%2C75%2C0%2C25m0-100%2C0%2C50%22%2F%3E%0A%3C%2Fsvg%3E%0A") repeat;
  contain: content;
  cursor: default;
  font-family: Helvetica, "Nimbus Sans L", "Liberation Sans", Arial, sans-serif;
  height: 100%;
  line-height: 1.4;
  margin: 0;
  outline: 0;
  overflow: hidden;
  padding: 0;
  position: relative;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
}

.pnlm-container * {
  box-sizing: content-box;
}

.pnlm-ui {
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 1;
}

.pnlm-grab {
  cursor: grab;
  cursor: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20height%3D%2226%22%20width%3D%2226%22%3E%0A%3Cpath%20stroke%3D%22%23000%22%20stroke-width%3D%221px%22%20fill%3D%22%23fff%22%20d%3D%22m15.3%2020.5s6.38-6.73%204.64-8.24-3.47%201.01-3.47%201.01%203.61-5.72%201.41-6.49c-2.2-0.769-3.33%204.36-3.33%204.36s0.873-5.76-1.06-5.76-1.58%205.39-1.58%205.39-0.574-4.59-2.18-4.12c-1.61%200.468-0.572%205.51-0.572%205.51s-1.58-4.89-2.93-3.79c-1.35%201.11%200.258%205.25%200.572%206.62%200.836%202.43%202.03%202.94%202.17%205.55%22%2F%3E%0A%3C%2Fsvg%3E%0A") 12 8, default;
}

.pnlm-grabbing {
  cursor: grabbing;
  cursor: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20height%3D%2226%22%20width%3D%2226%22%3E%0A%3Cpath%20stroke%3D%22%23000%22%20stroke-width%3D%221px%22%20fill%3D%22%23fff%22%20d%3D%22m15.3%2020.5s5.07-5.29%203.77-6.74c-1.31-1.45-2.53%200.14-2.53%200.14s2.74-3.29%200.535-4.06c-2.2-0.769-2.52%201.3-2.52%201.3s0.81-2.13-1.12-2.13-1.52%201.77-1.52%201.77-0.261-1.59-1.87-1.12c-1.61%200.468-0.874%202.17-0.874%202.17s-0.651-1.55-2-0.445c-1.35%201.11-0.68%202.25-0.365%203.62%200.836%202.43%202.03%202.94%202.17%205.55%22%2F%3E%0A%3C%2Fsvg%3E%0A") 12 8, default;
}

.pnlm-sprite {
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2226%22%20height%3D%22208%22%3E%0A%3Ccircle%20fill-opacity%3D%22.78%22%20cy%3D%22117%22%20cx%3D%2213%22%20r%3D%2211%22%20fill%3D%22%23fff%22%2F%3E%0A%3Ccircle%20fill-opacity%3D%22.78%22%20cy%3D%22143%22%20cx%3D%2213%22%20r%3D%2211%22%20fill%3D%22%23fff%22%2F%3E%0A%3Ccircle%20cy%3D%22169%22%20cx%3D%2213%22%20r%3D%227%22%20fill%3D%22none%22%20stroke%3D%22%23000%22%20stroke-width%3D%222%22%2F%3E%0A%3Ccircle%20cy%3D%22195%22%20cx%3D%2213%22%20r%3D%227%22%20fill%3D%22none%22%20stroke%3D%22%23000%22%20stroke-width%3D%222%22%2F%3E%0A%3Ccircle%20cx%3D%2213%22%20cy%3D%22195%22%20r%3D%222.5%22%2F%3E%0A%3Cpath%20d%3D%22m5%2083v6h2v-4h4v-2zm10%200v2h4v4h2v-6zm-5%205v6h6v-6zm-5%205v6h6v-2h-4v-4zm14%200v4h-4v2h6v-6z%22%2F%3E%0A%3Cpath%20d%3D%22m13%20110a7%207%200%200%200%20-7%207%207%207%200%200%200%207%207%207%207%200%200%200%207%20-7%207%207%200%200%200%20-7%20-7zm-1%203h2v2h-2zm0%203h2v5h-2z%22%2F%3E%0A%3Cpath%20d%3D%22m5%2057v6h2v-4h4v-2zm10%200v2h4v4h2v-6zm-10%2010v6h6v-2h-4v-4zm14%200v4h-4v2h6v-6z%22%2F%3E%0A%3Cpath%20d%3D%22m17%2038v2h-8v-2z%22%2F%3E%0A%3Cpath%20d%3D%22m12%209v3h-3v2h3v3h2v-3h3v-2h-3v-3z%22%2F%3E%0A%3Cpath%20d%3D%22m13%20136-6.125%206.125h4.375v7.875h3.5v-7.875h4.375z%22%2F%3E%0A%3Cpath%20d%3D%22m10.428%20173.33v-5.77l5-2.89v5.77zm1-1.73%203-1.73-3.001-1.74z%22%2F%3E%0A%3C%2Fsvg%3E%0A");
}

.pnlm-container:-moz-full-screen {
  height: 100%;
  position: static;
  width: 100%;
}

.pnlm-container:-webkit-full-screen {
  height: 100%;
  position: static;
  width: 100%;
}

.pnlm-container:-ms-fullscreen {
  height: 100%;
  position: static;
  width: 100%;
}

.pnlm-container:fullscreen {
  height: 100%;
  position: static;
  width: 100%;
}

.pnlm-render-container {
  cursor: inherit;
  height: 100%;
  position: absolute;
  width: 100%;
}

.pnlm-controls {
  background-color: #ffffff;
  border: 1px solid #999999;
  border-color: rgba(0, 0, 0, 0.4);
  border-radius: 3px;
  cursor: pointer;
  margin-top: 4px;
  -webkit-transform: translateZ(9999px);
  transform: translateZ(9999px);
  z-index: 2;
}

.pnlm-control:hover {
  background-color: #f8f8f8;
}

.pnlm-controls-container {
  left: 4px;
  position: absolute;
  top: 0;
  z-index: 1;
}

.pnlm-zoom-controls {
  height: 52px;
  width: 26px;
}

.pnlm-zoom-in {
  border-radius: 3px 3px 0 0;
  height: 50%;
  position: absolute;
  top: 0;
  width: 100%;
}

.pnlm-zoom-out {
  background-position: 0 -26px;
  border-radius: 0 0 3px 3px;
  border-top: 1px solid #dddddd;
  border-top-color: rgba(0, 0, 0, 0.1);
  bottom: 0;
  height: 50%;
  position: absolute;
  width: 100%;
}

.pnlm-fullscreen-toggle-button,
.pnlm-orientation-button,
.pnlm-hot-spot-debug-indicator {
  height: 26px;
  width: 26px;
}

.pnlm-hot-spot-debug-indicator {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 13px;
  display: none;
  height: 26px;
  left: 50%;
  margin: -13px 0 0 -13px;
  position: absolute;
  top: 50%;
  width: 26px;
}

.pnlm-orientation-button-inactive {
  background-position: 0 -156px;
}

.pnlm-orientation-button-active {
  background-position: 0 -182px;
}

.pnlm-fullscreen-toggle-button-inactive {
  background-position: 0 -52px;
}

.pnlm-fullscreen-toggle-button-active {
  background-position: 0 -78px;
}

.pnlm-panorama-info {
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 0 3px 3px 0;
  bottom: 4px;
  color: #ffffff;
  display: none;
  padding-right: 10px;
  position: absolute;
  text-align: left;
  -webkit-transform: translateZ(9999px);
  transform: translateZ(9999px);
  z-index: 2;
}

.pnlm-title-box {
  display: table;
  font-size: 20px;
  margin-bottom: 3px;
  padding-left: 5px;
  position: relative;
}

.pnlm-author-box {
  display: table;
  font-size: 12px;
  padding-left: 5px;
  position: relative;
}

.pnlm-load-box {
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 3px;
  color: #ffffff;
  display: none;
  font-size: 20px;
  height: 150px;
  left: 50%;
  margin: -75px 0 0 -100px;
  position: absolute;
  text-align: center;
  top: 50%;
  width: 200px;
}

.pnlm-load-box p {
  margin: 20px 0;
}

.pnlm-lbox {
  display: none;
  height: 20px;
  left: 50%;
  margin: -10px 0 0 -10px;
  position: absolute;
  top: 50%;
  width: 20px;
}

.pnlm-loading {
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: pnlm-mv;
  animation-name: pnlm-mv;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  background-color: #ffffff;
  height: 10px;
  position: relative;
  width: 10px;
}

@keyframes pnlm-mv {
  from {
    left: 0;
    top: 0;
  }

  25% {
    left: 10px;
    top: 0;
  }

  50% {
    left: 10px;
    top: 10px;
  }

  75% {
    left: 0;
    top: 10px;
  }

  to {
    left: 0;
    top: 0;
  }
}

@keyframes pnlm-mv {
  from {
    left: 0;
    top: 0;
  }

  25% {
    left: 10px;
    top: 0;
  }

  50% {
    left: 10px;
    top: 10px;
  }

  75% {
    left: 0;
    top: 10px;
  }

  to {
    left: 0;
    top: 0;
  }
}

.pnlm-load-button {
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 3px;
  color: #ffffff;
  cursor: pointer;
  display: table;
  font-size: 20px;
  height: 100px;
  left: 50%;
  margin: -50px 0 0 -100px;
  position: absolute;
  text-align: center;
  top: 50%;
  width: 200px;
}

.pnlm-load-button:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.pnlm-load-button p {
  display: table-cell;
  vertical-align: middle;
}

.pnlm-info-box {
  background-color: #000000;
  border-radius: 3px;
  color: #ffffff;
  display: table;
  font-size: 15px;
  height: 150px;
  left: 50%;
  margin: -75px 0 0 -100px;
  position: absolute;
  table-layout: fixed;
  text-align: center;
  top: 50%;
  width: 200px;
}

.pnlm-info-box a,
.pnlm-author-box a {
  color: #ffffff;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.pnlm-info-box p {
  display: table-cell;
  padding: 0 5px 0 5px;
  vertical-align: middle;
}

.pnlm-error-msg {
  display: none;
}

.pnlm-about-msg {
  background: rgba(0, 0, 0, 0.7);
  border-radius: 3px;
  color: #ffffff;
  display: none;
  font-size: 11px;
  left: 50px;
  line-height: 11px;
  opacity: 0;
  padding: 5px 8px 5px 8px;
  position: absolute;
  top: 50px;
  -moz-transition: opacity 0.3s ease-in-out;
  -webkit-transition: opacity 0.3s ease-in-out;
  -o-transition: opacity 0.3s ease-in-out;
  -ms-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
  z-index: 1;
}

.pnlm-about-msg a:link,
.pnlm-about-msg a:visited {
  color: #ffffff;
}

.pnlm-about-msg a:hover,
.pnlm-about-msg a:active {
  color: #eeeeee;
}

.pnlm-hotspot-base {
  cursor: default;
  position: absolute;
  top: 0;
  vertical-align: middle;
  visibility: hidden;
  z-index: 1;
}

.pnlm-hotspot {
  border-radius: 13px;
  height: 26px;
  width: 26px;
}

.pnlm-hotspot:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.pnlm-hotspot.pnlm-info {
  background-position: 0 -104px;
}

.pnlm-hotspot.pnlm-scene {
  background-position: 0 -130px;
}

div.pnlm-tooltip span {
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 3px;
  color: #ffffff;
  cursor: default;
  margin-left: -220px;
  max-width: 200px;
  padding: 5px 10px;
  position: absolute;
  text-align: center;
  visibility: hidden;
}

div.pnlm-tooltip:hover span {
  visibility: visible;
}

div.pnlm-tooltip:hover span::after {
  border-color: rgba(0, 0, 0, 0.7) transparent transparent transparent;
  border-style: solid;
  border-width: 10px;
  bottom: -20px;
  content: "";
  height: 0;
  left: -10px;
  margin: 0 50%;
  position: absolute;
  width: 0;
}

.pnlm-compass {
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20height%3D%2250%22%20width%3D%2250%22%3E%0A%3Cpath%20d%3D%22m24.5078%206-3.2578%2018h7.5l-3.25781-18h-0.984376zm-3.2578%2020%203.2578%2018h0.9844l3.2578-18h-7.5zm1.19531%200.9941h5.10938l-2.5547%2014.1075-2.5547-14.1075z%22%2F%3E%0A%3C%2Fsvg%3E%0A");
  border-radius: 25px;
  bottom: 4px;
  cursor: default;
  display: none;
  height: 50px;
  position: absolute;
  right: 4px;
  width: 50px;
}

.pnlm-world {
  left: 50%;
  position: absolute;
  top: 50%;
}

.pnlm-face {
  position: absolute;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

.pnlm-dragfix,
.pnlm-preview-img {
  height: 100%;
  position: absolute;
  width: 100%;
}

.pnlm-preview-img {
  background-position: center;
  background-size: cover;
}

.pnlm-lbar {
  border: #ffffff 1px solid;
  height: 6px;
  margin: 0 auto;
  width: 150px;
}

.pnlm-lbar-fill {
  background: #ffffff;
  height: 100%;
  width: 0;
}

.pnlm-lmsg {
  font-size: 12px;
}

.pnlm-fade-img {
  left: 0;
  position: absolute;
  top: 0;
}

.pnlm-pointer {
  cursor: pointer;
}
