@import "../config/*";

.store_list_link{
  text-align: center;

  @include MQ("pc") {
    margin-bottom: 45px;
  }

  @include MQ("sp") {
    margin-bottom: 20px;
  }

  ul{
    display: inline-block;
    overflow: hidden;

    li{
      display: inline-block;

      @include MQ("pc") {
        margin-right: 50px;
      }

      @include MQ("sp") {
        float: left;
        margin-bottom: 5px;
        width: 50%;
      }

      &:last-child{
        margin-right: 0;

        a{
          &::before {
            display: none;
          }

        }

      }

      &:nth-child(2n){
        @include MQ("sp") {
          margin-right: 0;
        }

        a{
          &::before {
            @include MQ("sp") {
              display: none;
            }

          }

        }

      }

      a{
        display: block;
        letter-spacing: -0.5px;
        position: relative;

        @include MQ("pc") {
          font-size: 18px;
        }

        @include MQ("sp") {
          font-size: 14px;
        }

        &::before {
          background: #1e1e1e;
          bottom: 0;
          content: "";
          height: 18px;
          margin: auto;
          position: absolute;
          top: 0;
          width: 1px;

          @include MQ("pc") {
            right: -25px;
          }

          @include MQ("sp") {
            right: 0;
          }

        }

      }

    }

  }

}

#store_list{
  .bg_txt{
    @include MQ("pc") {
      margin-bottom: 10px;
    }
  }
}
