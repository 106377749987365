@import "../config/*";

.white_border_box{
  background: #00000085;
  left: 50%;
  margin: auto;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  z-index: 10;

  @include MQ("pc") {
    border: solid 8px #ffffff;
    width: 720px;
  }

  @include MQ("sp") {
    border: solid 6px #ffffff;
    width: 280px;
  }

  .white_border_box_ttl{
    color: #ffffff;
    font-weight: bold;

    @include MQ("pc") {
      font-size: 66px;
      line-height: 82px;
      padding: 35px 0;
    }

    @include MQ("sp") {
      display: inline-block;
      font-size: 30px;
      padding: 20px 0;
    }

  }

}

.top_main_img{
  display: none;
  position: relative;
  width: 100%;

  @include MQ("pc") {
    height: 460px;
  }

  @include MQ("sp") {
    height: 300px;
  }

  .swiper-slide{
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;

    &.slider1{
      background-image: url("../../../images/portal/top/main_img01.jpg");
    }

    &.slider2{
      background-image: url("../../../images/portal/top/main_img02.jpg");
    }

    &.slider3{
      background-image: url("../../../images/portal/top/main_img03.jpg");
    }

    &.slider4{
      background-image: url("../../../images/portal/top/main_img04.jpg");
    }

    &.slider5{
      background-image: url("../../../images/portal/top/main_img05.jpg");
    }

  }

}

.top_serch{
  background: #56b298;
  color: #ffffff;
  display: none;
  text-align: center;

  @include MQ("sp") {
    padding: 30px 10px;
  }

  @include MQ("pc") {
    padding: 35px 0 56px;
  }

  .page_ttl_txt{
    @include MQ("sp") {
      margin-bottom: 30px;
    }
  }

  p{
    font-size: 18px;
    margin-bottom: 30px;
  }

  .top_serch_contents{
    background: #ffffff;
    margin: 0 auto;
    width: 100%;

    @include MQ("pc") {
      border: 12px solid #dbdbdb;
      max-width: 1100px;
      padding: 40px 0;
    }

    @include MQ("sp") {
      border: 6px solid #dbdbdb;
      padding: 30px 10px;
    }

    .select_area{
      @include MQ("pc") {
        display: inline-block;
        margin-right: 30px;
      }

      @include MQ("sp") {
        margin-bottom: 20px;
      }

      span{
        color: #1e1e1e;
        display: inline-block;
        vertical-align: middle;

        @include MQ("pc") {
          line-height: 46px;
          margin-right: 15px;
        }

        @include MQ("sp") {
          margin-bottom: 10px;
        }

      }

      .select_wrapper{
        @include MQ("pc") {
          width: 215px;
        }

      }

      &:last-of-type{
        @include MQ("pc") {
          margin-right: 15px;
        }

        @include MQ("sp") {
          margin-bottom: 30px;
        }

        .select_wrapper{
          @include MQ("pc") {
            width: 280px;
          }

        }

      }

    }

    .btn-org-xs,.btn-gry-sm{
      vertical-align: middle;

      @include MQ("pc") {
        display: inline-block;
        line-height: 46px;
      }

      @include MQ("sp") {
        display: block;
        max-width: 100%;
        width: 100%;
      }

    }

    .btn-org-xs{
      @include MQ("pc") {
        font-size: 18px;
        margin-right: 10px;
        max-width: 100px;
        padding: 0;
      }

      @include MQ("sp") {
        font-size: 16px;
        margin-bottom: 10px;
      }

    }

    .btn-gry-sm{
      @include MQ("pc") {
        font-size: 14px;
        padding: 0;
        width: 145px;
      }

      @include MQ("sp") {
        font-size: 16px;
      }

    }

  }

}

.top_link_area{
  display: none;
  overflow: hidden;

  @include MQ("pc") {
    margin-bottom: 50px;
  }

  @include MQ("sp") {
    margin-bottom: 30px;
  }

  .top_link_area_l,.top_link_area_r{
    @include MQ("pc") {
      float: left;
      width: 50%;
    }

    @include MQ("sp") {
      width: 100%;
    }

    a{
      background-image: url("../../../images/portal/top/link_area_l.jpg");
      background-position: bottom center;
      background-size: cover;
      display: block;
      position: relative;

      @include MQ("pc") {
        height: 180px;
      }

      @include MQ("sp") {
        height: 180px;
      }

      .white_border_box{
        border: 8px solid #ffffff;

        @include MQ("pc") {
          width: 340px;
        }

        @include MQ("sp") {
          width: 300px;
        }

        .white_border_box_ttl{
          @include MQ("pc") {
            font-size: 24px;
            line-height: 68px;
          }

          @include MQ("sp") {
            font-size: 20px;
            line-height: 60px;
          }

          padding: 0;

        }

      }

    }

  }

  .top_link_area_r{
    a{
      background-image: url("../../../images/portal/top/link_area_r.jpg");
      background-position: bottom center;
      background-size: cover;
    }

  }

}

#top{
  .header{
    margin-bottom: 0;
  }

  .main_menu{
    ul{
      li{
        a{
          @include MQ("pc") {
            padding: 42px 0;
          }

          @include MQ(1200) {
            padding: 38px 0;
          }
        }
      }
    }
  }

  .page_ttl{
    display: none;
  }

  .top_main_img,.top_serch,.top_link_area{
    display: block;
  }


}

.topNews {
  margin-bottom: 50px;

  &_list {
    margin-bottom: 60px;

    @include MQ("sp") {
      margin-bottom: 30px;
    }

    &_item {
      display: block;
      padding: 15px 0;
      border-bottom: 1px solid #dddddd;
      font-weight: bold;

      &:first-child {
        border-top: 1px solid #dddddd;

      }

      span {
        font-weight: normal;
        width: 160px;
        display: inline-block;

        @include MQ("sp"){
          display: block;
          font-size: 0.9em;
          margin-bottom: 5px;
        }
      }
    }
  }

  .btn-md {
    margin: 0 auto;
    max-width: 426px;
  }

}

.sdg_logo {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  max-width: 1100px;

  @include MQ("pc") {
    margin-bottom: 50px;
    display: flex;
    align-items: center;
  }

  @include MQ("sp") {
    margin-bottom: 30px;
    padding: 0 10px;
  }

  figure {
    @include MQ("pc"){
      margin-right: 30px;
      flex: 1;
    }

    @include MQ("sp"){
      margin-bottom: 10px;
    }
  }
}
