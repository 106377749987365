@import "../config/*";

.detail_info{
  margin-bottom: 25px;
  overflow: hidden;

  .detail_info_l{
    @include MQ("pc") {
      float: left;
      margin-top: 10px;
      width: 540px;
    }

    @include MQ("sp") {
      margin: 0 auto 20px;
      width: 100%;
    }

    .img_box{
      background: #dddddd;
      display: table;
      margin-bottom: 10px;
      width: 100%;

      @include MQ("sp") {
        margin: 0 auto 20px;
        width: 271px;
      }

      .img_box_contents{
        display: table-cell;
        margin: 0 auto;
        position: relative;
        text-align: center;
        vertical-align: middle;
        width: 100%;

        @include MQ("pc") {
          height: 360px;
        }

        @include MQ("sp") {
          height: 200px;
        }

        .new{
          background: #ff0000;
          color: #ffffff;
          display: inline-block;
          left: 0;
          padding: 2px 10px;
          position: absolute;
          top: 0;

          @include MQ("pc") {
            font-size: 20px;
            width: 90px;
          }

          @include MQ("sp") {
            font-size: 16px;
          }

        }

        img{
          @include MQ("pc") {
            max-height: 405px;
            max-width: 540px;
          }

          @include MQ("sp") {
            max-height: 200px;
            max-width: 100%;
          }

          vertical-align: middle;
        }

      }

    }

  }

  .detail_info_r{
    @include MQ("pc") {
      float: right;
      padding-left: 60px;
      width: calc(100% - 540px);
    }

    .txt_box{
      .green_txt{
        margin-bottom: 5px;
      }

      .no_txt{
        @include MQ("pc") {
          margin-bottom: 15px;
        }

        @include MQ("sp") {
          margin-bottom: 20px;
        }

      }

      .cat_box{
        margin-bottom: 20px;

        .bold_txt{
          margin-bottom: 10px;

          @include MQ("pc") {
            font-size: 18px;
          }

        }

        ul{
          @include MQ("pc") {
            display: table;
          }

          @include MQ("sp") {
            display: block;
            overflow: hidden;
          }

          &:first-of-type{
            margin-bottom: 20px;
          }

          li{
            background: #f7f7f7;
            border-radius: 6px;
            line-height: 1.14;
            text-align: center;
            vertical-align: middle;

            @include MQ("pc") {
              display: inline-block;
              font-size: 14px;
              margin-right: 4px;
            }

            @include MQ("sp") {
              display: table;
              float: left;
              font-size: 12px;
              margin-bottom: 1.25%;
              margin-right: 1.25%;
              width: 32.5%;
            }

            &:nth-child(3n){
              @include MQ("sp") {
                margin-right: 0;
              }
            }

            span{
              display: table-cell;
              text-align: center;
              vertical-align: middle;

              @include MQ("pc") {
                height: 53px;
                width: 80px;
              }

              @include MQ("sp") {
                height: 40px;
                width: 100%;
              }

            }

            &:last-child{
              margin-right: 0;
            }

            &.on{
              background: #56b298;
              color: #ffffff;
              font-weight: bold;
            }

          }

        }

      }

      .list_price{
        @include MQ("pc") {
          font-size: 18px;
          margin-bottom: 5px;
        }

        @include MQ("sp") {
          margin-bottom: 5px;
        }

      }

      .price_txt{
        display: table;
        text-align: left;

        .icn_include_price,
        .icn_only_price{
          border-radius: 10px;
          color: #ffffff;
          display: table-cell;
          font-weight: bold;
          letter-spacing: -0.5px;
          line-height: 1.17;
          text-align: center;
          vertical-align: middle;

          @include MQ("pc") {
            font-size: 14px;
            height: 60px;
            width: 65px;
          }

          @include MQ("sp") {
            font-size: 12px;
            height: 50px;
            width: 55px;
          }

          &.icn_include_price{
            background: #ff6f21;
          }

          &.icn_only_price{
            background: #56b298;
          }

        }

        .price{
          color: #ff6f21;
          display: inline-block;
          font-weight: bold;
          margin-left: 15px;
          vertical-align: middle;

          @include MQ("pc") {
            font-size: 50px;
            line-height: 60px;
          }

          @include MQ("sp") {
            font-size: 36px;
            line-height: 50px;
          }

          .tax{
            color: #1e1e1e;
            font-size: 14px;
            font-weight: normal;
            margin-left: 10px;
            vertical-align: top;
          }

        }

      }

    }

  }

}

#panorama {
  @include MQ("pc") {
    margin-bottom: 60px;
  }

  @include MQ("sp") {
    margin-bottom: 30px;
  }

  .panorama_block{
    background: #dddddd;
    
    &:not(:first-of-type) {
      margin-top: 30px;
    }

    div[id*="viewer"] {
      margin: 0 auto;

      @include MQ("pc") {
        height: 450px;
        width: 100%;
      }
      
      @include MQ("sp") {
        height: 200px;
        width: 100%;
      }

    }

    p {
      background-color: #f7f7f7;
      font-size: 14px;
      padding: 10px 0;
      text-align: center;
    }

  }

}

#item_detail{
  .store_block{
    @include MQ("pc") {
      margin-bottom: 50px;
    }

    @include MQ("sp") {
      margin-bottom: 30px;
    }

    &.last{
      margin-bottom: 0;
    }

  }

  .contact_link_area{
    display: block;

    @include MQ("pc") {
      margin-bottom: 50px;
    }

    @include MQ("sp") {
      margin-bottom: 30px;
    }

    &.prev_footer{
      margin-bottom: 0;

      @include MQ("pc") {
        margin-bottom: -100px;
        margin-top: 80px;
      }

      @include MQ("sp") {
        margin-bottom: -60px;
        margin-top: 30px;
      }

    }

  }


  table{
    table-layout: fixed;

    @include MQ("pc") {
      margin-bottom: 50px;
    }

    @include MQ("sp") {
      margin-bottom: 30px;
    }

    td {
      @include MQ("pc") {
        width: calc(50% - 215px);
      }
    }

  }

  .price_type{
    @include MQ("sp") {
      margin-bottom: 10px;
    }
  }

}

