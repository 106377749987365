@import "../config/*";

.header {
  @include MQ("pc") {
    margin-bottom: 50px;
  }

  @include MQ("sp") {
    margin-bottom: 30px;
    margin-top: 60px;
  }

  .header_content{
    display: table;
    overflow: hidden;
    position: relative;
    width: 100%;

    @include MQ("pc") {
      height: 135px;
      padding: 0 20px 0;
    }

    @include MQ("sp") {
      background: #ffffff;
      height: 60px;
      left: 0;
      padding: 0 10px 0;
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 100;
    }

    .header_l{
      display: table-cell;
      position: relative;
      vertical-align: middle;
      width: auto;

      a,p{
        display: inline-block;
        vertical-align: middle;
      }

      p{
        font-size: 14px;
        margin-left: 15px;

        @include MQ("sp") {
          display: none;
        }

      }

      a{
        @include MQ("pc") {
          height: 85px;
          width: 270px;
        }

        @include MQ("sp") {
          height: 32px;
          width: 100px;
        }

        img{
          height: auto;
          left: 0;
          width: 100%;
        }

      }

    }

    ul.sub_menu,a.btn-gry-md{
      position: absolute;
      right: 20px;

    }

    ul.sub_menu{
      top: 15px;

      @include MQ("pc") {
        display: inline-block;
      }

      @include MQ("sp") {
        display: none;
      }

      li{
        display: inline-block;
        margin-left: 40px;
        position: relative;

        &:first-child{
          margin-left: 0;

          &::before {
            display: none;
          }
        }

        &::before {
          background: #1e1e1e;
          bottom: 0;
          content: "";
          height: 14px;
          left: -20px;
          margin: auto;
          position: absolute;
          top: 0;
          width: 1px;
        }

        a{
          display: block;
          font-size: 14px;
        }

      }

    }

    a.btn-gry-md{
      span{
        @include MQ("pc") {
          display: inline;
        }

        @include MQ("sp") {
          display: none;
        }

      }

      @include MQ("pc") {
        bottom: 30px;
        font-size: 14px;
        font-weight: normal;
        max-width: 280px;
      }

      @include MQ("sp") {
        border-radius: 3px;
        font-size: 11px;
        line-height: 26px;
        max-width: 140px;
        padding: 0;
        right: 55px;
        top: 50%;
        transform: translateY(-50%);
      }

    }

    #sp_menu_btn{
      cursor: pointer;
      height: 22px;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      width: 30px;

      @include MQ("pc") {
        display: none;
      }

      .sp_menu_btn_contents {
        box-sizing: border-box;
        display: inline-block;
        height: 22px;
        position: relative;
        width: 100%;

        span {
          background-color: #56b298;
          border-radius: 4px;
          box-sizing: border-box;
          display: inline-block;
          height: 4px;
          left: 0;
          position: absolute;
          width: 100%;

          &:nth-of-type(1) {
            top: 0;
          }

          &:nth-of-type(2) {
            bottom: 0;
            margin: auto;
            top: 0;
          }

          &:nth-of-type(3) {
            bottom: 0;
          }
        }
      }

    }

  }

  .main_menu{
    background: #ffffff;
    overflow: hidden;

    @include MQ("sp") {
      display: block;
      max-height: 0;
      overflow: hidden;
      position: fixed;
      top: 60px;
      -webkit-transition: max-height 0.1s;
      transition: max-height 0.1s;
      width: 100%;
      z-index: 100;
    }

    li{
      @include MQ("pc") {
        float: left;
        width: 14.285%;
      }

      @include MQ("sp") {
        width: 100%;
      }

      &:first-child{
        a{
          &::before {
            @include MQ("pc") {
              display: none;
            }
          }

        }

      }

      a{
        display: block;
        position: relative;

        @include MQ("pc") {
          padding: 10px 0 42px 0;
          text-align: center;
        }

        @include MQ("sp") {
          padding: 10px 0 10px 0;
          text-align: left;
        }

        @include MQ(1200) {
          padding: 0 0 38px 0;
        }

        &::before {
          content: "";
          position: absolute;

          @include MQ("pc") {
            border-right: 1px dashed #c5c5c5;
            bottom: 0;
            height: 75px;
            left: 0;
            margin: auto;
            top: 0;
            width: 1px;
          }

          @include MQ("sp") {
            border-bottom: 1px dashed #c5c5c5;
            bottom: 0;
            height: 1px;
            left: 0;
            margin: auto;
            width: 100%;
          }

        }

        .imgbox{
          @include MQ("sp") {
            display: inline-block;
            text-align: center;
            vertical-align: middle;
            width: 80px;
          }

          img{
            height: 46px;
            width: auto;

            @include MQ("sp") {
              display: inline-block;
              height: 25px;
              vertical-align: middle;
            }

            @include MQ(1200) {
              height: 35px;
            }

          }

        }

        span{
          display: block;

          @include MQ("pc") {
            font-size: 18px;
            margin-top: 3px;
          }

          @include MQ("sp") {
            display: inline-block;
            margin-left: 10px;
            vertical-align: middle;
          }

          @include MQ(1200) {
            font-size: 16px;
          }

        }

      }

      &:nth-child(5){
        a{
          img{
            @include MQ("sp") {
              height: 22px;
            }

          }

        }

      }

      &.bg{
        a{
          background: #eeeeee;

          span{
            @include MQ("sp") {
              margin-left: 95px;
            }

          }

        }

      }

    }

    &.open{
      border-top: 1px dashed #c5c5c5;
      max-height: 480px;
    }

  }

  .page_ttl{
    background: #56b298;
    color: #ffffff;
    text-align: center;

    @include MQ("pc") {
      padding: 38px 0 50px;
    }

    .page_ttl_txt{
      @include MQ("sp") {
        padding: 20px 0;
      }
    }

    .pankuzu{
      text-align: center;

      @include MQ("sp") {
        display: none;
      }

      li{
        display: inline-block;
        font-size: 14px;
        position: relative;

        &::after {
          content: ">";
          margin: 0 8px 0 11px;
        }

        &:last-child{
          margin-right: 0;

          &::after {
            display: none;
          }
        }

        a{
          color: #ffffff;
          font-size: 14px;
        }

      }

    }

  }

}
