@import "../config/*";

.item_list_serch_menu{
  text-align: right;

  @include MQ("pc") {
    margin-bottom: 30px;
  }

  @include MQ("sp") {
    margin-bottom: 40px;
  }

  p,
  .btn-org-md,
  .btn-gry-md{
    @include MQ("pc") {
      display: inline-block;
    }

    @include MQ("sp") {
      margin-bottom: 10px;
    }

  }

  .btn-org-md,.btn-gry-md{
    font-weight: normal;

    @include MQ("pc") {
      line-height: 54px;
      margin-left: 10px;
      padding: 0;
      width: 270px;
    }

  }

}
