@import "../config/*";

/* ==================================================
   ページネーション
================================================== */

.pagenation {
  align-items: center;
  background-color: #f7f7f7;
  display: flex;
  justify-content: center;
  padding: 15px 0;

  a,span {
    background-color: $COLOR_WHITE;
    border-radius: 5px;
    box-shadow: $SHADOW_B;
    color: $COLOR_GRAY;
    display: block;
    line-height: 100%;
    text-align: center;

    @include MQ("pc") {
      padding: 12px 13px 11px;
    }

    @include MQ("sp") {
      font-size: 14px;
      padding: 12px 10px 11px;
    }

    @include MQ("sp") {
      font-size: 12px;
      padding: 8px 9px 7px;
    }

    &:not(:first-child) {
      margin-left: 5px;
    }
  }

  a {
    outline: none;
    text-decoration: none;
    transition: background-color ease-in-out 0.2s, box-shadow ease-in-out 0.2s;

    &:hover,
    &:focus {
      background-color: $COLOR_BASE;
    }

    &:active {
      box-shadow: none;
    }
  }

  .current {
    background-color: $COLOR_PRIMARY;
    color: $COLOR_WHITE;
  }

  .prev,
  .next {
    position: relative;

    @include MQ("pc") {
      height: 39px;
      width: 37px;
    }

    @include MQ("sp") {
      height: 27px;
      width: 27px;
    }

    &::before {
      background-repeat: no-repeat;
      background-size: cover;
      content: "";
      height: 10px;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 6px;

      @include MQ("sm") {
        top: calc(50% + 1px);
      }
    }
  }

  .prev {
    &::before {
      background-image: url("../../../images/admin/icon/icon_prev.svg");
      left: calc(50% - 1px);
    }
  }

  .next {
    &::before {
      background-image: url("../../../images/admin/icon/icon_next.svg");
      left: 50%;
    }
  }

  .total {
    background-color: $COLOR_BASE;
    border: 1px solid $COLOR_BORDER;
    border-radius: 5px;
    color: $COLOR_GRAY;
    display: block;
    line-height: 100%;
    text-align: center;

    @include MQ("pc") {
      font-size: 16px;
      padding: 11px 13px 10px;
    }

    @include MQ("sp") {
      font-size: 12px;
      padding: 7px;
    }

  }

}
