@import "../config/*";


.footer{
  background-color: #fbfbfb;

  @include MQ("pc") {
    margin-top: 100px;
  }

  @include MQ("sp") {
    margin-top: 60px;
  }

  .sitemap{
    box-shadow: inset 0 8px 5px 0 rgba(0, 0, 0, 0.07);

    .sitemap_contents{
      margin: 0 auto;
      overflow: hidden;

      @include MQ("pc") {
        max-width: 1100px;
        padding: 40px 0 55px 0;
        width: 100%;
      }

      @include MQ("sp") {
        padding: 30px 20px;
      }

      ul{
        @include MQ("pc"){
          border-bottom: 1px solid #dfdfdf;
          float: left;
          margin-right: 8%;
          width: 19%;
        }

        @include MQ("sp"){
          display: flex;
          flex-wrap: wrap;
        }


        &:last-child{
          margin-right: 0;
        }

        li{
          a{
            border-top: 1px solid #dfdfdf;
            display: block;
            font-size: 14px;
            line-height: 40px;
            position: relative;

            @include MQ("sp"){
              background-color: #ffffff;
              border: 1px solid #35b596;
              border-radius: 6px;
              color: #35b596;
              font-size: 10px;
              line-height: initial;
              margin-bottom: 8px;
              margin-right: 10px;
              padding: 5px 10px 6px;
            }

            &::before {
              background-image: url("../../../images/portal/common/arrow.png");
              background-repeat: no-repeat;
              background-size: contain;
              bottom: 0;
              content: "";
              height: 10px;
              margin: auto;
              position: absolute;
              right: 15px;
              top: 0;
              width: 7px;

              @include MQ("sp"){
                content: none;
              }
            }

          }
        }

      }

    }

  }

  .insta{
    background: #eeeeee;
    padding: 100px 0;
    text-align: center;
  }

  .copy{
    background: #56b298;
    color: #ffffff;
    text-align: center;

    @include MQ("pc") {
      font-size: 14px;
      line-height: 50px;
    }

    @include MQ("sp") {
      font-size: 12px;
      padding: 10px 0;
    }

  }

}

