@import "variable";

/* ==================================================
  プレースホルダーの色
================================================== */

@mixin placeholder($color) {
  &::placeholder {
    color: $color;
  }

  &:-ms-input-placeholder {
    color: $color;
  }

  &::-ms-input-placeholder {
    color: $color;
  }
}

/* ==================================================
  ボタン：ベース
================================================== */

@mixin button($color) {
  background-color: $color;
  border: 0;
  border-radius: 5px;
  color: $COLOR_WHITE;
  display: block;
  line-height: 100%;
  text-align: center;
  text-decoration: none;
  transition: background-color ease-in-out 0.2s, box-shadow ease-in-out 0.2s;
  white-space: nowrap;
  width: 100%;

  &:hover {
    background-color: lighten($color, 5%);
    cursor: pointer;
  }

  &:focus {
    background-color: lighten($color, 5%);
  }

  &:active {
    box-shadow: none;
  }
}

@mixin button-lg {
  font-size: 18px;
  font-weight: bold;
  max-width: 420px;
  padding: 21px 0;

  @include MQ("sp") {
    max-width: 100%;
    padding: 12px 0;
  }

}

@mixin button-md {
  font-size: 16px;
  font-weight: bold;
  max-width: 300px;
  padding: 14px 0 13px;

  @include MQ("sp") {
    max-width: 100%;
    padding: 12px 0;
  }

}

@mixin button-sm {
  @include MQ("pc") {
    font-size: 14px;
    max-width: 220px;
    padding: 12px 0;
  }

  @include MQ("sp") {
    font-size: 16px;
    max-width: 100%;
    padding: 14px 0;
  }

}

@mixin button-xs {
  @include MQ("pc") {
    font-size: 12px;
    max-width: 80px;
    padding: 12px 0;
  }

  @include MQ("sp") {
    padding: 14px 0;
  }

}


/* ==================================================
  メディアクエリ
================================================== */
@mixin MQ($value) {
  @if $value == "pc" {
    @media screen and (min-width: $BLAKE_POINT) {
      @content;
    }
  }

  @else if $value == "sp" {
    @media screen and (max-width: $BLAKE_POINT - 1) {
      @content;
    }
  }

  @else if type-of($value) == number {
    @media screen and (min-width: $BLAKE_POINT) and (max-width: $value + px) {
      @content;
    }
  }
}
