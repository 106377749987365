@import "../config/*";

#store_detail{
  .detail_info{
    margin-bottom: 35px;

    .detail_info_l{
      .img_box{
        .img_box_contents{
          @include MQ("pc") {
            height: 360px;
          }

          @include MQ("sp") {
            height: 200px;
          }

          img{
            @include MQ("pc") {
              height: 360px;
            }

            @include MQ("sp") {
              height: 200px;
            }

          }

        }

      }

    }

    .detail_info_r{
      .store_info{
        ul{
          li{
            border-top: 1px solid #dddddd;

            @include MQ("pc") {
              padding: 15px 0;
            }

            @include MQ("sp") {
              padding: 10px 0;
            }

            &:last-child{
              border-bottom: 1px solid #dddddd;
            }

            span,p{
              display: block;
              float: none;
              width: auto;
            }

            p{
              padding-left: 0;
            }

          }

        }



      }

    }

  }

  .freetxt_area{
    margin-bottom: 70px;
  }

  .store_block{
    margin-bottom: 40px;
  }

  .contact_link_area{
    display: block;

    @include MQ("pc") {
      margin-bottom: 50px;
    }

    @include MQ("sp") {
      margin-bottom: 30px;
    }

    &.prev_footer{
      margin-top: 80px;

      @include MQ("pc") {
        margin-bottom: -100px;
      }

      @include MQ("sp") {
        margin-bottom: -60px;
      }

    }

  }
}
