@import "../config/*";

#information{
  .obi_wrap{
    margin-bottom: 20px;
  }

  .block{
    ul{
      @include MQ("pc") {
        padding-left: 20px;
      }

      @include MQ("sp") {
        padding-left: 10px;
      }

      li{
        padding-left: 2em;
        text-indent: -1.8em;
      }

    }

  }

}
