@import "../config/*";


//線色指定
$borderColor: $COLOR_PRIMARY;

//続きを見るボタンのスタイル指定
@mixin enterBtnStyle() {
  color: #ffffff;
  content: "";
}

.post-page {
  @include MQ("pc") {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 40px;
  }

  @include MQ("sp") {
    padding-bottom: 30px;
  }


  .obi_wrap {
    margin-bottom: 30px;
  }

  .wp-pagenavi {
    .pages {
      display: none;
    }

    span {
      border: none;
    }

    span.current,
    a{
      background-color: #bbbbbb;
      border: 0;
      border-radius: 5px;
      color: #ffffff;
      display: inline-block;
      font-size: 16px;
      font-weight: bold;
      height: 30px;
      line-height: 80%;
      min-width: 30px;
      padding: 9px 10px;
      text-align: center;
      text-decoration: none;
      transition: background-color ease-in-out 0.2s, box-shadow ease-in-out 0.2s;
      white-space: nowrap;

      &.current {
        background-color: $COLOR_PRIMARY;
      }
    }
  }
}

.article-list {
  @include MQ("pc") {
    width: calc(100% - 350px);
  }

  &-item {
    border-bottom: 1px solid $COLOR_BORDER;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    padding-bottom: 20px;

    @include MQ("sp") {
      margin-bottom: 11px;
    }

    &-thum {
      display: block;
      margin-right: 15px;

      @include MQ("pc") {
        height: 120px;
        width: 120px;
      }

      @include MQ("sp") {
        height: 70px;
        margin-top: 10px;
        width: 70px;
      }

      img {
        height: auto;
        width: 100%;
      }
    }

    article {
      flex: 1;
      width: 100%;
    }

    &-time {
      display: block;

      time {
        margin-right: 1em;
      }

      time,
      p {
        display: inline-block;
        font-size: 14px;
      }
    }

    &-title {
      font-size: 18px;
      line-height: 28px;

      a {
        display: block;
        text-decoration: none;
      }
    }

    &-cat {
      color: #1e1e1e;
      display: inline-block;
      font-size: 12px;
      line-height: 25px;
      margin-left: 10px;
      vertical-align: bottom;
    }

    &-text {
      line-height: 23px;
      margin-bottom: 30px;
      margin-top: 15px;

      @include MQ("sp") {
        margin-bottom: 20px;
        margin-top: 0;
      }
    }

    &-button {
      font-size: 14px;
      margin-left: auto;
      padding: 10px 0;

      @include MQ("pc"){
        max-width: 180px;
      }

      @include MQ("sp") {
        width: 100%;
      }

      a {
        @include enterBtnStyle;

        display: block;

        @include MQ("pc") {
          margin-left: auto;
        }

        @include MQ("sp") {
          margin: 0 auto;
        }
      }
    }
  }
}

.info_sidebar {
  @include MQ("pc") {
    margin-left: 50px;
    width: 300px;
  }

  @include MQ("sp") {
    margin-top: 50px;
    width: 100%;
  }

  &-title {
    font-size: 24px;
    margin-bottom: 30px;

    @include MQ("sp") {
      margin-bottom: 15px !important;
    }

    span {
      color: #575757;
      font-size: 24px;
      font-weight: bold;
      line-height: 30px;
      padding-bottom: 17px !important;

      @include MQ("sp") {
        font-size: 20px;
        padding-bottom: 10px !important;
      }
    }

    &:not(:first-child){
      margin-top: 60px;

      @include MQ("sp") {
        margin-top: 40px;
      }
    }
  }

  &-list{
    border-bottom: 1px solid #dfdfdf;
    width: 100%;

    &-item,
    li{
      border-top: 1px solid #dfdfdf;

      &:first-child{
        border-top: 0;
      }

      a{
        display: block;
        font-size: 14px;
        line-height: 40px;
        position: relative;

        &::before {
          background-image: url("../../../images/portal/common/arrow.png");
          background-repeat: no-repeat;
          background-size: contain;
          bottom: 0;
          content: "";
          height: 10px;
          margin: auto;
          position: absolute;
          right: 15px;
          top: 0;
          width: 7px;
        }
      }
    }
  }
}

.post-single {
  .article {
    @include MQ("pc") {
      width: calc(100% - 350px);
    }

    .obi_wrap {
      margin-bottom: 10px;
    }

    &-info {
      font-size: 14px;
      margin-bottom: 40px;

      @include MQ("pc") {
        margin-bottom: 30px;
      }

      time,
      p {
        display: inline-block;
      }
    }

    &-thumbnail {
      display: block;
      margin-bottom: 15px;
      width: 100%;

      @include MQ("pc") {
        height: 400px;
        position: relative;
      }

      img {
        @include MQ("pc"){
          height: auto;
          left: 50%;
          max-height: 100%;
          max-width: 100%;
          position: absolute;
          top: 50%;
          transform: translate(-50%,-50%);
          width: auto;
        }

        @include MQ("sp"){
          height: auto;
          width: 100%;
        }
      }
    }

    &-content {
      font-size: 16px;

      p {
        display: block;
        margin-bottom: 1em;
        overflow: hidden;

        img {
          margin-bottom: 20px !important;

          &:only-child {
            margin-bottom: 0 !important;
          }
        }
      }

      img {
        height: auto;
        max-height: 480px;
        max-width: 640px;
        width: auto;
      }

      strong {
        font-size: 1em;
        font-weight: bold;
      }

      em {
        font-style: italic;
      }

      blockquote {
        display: block;
        -webkit-margin-after: 1em;
        -webkit-margin-before: 1em;
        -webkit-margin-end: 40px;
        -webkit-margin-start: 40px;
      }

      .aligncenter {
        margin: 0 auto;
      }

      .alignright {
        float: right;
      }

      .alignleft {
        float: left;
      }

      .wp-caption {
        font-size: 12px;
        text-align: right;
      }

      .wp-block-image {
        overflow: hidden;

        @include MQ("pc") {
          margin-bottom: 26px;
        }

        @include MQ("sp") {
          margin-bottom: 20px;
        }
      }

      img[class*="wp-image-"],
      img[class*="attachment-"] {
        height: auto;
        max-width: 100%;
      }

      h1 {
        border-bottom: 1px solid #dddddd;
        color: #56b298;
        font-size: 32px;
        font-weight: bold;
        line-height: 1.46;
        padding-left: 20px;
        position: relative;

        @include MQ("pc"){
          margin-bottom: 15px;
          padding-bottom: 15px;
        }

        @include MQ("sp"){
          margin-bottom: 10px;
          padding-bottom: 10px;
        }

        &::before {
          background-color: #35b597;
          content: "";
          height: calc(100% - 15px);
          left: 0;
          position: absolute;
          top: 0;
          width: 6px;

          @include MQ("sp") {
            height: calc(100% - 10px);
          }
        }
      }

      h2 {
        color: #56b298;
        font-size: 30px;
        font-weight: bold;
        line-height: 1.46;
        margin-bottom: 10px;
      }

      h3 {
        font-size: 28px;
      }

      h4 {
        font-size: 24px;
      }

      h5 {
        font-size: 20px;
      }

      h6 {
        font-size: 18px;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-weight: bold;
        margin-bottom: 20px;
      }

      ul {
        list-style: disc;
        padding-left: 2em;

        li {
          list-style: inherit;
        }
      }

      ol {
        list-style: decimal;
        padding-left: 1.2em;

        li {
          list-style: inherit;
        }
      }
    }

    &-btn-c {
      margin-top: 50px;

      .btn-c {
        margin-top: 20px;
        display: flex;
        justify-content: center;

        @include MQ('sp') {
          margin-top: 15px;
        }

        a{
          &:not(:first-child){
            margin-left: 15px;
          }

          &.arrow,
          &.arrow-l {
            position: relative;

            &::before{
              content: "";
              width: 12px;
              height: 12px;
              display: block;
              position: absolute;
              border-top: 2px solid #fff;
              border-right: 2px solid #fff;
              right: 20px;
              top: 50%;
              transform: rotate(45deg) translateY(-50%);
            }
          }

          &.arrow {
            padding-right: 20px;
          }

          &.arrow-l {
            padding-left: 20px;

            &::before{
              left: 20px;
              border-right: 0;
              border-left: 2px solid #fff;
              transform: rotate(-45deg) translateY(-50%);
            }
          }
        }
      }
    }

    #pagination {
      margin-top: 40px;

      li {
        width: 220px;

        &.next {
          margin-left: 10px;
        }
      }
    }
  }
}


