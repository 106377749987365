@import "../config/*";


#flow_shopping{
  .square_step {
    ol {
      display: flex;
      justify-content: space-between;
      margin: 0 auto;

      @include MQ("pc"){
        width: 1028px;
      }

      @include MQ("sp"){
        flex-direction: column;
      }

      li {
        background-color: $COLOR_PRIMARY;
        border-radius: 7px;
        color: $COLOR_WHITE;
        font-weight: bold;
        position: relative;

        @include MQ("pc"){
          font-size: 22px;
          height: 181px;
          padding-top: 57px;
          text-align: center;
          width: 185px;
        }

        @include MQ("sp"){
          font-size: 18px;
          height: auto;
          margin-bottom: 15px;
          padding: 14px 14px 14px 50px;
          text-align: left;
          width: 100%;
        }

        span{
          border: 2px solid $COLOR_WHITE;
          border-radius: 100%;
          position: absolute;
          text-align: center;
          transform: translateX(-50%);

          @include MQ("pc"){
            height: 30px;
            left: 50%;
            line-height: 25px;
            top: 18px;
            width: 30px;
          }

          @include MQ("sp"){
            height: 25px;
            left: 10px;
            line-height: 20px;
            top: 15px;
            transform: unset;
            width: 25px;
          }
        }
      }
    }
  }

  .flow_shopping_block{
    &:not(:first-child) {
      margin-top: 55px;
    }

    @include MQ("sp"){
      margin-top: 35px;
    }

    .green_bdr_txt {
      font-weight: bold;
    }

    .img_box {
      display: flex;
      justify-content: space-between;
      padding-top: 20px;

      @include MQ("sp"){
        flex-direction: column;
        margin: 0 auto;
        width: 70%;
      }

      .img_box_contents {
        width: 349px;

        @include MQ("sp"){
          text-align: center;
          width: 100%;

          &:not(:first-child) {
            margin-top: 15px;
          }
        }
      }
    }

    .txt_box {
      letter-spacing: -0.5px;
      margin-top: 26px;

      .txt_indent {
        margin-top: 0;
        padding-left: 2em;
        text-indent: -1em;
      }

      .mt0 {
        margin-top: 0;
      }

      &:not(:first-child) {
        margin-top: 15px;
      }
    }
  }


}//#flow_shopping end
