@import "../config/*";

.form_flow {
  text-align: center;

  @include MQ("pc") {
    margin-bottom: 50px;
  }

  @include MQ("sp") {
    margin-bottom: 30px;
  }

  p{
    @include MQ("pc") {
      margin-bottom: 50px;
    }

    @include MQ("sp") {
      margin-bottom: 30px;
    }

  }

  ul {
    display: inline-block;
    overflow: hidden;

    @include MQ("sp") {
      width: 100%;
    }

    li {
      background: #acacac;
      border: 0;
      border-radius: 5px;
      color: #ffffff;
      float: left;
      font-weight: bold;
      position: relative;
      text-align: center;

      @include MQ("pc") {
        display: block;
        font-size: 18px;
        line-height: 46px;
        margin-right: 65px;
        width: 280px;
      }

      @include MQ("sp") {
        display: none;
      }
      
      &.active{
        background: #56b298;

        @include MQ("sp") {
          display: block;
          line-height: 43px;
          width: 100%;
        }

      }

      &::before {
        background-image: url("../../../images/portal/common/arrow.png");
        background-repeat: no-repeat;
        background-size: contain;
        bottom: 0;
        content: "";
        height: 29px;
        margin: auto;
        position: absolute;
        right: -43px;
        top: 0;
        width: 18px;
      }

      &:last-child{
        margin-right: 0;

        &::before{
          display: none;
        }
      }

    }

  }

}

.item_block {
  @include MQ("pc") {
    margin-bottom: 40px;
  }

  @include MQ("sp") {
    margin-bottom: 30px;
  }

  .item_contents{
    border: 1px solid #dddddd;
    margin-bottom: 2px;
    overflow: hidden;

    @include MQ("pc") {
      padding: 20px;
    }

    @include MQ("sp") {
      padding: 10px;
    }

    &:last-child{
      margin-bottom: 0;
    }

    .item_contents_l{
      @include MQ("pc") {
        float: left;
        width: 320px;
      }

      @include MQ("sp") {
        margin: 0 auto 10px;
        width: 225px;
      }

      .img_box{
        background: #dddddd;
        display: table;
        margin-bottom: 10px;
        width: 100%;

        .img_box_contents{
          display: table-cell;
          margin: 0 auto;
          position: relative;
          text-align: center;
          vertical-align: middle;
          width: 100%;

          @include MQ("pc") {
            height: 213px;
          }

          @include MQ("sp") {
            height: 150px;
          }

          img{
            @include MQ("pc") {
              max-height: 215px;
              max-width: 320px;
            }

            @include MQ("sp") {
              max-height: 150px;
              max-width: 100%;
            }

            vertical-align: middle;
          }

        }

      }

    }

    .item_contents_r{
      @include MQ("pc") {
        float: left;
        padding-left: 30px;
        width: calc(100% - 320px);
      }

      .txt_box{
        text-align: left;

        .green_txt{
          margin-bottom: 5px;
        }

        .info_txt{
          margin-bottom: 10px;

          .info_store_name{
            display: inline;
            font-size: 14px;
          }

          .info_item_name{
            display: inline;
            font-size: 14px;
          }

        }

        .price_txt{
          display: table;
          margin: 0 0 10px;
          text-align: left;

          .icn_include_price,
          .icn_only_price{
            border-radius: 6px;
            color: #ffffff;
            display: table-cell;
            font-size: 11px;
            font-weight: bold;
            height: 38px;
            letter-spacing: -0.3px;
            line-height: 1.17;
            text-align: center;
            vertical-align: middle;
            width: 50px;

            &.icn_include_price{
              background: #ff6f21;
            }

            &.icn_only_price{
              background: #56b298;
            }

          }

          .price{
            color: #ff6f21;
            display: inline-block;
            font-size: 34px;
            font-weight: bold;
            line-height: 38px;
            margin-left: 10px;
            vertical-align: middle;

            .tax{
              color: #1e1e1e;
              font-size: 16px;
              font-weight: normal;
              margin-left: 4px;
              vertical-align: top;
            }

          }

        }

        .list_price{
          font-size: 16px;
          margin-bottom: 5px;
        }

        .cat_txt{
          display: inline-block;

          span{
            background-color: #bdbdbd;
            border-radius: 4px;
            color: #ffffff;
            display: inline-block;
            font-size: 14px;
            font-weight: bold;
            line-height: 28px;
            margin: 3px;
            padding: 0 15px;
            vertical-align: middle;

            @include MQ("sp") {
              font-size: 12px;
              line-height: 20px;
            }

            &:last-child{
              margin-right: 0;
            }
          }
        }
      }

    }

  }

}

.form_block {
  table {
    @include MQ("pc") {
      margin-bottom: 30px;
    }

    @include MQ("sp") {
      margin-bottom: 20px;
    }

    tr {
      th {
        text-align: center;

        @include MQ("pc") {
          font-size: 18px;
          width: 290px;
        }

        div{
          position: relative;

          span.required {
            background-color: $COLOR_ORANGE;
            border-radius: 3px;
            color: $COLOR_WHITE;
            position: absolute;
            right: 0;
            text-align: center;
            top: 50%;
            transform: translateY(-50%);

            @include MQ("pc") {
              font-size: 14px;
              height: 28px;
              padding: 2px 0;
              width: 55px;
            }

            @include MQ("sp") {
              font-size: 12px;
              padding: 3px;
            }

          }

        }

      }

      td {
        @include MQ("pc") {
          padding: 12px 25px 12px 35px;
        }

        input[type="text"] {
          @include MQ("pc") {
            margin-right: 28px;
            padding: 10px;
            width: 314px;
          }

        }

        .input[type="radio"],
        .input[type="checkbox"] {
          margin-right: 6px;
        }

        textarea {
          font-size: 16px;
          height: 183px;
        }

        .form-malti-select {
          @include MQ("pc") {
            padding: 10px 0;
          }

          @include MQ("sp") {
            text-align: left;
          }

          br {
            @include MQ("sp"){
              display: none;
            }
          }

          label {
            line-height: 25px;

            @include MQ("sp"){
              display: block;
            }

            input{
              margin-right: 10px;
            }

          }
        }

        &.address_inner {
          input[type="text"]{
            &.w168 {
              @include MQ("pc") {
                margin-left: 15px;
                width: 168px;
              }

              @include MQ("sp") {
                display: inline-block;
                margin-left: 10px;
                width: calc(100% - 26px);
              }

            }

            &.w400 {
              margin-left: 10px;

              @include MQ("pc") {
                width: 400px;
              }

              @include MQ("sp") {
                display: inline-block;
                width: calc(100% - 51px);
              }

            }

            &.w440 {
              width: 440px;

              @include MQ("sp") {
                display: inline-block;
                margin-left: 10px;
                width: calc(100% - 51px);
              }

            }

          }

          div{
            @include MQ("pc") {
              display: inline-block;
            }

            @include MQ("sp") {
              display: block;
            }

            &:not(:last-child) {
              margin-bottom: 10px;
            }
          }

        }

        .sample{
          @include MQ("sp") {
            display: block;
            font-size: 14px;
            margin-top: 5px;
          }
        }

      }

    }

  }

  .btn-c {
    text-align: center;

    .form_btn1{
      position: relative;

      @include MQ("pc") {
        margin: 0 auto;
        width: 456px;
      }

      .btn-lg {
        @include MQ("pc") {
          max-width: 100%;
        }

      }
    }

    .form_btn2{
      overflow: hidden;

      @include MQ("pc") {
        margin: 0 auto;
        width: 600px;
      }

      li{
        position: relative;

        @include MQ("pc") {
          float: left;
          margin-right: 2%;
          width: 49%;
        }

        @include MQ("sp") {
          width: 100%;
        }

        &:first-child{
          @include MQ("sp") {
            margin-bottom: 10px;
          }
        }

        &:last-child{
          margin-right: 0;
        }

        input{
          max-width: 100%;
        }

      }

    }

    .form_btn1,
    .form_btn2 li{
      &::before {
        color: $COLOR_WHITE;
        content: "＞";
        margin: 0 auto;
        position: absolute;

        @include MQ("pc") {
          left: 44px;
          pointer-events: none;
          top: 18px;
          transform: scaleY(1.8);
        }

        @include MQ("sp") {
          bottom: 0;
          left: 20px;
          top: 8px;
          transform: scaleY(1);
        }

      }
    }

  }

}

.accept{
  background: #f7f7f7;
  overflow: hidden;
  text-align: center;

  @include MQ("pc") {
    margin-bottom: 60px;
    padding: 20px;
  }

  @include MQ("sp") {
    margin-bottom: 30px;
    padding: 10px;
  }

  label{
    @include MQ("sp") {
      display: flex;
      justify-content: center;
      text-align: center;
    }

    input{
      @include MQ("sp") {
        margin-top: 6px;
      }
    }

    p{
      display: inline-block;

      @include MQ("sp") {
        padding-left: 10px;
        text-align: left;
      }

      a{
        text-decoration: underline;
      }

    }

  }


}
