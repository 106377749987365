@charset "UTF-8";
/* http://meyerweb.com/eric/tools/css/reset/
   v5.0.1 | 20191019
   License: none (public domain)
*/
@import "~swiper/css/swiper.css";
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, menu, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
main, menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, main, menu, nav, section {
  display: block;
}

/* HTML5 hidden-attribute fix for newer browsers */
*[hidden] {
  display: none;
}

body {
  line-height: 1;
}

menu, ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

@font-face {
  font-family: "lg";
  src: url("../fonts/lg.eot?n1z373");
  src: url("../fonts/lg.eot?#iefixn1z373") format("embedded-opentype"), url("../fonts/lg.woff?n1z373") format("woff"), url("../fonts/lg.ttf?n1z373") format("truetype"), url("../fonts/lg.svg?n1z373#lg") format("svg");
  font-weight: normal;
  font-style: normal;
}
.lg-icon {
  font-family: "lg";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.lg-actions .lg-next, .lg-actions .lg-prev {
  background-color: rgba(0, 0, 0, 0.45);
  border-radius: 2px;
  color: #999;
  cursor: pointer;
  display: block;
  font-size: 22px;
  margin-top: -10px;
  padding: 8px 10px 9px;
  position: absolute;
  top: 50%;
  z-index: 1080;
}
.lg-actions .lg-next.disabled, .lg-actions .lg-prev.disabled {
  pointer-events: none;
  opacity: 0.5;
}
.lg-actions .lg-next:hover, .lg-actions .lg-prev:hover {
  color: #FFF;
}
.lg-actions .lg-next {
  right: 20px;
}
.lg-actions .lg-next:before {
  content: "";
}
.lg-actions .lg-prev {
  left: 20px;
}
.lg-actions .lg-prev:after {
  content: "";
}

@-webkit-keyframes lg-right-end {
  0% {
    left: 0;
  }
  50% {
    left: -30px;
  }
  100% {
    left: 0;
  }
}
@-moz-keyframes lg-right-end {
  0% {
    left: 0;
  }
  50% {
    left: -30px;
  }
  100% {
    left: 0;
  }
}
@-ms-keyframes lg-right-end {
  0% {
    left: 0;
  }
  50% {
    left: -30px;
  }
  100% {
    left: 0;
  }
}
@keyframes lg-right-end {
  0% {
    left: 0;
  }
  50% {
    left: -30px;
  }
  100% {
    left: 0;
  }
}
@-webkit-keyframes lg-left-end {
  0% {
    left: 0;
  }
  50% {
    left: 30px;
  }
  100% {
    left: 0;
  }
}
@-moz-keyframes lg-left-end {
  0% {
    left: 0;
  }
  50% {
    left: 30px;
  }
  100% {
    left: 0;
  }
}
@-ms-keyframes lg-left-end {
  0% {
    left: 0;
  }
  50% {
    left: 30px;
  }
  100% {
    left: 0;
  }
}
@keyframes lg-left-end {
  0% {
    left: 0;
  }
  50% {
    left: 30px;
  }
  100% {
    left: 0;
  }
}
.lg-outer.lg-right-end .lg-object {
  -webkit-animation: lg-right-end 0.3s;
  -o-animation: lg-right-end 0.3s;
  animation: lg-right-end 0.3s;
  position: relative;
}
.lg-outer.lg-left-end .lg-object {
  -webkit-animation: lg-left-end 0.3s;
  -o-animation: lg-left-end 0.3s;
  animation: lg-left-end 0.3s;
  position: relative;
}

.lg-toolbar {
  z-index: 1082;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.45);
}
.lg-toolbar .lg-icon {
  color: #999;
  cursor: pointer;
  float: right;
  font-size: 24px;
  height: 47px;
  line-height: 27px;
  padding: 10px 0;
  text-align: center;
  width: 50px;
  text-decoration: none !important;
  outline: medium none;
  -webkit-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  transition: color 0.2s linear;
}
.lg-toolbar .lg-icon:hover {
  color: #FFF;
}
.lg-toolbar .lg-close:after {
  content: "";
}
.lg-toolbar .lg-download:after {
  content: "";
}

.lg-sub-html {
  background-color: rgba(0, 0, 0, 0.45);
  bottom: 0;
  color: #EEE;
  font-size: 16px;
  left: 0;
  padding: 10px 40px;
  position: fixed;
  right: 0;
  text-align: center;
  z-index: 1080;
}
.lg-sub-html h4 {
  margin: 0;
  font-size: 13px;
  font-weight: bold;
}
.lg-sub-html p {
  font-size: 12px;
  margin: 5px 0 0;
}

#lg-counter {
  color: #999;
  display: inline-block;
  font-size: 16px;
  padding-left: 20px;
  padding-top: 12px;
  vertical-align: middle;
}

.lg-toolbar, .lg-prev, .lg-next {
  opacity: 1;
  -webkit-transition: -webkit-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
  -moz-transition: -moz-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
  -o-transition: -o-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
  transition: transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
}

.lg-hide-items .lg-prev {
  opacity: 0;
  -webkit-transform: translate3d(-10px, 0, 0);
  transform: translate3d(-10px, 0, 0);
}
.lg-hide-items .lg-next {
  opacity: 0;
  -webkit-transform: translate3d(10px, 0, 0);
  transform: translate3d(10px, 0, 0);
}
.lg-hide-items .lg-toolbar {
  opacity: 0;
  -webkit-transform: translate3d(0, -10px, 0);
  transform: translate3d(0, -10px, 0);
}

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-object {
  -webkit-transform: scale3d(0.5, 0.5, 0.5);
  transform: scale3d(0.5, 0.5, 0.5);
  opacity: 0;
  -webkit-transition: -webkit-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  -moz-transition: -moz-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  -o-transition: -o-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  transition: transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}
body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item.lg-complete .lg-object {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  opacity: 1;
}

.lg-outer .lg-thumb-outer {
  background-color: #0D0A0A;
  bottom: 0;
  position: absolute;
  width: 100%;
  z-index: 1080;
  max-height: 350px;
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
  -webkit-transition: -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  -moz-transition: -moz-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  -o-transition: -o-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
}
.lg-outer .lg-thumb-outer.lg-grab .lg-thumb-item {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab;
}
.lg-outer .lg-thumb-outer.lg-grabbing .lg-thumb-item {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing;
}
.lg-outer .lg-thumb-outer.lg-dragging .lg-thumb {
  -webkit-transition-duration: 0s !important;
  transition-duration: 0s !important;
}
.lg-outer.lg-thumb-open .lg-thumb-outer {
  -webkit-transform: translate3d(0, 0%, 0);
  transform: translate3d(0, 0%, 0);
}
.lg-outer .lg-thumb {
  padding: 10px 0;
  height: 100%;
  margin-bottom: -5px;
}
.lg-outer .lg-thumb-item {
  border-radius: 5px;
  cursor: pointer;
  float: left;
  overflow: hidden;
  height: 100%;
  border: 2px solid #FFF;
  border-radius: 4px;
  margin-bottom: 5px;
}
@media (min-width: 1025px) {
  .lg-outer .lg-thumb-item {
    -webkit-transition: border-color 0.25s ease;
    -o-transition: border-color 0.25s ease;
    transition: border-color 0.25s ease;
  }
}
.lg-outer .lg-thumb-item.active, .lg-outer .lg-thumb-item:hover {
  border-color: #a90707;
}
.lg-outer .lg-thumb-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.lg-outer.lg-has-thumb .lg-item {
  padding-bottom: 120px;
}
.lg-outer.lg-can-toggle .lg-item {
  padding-bottom: 0;
}
.lg-outer.lg-pull-caption-up .lg-sub-html {
  -webkit-transition: bottom 0.25s ease;
  -o-transition: bottom 0.25s ease;
  transition: bottom 0.25s ease;
}
.lg-outer.lg-pull-caption-up.lg-thumb-open .lg-sub-html {
  bottom: 100px;
}
.lg-outer .lg-toggle-thumb {
  background-color: #0D0A0A;
  border-radius: 2px 2px 0 0;
  color: #999;
  cursor: pointer;
  font-size: 24px;
  height: 39px;
  line-height: 27px;
  padding: 5px 0;
  position: absolute;
  right: 20px;
  text-align: center;
  top: -39px;
  width: 50px;
}
.lg-outer .lg-toggle-thumb:after {
  content: "";
}
.lg-outer .lg-toggle-thumb:hover {
  color: #FFF;
}

.lg-outer .lg-video-cont {
  display: inline-block;
  vertical-align: middle;
  max-width: 1140px;
  max-height: 100%;
  width: 100%;
  padding: 0 5px;
}
.lg-outer .lg-video {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;
  position: relative;
}
.lg-outer .lg-video .lg-object {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}
.lg-outer .lg-video .lg-video-play {
  width: 84px;
  height: 59px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -42px;
  margin-top: -30px;
  z-index: 1080;
  cursor: pointer;
}
.lg-outer .lg-has-vimeo .lg-video-play {
  background: url("../img/vimeo-play.png") no-repeat scroll 0 0 transparent;
}
.lg-outer .lg-has-vimeo:hover .lg-video-play {
  background: url("../img/vimeo-play.png") no-repeat scroll 0 -58px transparent;
}
.lg-outer .lg-has-html5 .lg-video-play {
  background: transparent url("../img/video-play.png") no-repeat scroll 0 0;
  height: 64px;
  margin-left: -32px;
  margin-top: -32px;
  width: 64px;
  opacity: 0.8;
}
.lg-outer .lg-has-html5:hover .lg-video-play {
  opacity: 1;
}
.lg-outer .lg-has-youtube .lg-video-play {
  background: url("../img/youtube-play.png") no-repeat scroll 0 0 transparent;
}
.lg-outer .lg-has-youtube:hover .lg-video-play {
  background: url("../img/youtube-play.png") no-repeat scroll 0 -60px transparent;
}
.lg-outer .lg-video-object {
  width: 100% !important;
  height: 100% !important;
  position: absolute;
  top: 0;
  left: 0;
}
.lg-outer .lg-has-video .lg-video-object {
  visibility: hidden;
}
.lg-outer .lg-has-video.lg-video-playing .lg-object, .lg-outer .lg-has-video.lg-video-playing .lg-video-play {
  display: none;
}
.lg-outer .lg-has-video.lg-video-playing .lg-video-object {
  visibility: visible;
}

.lg-progress-bar {
  background-color: #333;
  height: 5px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1083;
  opacity: 0;
  -webkit-transition: opacity 0.08s ease 0s;
  -moz-transition: opacity 0.08s ease 0s;
  -o-transition: opacity 0.08s ease 0s;
  transition: opacity 0.08s ease 0s;
}
.lg-progress-bar .lg-progress {
  background-color: #a90707;
  height: 5px;
  width: 0;
}
.lg-progress-bar.lg-start .lg-progress {
  width: 100%;
}
.lg-show-autoplay .lg-progress-bar {
  opacity: 1;
}

.lg-autoplay-button:after {
  content: "";
}
.lg-show-autoplay .lg-autoplay-button:after {
  content: "";
}

.lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-img-wrap, .lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-image {
  -webkit-transition-duration: 0s;
  transition-duration: 0s;
}
.lg-outer.lg-use-transition-for-zoom .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -moz-transition: -moz-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -o-transition: -o-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
}
.lg-outer.lg-use-left-for-zoom .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -webkit-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -moz-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -o-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
}
.lg-outer .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}
.lg-outer .lg-item.lg-complete.lg-zoomable .lg-image {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  -moz-transition: -moz-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  -o-transition: -o-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  transition: transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}

#lg-zoom-in:after {
  content: "";
}

#lg-actual-size {
  font-size: 20px;
}
#lg-actual-size:after {
  content: "";
}

#lg-zoom-out {
  opacity: 0.5;
  pointer-events: none;
}
#lg-zoom-out:after {
  content: "";
}
.lg-zoomed #lg-zoom-out {
  opacity: 1;
  pointer-events: auto;
}

.lg-outer .lg-pager-outer {
  bottom: 60px;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  z-index: 1080;
  height: 10px;
}
.lg-outer .lg-pager-outer.lg-pager-hover .lg-pager-cont {
  overflow: visible;
}
.lg-outer .lg-pager-cont {
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  position: relative;
  vertical-align: top;
  margin: 0 5px;
}
.lg-outer .lg-pager-cont:hover .lg-pager-thumb-cont {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.lg-outer .lg-pager-cont.lg-pager-active .lg-pager {
  box-shadow: 0 0 0 2px white inset;
}
.lg-outer .lg-pager-thumb-cont {
  background-color: #fff;
  color: #FFF;
  bottom: 100%;
  height: 83px;
  left: 0;
  margin-bottom: 20px;
  margin-left: -60px;
  opacity: 0;
  padding: 5px;
  position: absolute;
  width: 120px;
  border-radius: 3px;
  -webkit-transition: opacity 0.15s ease 0s, -webkit-transform 0.15s ease 0s;
  -moz-transition: opacity 0.15s ease 0s, -moz-transform 0.15s ease 0s;
  -o-transition: opacity 0.15s ease 0s, -o-transform 0.15s ease 0s;
  transition: opacity 0.15s ease 0s, transform 0.15s ease 0s;
  -webkit-transform: translate3d(0, 5px, 0);
  transform: translate3d(0, 5px, 0);
}
.lg-outer .lg-pager-thumb-cont img {
  width: 100%;
  height: 100%;
}
.lg-outer .lg-pager {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.7) inset;
  display: block;
  height: 12px;
  -webkit-transition: box-shadow 0.3s ease 0s;
  -o-transition: box-shadow 0.3s ease 0s;
  transition: box-shadow 0.3s ease 0s;
  width: 12px;
}
.lg-outer .lg-pager:hover, .lg-outer .lg-pager:focus {
  box-shadow: 0 0 0 8px white inset;
}
.lg-outer .lg-caret {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px dashed;
  bottom: -10px;
  display: inline-block;
  height: 0;
  left: 50%;
  margin-left: -5px;
  position: absolute;
  vertical-align: middle;
  width: 0;
}

.lg-fullscreen:after {
  content: "";
}
.lg-fullscreen-on .lg-fullscreen:after {
  content: "";
}

.lg-outer #lg-dropdown-overlay {
  background-color: rgba(0, 0, 0, 0.25);
  bottom: 0;
  cursor: default;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1081;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: visibility 0s linear 0.18s, opacity 0.18s linear 0s;
  -o-transition: visibility 0s linear 0.18s, opacity 0.18s linear 0s;
  transition: visibility 0s linear 0.18s, opacity 0.18s linear 0s;
}
.lg-outer.lg-dropdown-active .lg-dropdown, .lg-outer.lg-dropdown-active #lg-dropdown-overlay {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  -moz-transform: translate3d(0, 0px, 0);
  -o-transform: translate3d(0, 0px, 0);
  -ms-transform: translate3d(0, 0px, 0);
  -webkit-transform: translate3d(0, 0px, 0);
  transform: translate3d(0, 0px, 0);
  opacity: 1;
  visibility: visible;
}
.lg-outer.lg-dropdown-active #lg-share {
  color: #FFF;
}
.lg-outer .lg-dropdown {
  background-color: #fff;
  border-radius: 2px;
  font-size: 14px;
  list-style-type: none;
  margin: 0;
  padding: 10px 0;
  position: absolute;
  right: 0;
  text-align: left;
  top: 50px;
  opacity: 0;
  visibility: hidden;
  -moz-transform: translate3d(0, 5px, 0);
  -o-transform: translate3d(0, 5px, 0);
  -ms-transform: translate3d(0, 5px, 0);
  -webkit-transform: translate3d(0, 5px, 0);
  transform: translate3d(0, 5px, 0);
  -webkit-transition: -webkit-transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s;
  -moz-transition: -moz-transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s;
  -o-transition: -o-transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s;
  transition: transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s;
}
.lg-outer .lg-dropdown:after {
  content: "";
  display: block;
  height: 0;
  width: 0;
  position: absolute;
  border: 8px solid transparent;
  border-bottom-color: #FFF;
  right: 16px;
  top: -16px;
}
.lg-outer .lg-dropdown > li:last-child {
  margin-bottom: 0px;
}
.lg-outer .lg-dropdown > li:hover a, .lg-outer .lg-dropdown > li:hover .lg-icon {
  color: #333;
}
.lg-outer .lg-dropdown a {
  color: #333;
  display: block;
  white-space: pre;
  padding: 4px 12px;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px;
}
.lg-outer .lg-dropdown a:hover {
  background-color: rgba(0, 0, 0, 0.07);
}
.lg-outer .lg-dropdown .lg-dropdown-text {
  display: inline-block;
  line-height: 1;
  margin-top: -3px;
  vertical-align: middle;
}
.lg-outer .lg-dropdown .lg-icon {
  color: #333;
  display: inline-block;
  float: none;
  font-size: 20px;
  height: auto;
  line-height: 1;
  margin-right: 8px;
  padding: 0;
  vertical-align: middle;
  width: auto;
}
.lg-outer #lg-share {
  position: relative;
}
.lg-outer #lg-share:after {
  content: "";
}
.lg-outer #lg-share-facebook .lg-icon {
  color: #3b5998;
}
.lg-outer #lg-share-facebook .lg-icon:after {
  content: "";
}
.lg-outer #lg-share-twitter .lg-icon {
  color: #00aced;
}
.lg-outer #lg-share-twitter .lg-icon:after {
  content: "";
}
.lg-outer #lg-share-googleplus .lg-icon {
  color: #dd4b39;
}
.lg-outer #lg-share-googleplus .lg-icon:after {
  content: "";
}
.lg-outer #lg-share-pinterest .lg-icon {
  color: #cb2027;
}
.lg-outer #lg-share-pinterest .lg-icon:after {
  content: "";
}

.group {
  *zoom: 1;
}

.group:before, .group:after {
  display: table;
  content: "";
  line-height: 0;
}

.group:after {
  clear: both;
}

.lg-outer {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  opacity: 0;
  -webkit-transition: opacity 0.15s ease 0s;
  -o-transition: opacity 0.15s ease 0s;
  transition: opacity 0.15s ease 0s;
}
.lg-outer * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.lg-outer.lg-visible {
  opacity: 1;
}
.lg-outer.lg-css3 .lg-item.lg-prev-slide, .lg-outer.lg-css3 .lg-item.lg-next-slide, .lg-outer.lg-css3 .lg-item.lg-current {
  -webkit-transition-duration: inherit !important;
  transition-duration: inherit !important;
  -webkit-transition-timing-function: inherit !important;
  transition-timing-function: inherit !important;
}
.lg-outer.lg-css3.lg-dragging .lg-item.lg-prev-slide, .lg-outer.lg-css3.lg-dragging .lg-item.lg-next-slide, .lg-outer.lg-css3.lg-dragging .lg-item.lg-current {
  -webkit-transition-duration: 0s !important;
  transition-duration: 0s !important;
  opacity: 1;
}
.lg-outer.lg-grab img.lg-object {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab;
}
.lg-outer.lg-grabbing img.lg-object {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing;
}
.lg-outer .lg {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  max-height: 100%;
}
.lg-outer .lg-inner {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  white-space: nowrap;
}
.lg-outer .lg-item {
  background: url("../img/loading.gif") no-repeat scroll center center transparent;
  display: none !important;
}
.lg-outer.lg-css3 .lg-prev-slide, .lg-outer.lg-css3 .lg-current, .lg-outer.lg-css3 .lg-next-slide {
  display: inline-block !important;
}
.lg-outer.lg-css .lg-current {
  display: inline-block !important;
}
.lg-outer .lg-item, .lg-outer .lg-img-wrap {
  display: inline-block;
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
}
.lg-outer .lg-item:before, .lg-outer .lg-img-wrap:before {
  content: "";
  display: inline-block;
  height: 50%;
  width: 1px;
  margin-right: -1px;
}
.lg-outer .lg-img-wrap {
  position: absolute;
  padding: 0 5px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.lg-outer .lg-item.lg-complete {
  background-image: none;
}
.lg-outer .lg-item.lg-current {
  z-index: 1060;
}
.lg-outer .lg-image {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  max-height: 100%;
  width: auto !important;
  height: auto !important;
}
.lg-outer.lg-show-after-load .lg-item .lg-object, .lg-outer.lg-show-after-load .lg-item .lg-video-play {
  opacity: 0;
  -webkit-transition: opacity 0.15s ease 0s;
  -o-transition: opacity 0.15s ease 0s;
  transition: opacity 0.15s ease 0s;
}
.lg-outer.lg-show-after-load .lg-item.lg-complete .lg-object, .lg-outer.lg-show-after-load .lg-item.lg-complete .lg-video-play {
  opacity: 1;
}
.lg-outer .lg-empty-html {
  display: none;
}
.lg-outer.lg-hide-download #lg-download {
  display: none;
}

.lg-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1040;
  background-color: #000;
  opacity: 0;
  -webkit-transition: opacity 0.15s ease 0s;
  -o-transition: opacity 0.15s ease 0s;
  transition: opacity 0.15s ease 0s;
}
.lg-backdrop.in {
  opacity: 1;
}

.lg-css3.lg-no-trans .lg-prev-slide, .lg-css3.lg-no-trans .lg-next-slide, .lg-css3.lg-no-trans .lg-current {
  -webkit-transition: none 0s ease 0s !important;
  -moz-transition: none 0s ease 0s !important;
  -o-transition: none 0s ease 0s !important;
  transition: none 0s ease 0s !important;
}
.lg-css3.lg-use-css3 .lg-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}
.lg-css3.lg-use-left .lg-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}
.lg-css3.lg-fade .lg-item {
  opacity: 0;
}
.lg-css3.lg-fade .lg-item.lg-current {
  opacity: 1;
}
.lg-css3.lg-fade .lg-item.lg-prev-slide, .lg-css3.lg-fade .lg-item.lg-next-slide, .lg-css3.lg-fade .lg-item.lg-current {
  -webkit-transition: opacity 0.1s ease 0s;
  -moz-transition: opacity 0.1s ease 0s;
  -o-transition: opacity 0.1s ease 0s;
  transition: opacity 0.1s ease 0s;
}
.lg-css3.lg-slide.lg-use-css3 .lg-item {
  opacity: 0;
}
.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide {
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}
.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide {
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}
.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
}
.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}
.lg-css3.lg-slide.lg-use-left .lg-item {
  opacity: 0;
  position: absolute;
  left: 0;
}
.lg-css3.lg-slide.lg-use-left .lg-item.lg-prev-slide {
  left: -100%;
}
.lg-css3.lg-slide.lg-use-left .lg-item.lg-next-slide {
  left: 100%;
}
.lg-css3.lg-slide.lg-use-left .lg-item.lg-current {
  left: 0;
  opacity: 1;
}
.lg-css3.lg-slide.lg-use-left .lg-item.lg-prev-slide, .lg-css3.lg-slide.lg-use-left .lg-item.lg-next-slide, .lg-css3.lg-slide.lg-use-left .lg-item.lg-current {
  -webkit-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}

/* ==================================================
  カラー
================================================== */
/* ==================================================
  シャドウ
================================================== */
/* ==================================================
  ブレイクポイント
================================================== */
/* ==================================================
  プレースホルダーの色
================================================== */
/* ==================================================
  ボタン：ベース
================================================== */
/* ==================================================
  メディアクエリ
================================================== */
/* ==================================================
  カラー
================================================== */
/* ==================================================
  シャドウ
================================================== */
/* ==================================================
  ブレイクポイント
================================================== */
/* ==================================================
   ボタン：ノーマル（グリーン）
================================================== */
.btn-lg {
  background-color: #35b597;
  border: 0;
  border-radius: 5px;
  color: #ffffff;
  display: block;
  line-height: 100%;
  text-align: center;
  text-decoration: none;
  transition: background-color ease-in-out 0.2s, box-shadow ease-in-out 0.2s;
  white-space: nowrap;
  width: 100%;
  font-size: 18px;
  font-weight: bold;
  max-width: 420px;
  padding: 21px 0;
}
.btn-lg:hover {
  background-color: #3dc6a6;
  cursor: pointer;
}
.btn-lg:focus {
  background-color: #3dc6a6;
}
.btn-lg:active {
  box-shadow: none;
}
@media screen and (max-width: 768px) {
  .btn-lg {
    max-width: 100%;
    padding: 12px 0;
  }
}

.btn-md {
  background-color: #35b597;
  border: 0;
  border-radius: 5px;
  color: #ffffff;
  display: block;
  line-height: 100%;
  text-align: center;
  text-decoration: none;
  transition: background-color ease-in-out 0.2s, box-shadow ease-in-out 0.2s;
  white-space: nowrap;
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  max-width: 300px;
  padding: 14px 0 13px;
}
.btn-md:hover {
  background-color: #3dc6a6;
  cursor: pointer;
}
.btn-md:focus {
  background-color: #3dc6a6;
}
.btn-md:active {
  box-shadow: none;
}
@media screen and (max-width: 768px) {
  .btn-md {
    max-width: 100%;
    padding: 12px 0;
  }
}

.btn-sm {
  background-color: #35b597;
  border: 0;
  border-radius: 5px;
  color: #ffffff;
  display: block;
  line-height: 100%;
  text-align: center;
  text-decoration: none;
  transition: background-color ease-in-out 0.2s, box-shadow ease-in-out 0.2s;
  white-space: nowrap;
  width: 100%;
}
.btn-sm:hover {
  background-color: #3dc6a6;
  cursor: pointer;
}
.btn-sm:focus {
  background-color: #3dc6a6;
}
.btn-sm:active {
  box-shadow: none;
}
@media screen and (min-width: 769px) {
  .btn-sm {
    font-size: 14px;
    max-width: 220px;
    padding: 12px 0;
  }
}
@media screen and (max-width: 768px) {
  .btn-sm {
    font-size: 16px;
    max-width: 100%;
    padding: 14px 0;
  }
}

.btn-xs {
  background-color: #35b597;
  border: 0;
  border-radius: 5px;
  color: #ffffff;
  display: block;
  line-height: 100%;
  text-align: center;
  text-decoration: none;
  transition: background-color ease-in-out 0.2s, box-shadow ease-in-out 0.2s;
  white-space: nowrap;
  width: 100%;
}
.btn-xs:hover {
  background-color: #3dc6a6;
  cursor: pointer;
}
.btn-xs:focus {
  background-color: #3dc6a6;
}
.btn-xs:active {
  box-shadow: none;
}
@media screen and (min-width: 769px) {
  .btn-xs {
    font-size: 12px;
    max-width: 80px;
    padding: 12px 0;
  }
}
@media screen and (max-width: 768px) {
  .btn-xs {
    padding: 14px 0;
  }
}

/* ==================================================
   ボタン：ノーマル（オレンジ）
================================================== */
.btn-org-lg {
  background-color: #ff6f21;
  border: 0;
  border-radius: 5px;
  color: #ffffff;
  display: block;
  line-height: 100%;
  text-align: center;
  text-decoration: none;
  transition: background-color ease-in-out 0.2s, box-shadow ease-in-out 0.2s;
  white-space: nowrap;
  width: 100%;
  font-size: 18px;
  font-weight: bold;
  max-width: 420px;
  padding: 21px 0;
}
.btn-org-lg:hover {
  background-color: #ff803b;
  cursor: pointer;
}
.btn-org-lg:focus {
  background-color: #ff803b;
}
.btn-org-lg:active {
  box-shadow: none;
}
@media screen and (max-width: 768px) {
  .btn-org-lg {
    max-width: 100%;
    padding: 12px 0;
  }
}

.btn-org-md {
  background-color: #ff6f21;
  border: 0;
  border-radius: 5px;
  color: #ffffff;
  display: block;
  line-height: 100%;
  text-align: center;
  text-decoration: none;
  transition: background-color ease-in-out 0.2s, box-shadow ease-in-out 0.2s;
  white-space: nowrap;
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  max-width: 300px;
  padding: 14px 0 13px;
}
.btn-org-md:hover {
  background-color: #ff803b;
  cursor: pointer;
}
.btn-org-md:focus {
  background-color: #ff803b;
}
.btn-org-md:active {
  box-shadow: none;
}
@media screen and (max-width: 768px) {
  .btn-org-md {
    max-width: 100%;
    padding: 12px 0;
  }
}

.btn-org-sm {
  background-color: #ff6f21;
  border: 0;
  border-radius: 5px;
  color: #ffffff;
  display: block;
  line-height: 100%;
  text-align: center;
  text-decoration: none;
  transition: background-color ease-in-out 0.2s, box-shadow ease-in-out 0.2s;
  white-space: nowrap;
  width: 100%;
}
.btn-org-sm:hover {
  background-color: #ff803b;
  cursor: pointer;
}
.btn-org-sm:focus {
  background-color: #ff803b;
}
.btn-org-sm:active {
  box-shadow: none;
}
@media screen and (min-width: 769px) {
  .btn-org-sm {
    font-size: 14px;
    max-width: 220px;
    padding: 12px 0;
  }
}
@media screen and (max-width: 768px) {
  .btn-org-sm {
    font-size: 16px;
    max-width: 100%;
    padding: 14px 0;
  }
}

.btn-org-xs {
  background-color: #ff6f21;
  border: 0;
  border-radius: 5px;
  color: #ffffff;
  display: block;
  line-height: 100%;
  text-align: center;
  text-decoration: none;
  transition: background-color ease-in-out 0.2s, box-shadow ease-in-out 0.2s;
  white-space: nowrap;
  width: 100%;
}
.btn-org-xs:hover {
  background-color: #ff803b;
  cursor: pointer;
}
.btn-org-xs:focus {
  background-color: #ff803b;
}
.btn-org-xs:active {
  box-shadow: none;
}
@media screen and (min-width: 769px) {
  .btn-org-xs {
    font-size: 12px;
    max-width: 80px;
    padding: 12px 0;
  }
}
@media screen and (max-width: 768px) {
  .btn-org-xs {
    padding: 14px 0;
  }
}

/* ==================================================
   ボタン：キャンセル系（グレー）
================================================== */
.btn-gry-lg {
  background-color: #777777;
  border: 0;
  border-radius: 5px;
  color: #ffffff;
  display: block;
  line-height: 100%;
  text-align: center;
  text-decoration: none;
  transition: background-color ease-in-out 0.2s, box-shadow ease-in-out 0.2s;
  white-space: nowrap;
  width: 100%;
  font-size: 18px;
  font-weight: bold;
  max-width: 420px;
  padding: 21px 0;
}
.btn-gry-lg:hover {
  background-color: #848484;
  cursor: pointer;
}
.btn-gry-lg:focus {
  background-color: #848484;
}
.btn-gry-lg:active {
  box-shadow: none;
}
@media screen and (max-width: 768px) {
  .btn-gry-lg {
    max-width: 100%;
    padding: 12px 0;
  }
}

.btn-gry-md {
  background-color: #777777;
  border: 0;
  border-radius: 5px;
  color: #ffffff;
  display: block;
  line-height: 100%;
  text-align: center;
  text-decoration: none;
  transition: background-color ease-in-out 0.2s, box-shadow ease-in-out 0.2s;
  white-space: nowrap;
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  max-width: 300px;
  padding: 14px 0 13px;
}
.btn-gry-md:hover {
  background-color: #848484;
  cursor: pointer;
}
.btn-gry-md:focus {
  background-color: #848484;
}
.btn-gry-md:active {
  box-shadow: none;
}
@media screen and (max-width: 768px) {
  .btn-gry-md {
    max-width: 100%;
    padding: 12px 0;
  }
}

.btn-gry-sm {
  background-color: #777777;
  border: 0;
  border-radius: 5px;
  color: #ffffff;
  display: block;
  line-height: 100%;
  text-align: center;
  text-decoration: none;
  transition: background-color ease-in-out 0.2s, box-shadow ease-in-out 0.2s;
  white-space: nowrap;
  width: 100%;
}
.btn-gry-sm:hover {
  background-color: #848484;
  cursor: pointer;
}
.btn-gry-sm:focus {
  background-color: #848484;
}
.btn-gry-sm:active {
  box-shadow: none;
}
@media screen and (min-width: 769px) {
  .btn-gry-sm {
    font-size: 14px;
    max-width: 220px;
    padding: 12px 0;
  }
}
@media screen and (max-width: 768px) {
  .btn-gry-sm {
    font-size: 16px;
    max-width: 100%;
    padding: 14px 0;
  }
}

.btn-gry-xs {
  background-color: #777777;
  border: 0;
  border-radius: 5px;
  color: #ffffff;
  display: block;
  line-height: 100%;
  text-align: center;
  text-decoration: none;
  transition: background-color ease-in-out 0.2s, box-shadow ease-in-out 0.2s;
  white-space: nowrap;
  width: 100%;
}
.btn-gry-xs:hover {
  background-color: #848484;
  cursor: pointer;
}
.btn-gry-xs:focus {
  background-color: #848484;
}
.btn-gry-xs:active {
  box-shadow: none;
}
@media screen and (min-width: 769px) {
  .btn-gry-xs {
    font-size: 12px;
    max-width: 80px;
    padding: 12px 0;
  }
}
@media screen and (max-width: 768px) {
  .btn-gry-xs {
    padding: 14px 0;
  }
}

/* ==================================================
  カラー
================================================== */
/* ==================================================
  シャドウ
================================================== */
/* ==================================================
  ブレイクポイント
================================================== */
/* ==================================================
  プレースホルダーの色
================================================== */
/* ==================================================
  ボタン：ベース
================================================== */
/* ==================================================
  メディアクエリ
================================================== */
/* ==================================================
  カラー
================================================== */
/* ==================================================
  シャドウ
================================================== */
/* ==================================================
  ブレイクポイント
================================================== */
/* ==================================================
   エラー
================================================== */
.error {
  background: #ffdddd;
  color: #ff0000;
  line-height: 2.5em;
  margin-bottom: 30px;
  padding: 20px 40px;
}

/* ==================================================
  カラー
================================================== */
/* ==================================================
  シャドウ
================================================== */
/* ==================================================
  ブレイクポイント
================================================== */
/* ==================================================
  プレースホルダーの色
================================================== */
/* ==================================================
  ボタン：ベース
================================================== */
/* ==================================================
  メディアクエリ
================================================== */
/* ==================================================
  カラー
================================================== */
/* ==================================================
  シャドウ
================================================== */
/* ==================================================
  ブレイクポイント
================================================== */
.footer {
  background-color: #fbfbfb;
}
@media screen and (min-width: 769px) {
  .footer {
    margin-top: 100px;
  }
}
@media screen and (max-width: 768px) {
  .footer {
    margin-top: 60px;
  }
}
.footer .sitemap {
  box-shadow: inset 0 8px 5px 0 rgba(0, 0, 0, 0.07);
}
.footer .sitemap .sitemap_contents {
  margin: 0 auto;
  overflow: hidden;
}
@media screen and (min-width: 769px) {
  .footer .sitemap .sitemap_contents {
    max-width: 1100px;
    padding: 40px 0 55px 0;
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .footer .sitemap .sitemap_contents {
    padding: 30px 20px;
  }
}
@media screen and (min-width: 769px) {
  .footer .sitemap .sitemap_contents ul {
    border-bottom: 1px solid #dfdfdf;
    float: left;
    margin-right: 8%;
    width: 19%;
  }
}
@media screen and (max-width: 768px) {
  .footer .sitemap .sitemap_contents ul {
    display: flex;
    flex-wrap: wrap;
  }
}
.footer .sitemap .sitemap_contents ul:last-child {
  margin-right: 0;
}
.footer .sitemap .sitemap_contents ul li a {
  border-top: 1px solid #dfdfdf;
  display: block;
  font-size: 14px;
  line-height: 40px;
  position: relative;
}
@media screen and (max-width: 768px) {
  .footer .sitemap .sitemap_contents ul li a {
    background-color: #ffffff;
    border: 1px solid #35b596;
    border-radius: 6px;
    color: #35b596;
    font-size: 10px;
    line-height: initial;
    margin-bottom: 8px;
    margin-right: 10px;
    padding: 5px 10px 6px;
  }
}
.footer .sitemap .sitemap_contents ul li a::before {
  background-image: url("../../../images/portal/common/arrow.png");
  background-repeat: no-repeat;
  background-size: contain;
  bottom: 0;
  content: "";
  height: 10px;
  margin: auto;
  position: absolute;
  right: 15px;
  top: 0;
  width: 7px;
}
@media screen and (max-width: 768px) {
  .footer .sitemap .sitemap_contents ul li a::before {
    content: none;
  }
}
.footer .insta {
  background: #eeeeee;
  padding: 100px 0;
  text-align: center;
}
.footer .copy {
  background: #56b298;
  color: #ffffff;
  text-align: center;
}
@media screen and (min-width: 769px) {
  .footer .copy {
    font-size: 14px;
    line-height: 50px;
  }
}
@media screen and (max-width: 768px) {
  .footer .copy {
    font-size: 12px;
    padding: 10px 0;
  }
}

/* ==================================================
  カラー
================================================== */
/* ==================================================
  シャドウ
================================================== */
/* ==================================================
  ブレイクポイント
================================================== */
/* ==================================================
  プレースホルダーの色
================================================== */
/* ==================================================
  ボタン：ベース
================================================== */
/* ==================================================
  メディアクエリ
================================================== */
/* ==================================================
  カラー
================================================== */
/* ==================================================
  シャドウ
================================================== */
/* ==================================================
  ブレイクポイント
================================================== */
@media screen and (min-width: 769px) {
  .header {
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 768px) {
  .header {
    margin-bottom: 30px;
    margin-top: 60px;
  }
}
.header .header_content {
  display: table;
  overflow: hidden;
  position: relative;
  width: 100%;
}
@media screen and (min-width: 769px) {
  .header .header_content {
    height: 135px;
    padding: 0 20px 0;
  }
}
@media screen and (max-width: 768px) {
  .header .header_content {
    background: #ffffff;
    height: 60px;
    left: 0;
    padding: 0 10px 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
  }
}
.header .header_content .header_l {
  display: table-cell;
  position: relative;
  vertical-align: middle;
  width: auto;
}
.header .header_content .header_l a, .header .header_content .header_l p {
  display: inline-block;
  vertical-align: middle;
}
.header .header_content .header_l p {
  font-size: 14px;
  margin-left: 15px;
}
@media screen and (max-width: 768px) {
  .header .header_content .header_l p {
    display: none;
  }
}
@media screen and (min-width: 769px) {
  .header .header_content .header_l a {
    height: 85px;
    width: 270px;
  }
}
@media screen and (max-width: 768px) {
  .header .header_content .header_l a {
    height: 32px;
    width: 100px;
  }
}
.header .header_content .header_l a img {
  height: auto;
  left: 0;
  width: 100%;
}
.header .header_content ul.sub_menu, .header .header_content a.btn-gry-md {
  position: absolute;
  right: 20px;
}
.header .header_content ul.sub_menu {
  top: 15px;
}
@media screen and (min-width: 769px) {
  .header .header_content ul.sub_menu {
    display: inline-block;
  }
}
@media screen and (max-width: 768px) {
  .header .header_content ul.sub_menu {
    display: none;
  }
}
.header .header_content ul.sub_menu li {
  display: inline-block;
  margin-left: 40px;
  position: relative;
}
.header .header_content ul.sub_menu li:first-child {
  margin-left: 0;
}
.header .header_content ul.sub_menu li:first-child::before {
  display: none;
}
.header .header_content ul.sub_menu li::before {
  background: #1e1e1e;
  bottom: 0;
  content: "";
  height: 14px;
  left: -20px;
  margin: auto;
  position: absolute;
  top: 0;
  width: 1px;
}
.header .header_content ul.sub_menu li a {
  display: block;
  font-size: 14px;
}
@media screen and (min-width: 769px) {
  .header .header_content a.btn-gry-md span {
    display: inline;
  }
}
@media screen and (max-width: 768px) {
  .header .header_content a.btn-gry-md span {
    display: none;
  }
}
@media screen and (min-width: 769px) {
  .header .header_content a.btn-gry-md {
    bottom: 30px;
    font-size: 14px;
    font-weight: normal;
    max-width: 280px;
  }
}
@media screen and (max-width: 768px) {
  .header .header_content a.btn-gry-md {
    border-radius: 3px;
    font-size: 11px;
    line-height: 26px;
    max-width: 140px;
    padding: 0;
    right: 55px;
    top: 50%;
    transform: translateY(-50%);
  }
}
.header .header_content #sp_menu_btn {
  cursor: pointer;
  height: 22px;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
}
@media screen and (min-width: 769px) {
  .header .header_content #sp_menu_btn {
    display: none;
  }
}
.header .header_content #sp_menu_btn .sp_menu_btn_contents {
  box-sizing: border-box;
  display: inline-block;
  height: 22px;
  position: relative;
  width: 100%;
}
.header .header_content #sp_menu_btn .sp_menu_btn_contents span {
  background-color: #56b298;
  border-radius: 4px;
  box-sizing: border-box;
  display: inline-block;
  height: 4px;
  left: 0;
  position: absolute;
  width: 100%;
}
.header .header_content #sp_menu_btn .sp_menu_btn_contents span:nth-of-type(1) {
  top: 0;
}
.header .header_content #sp_menu_btn .sp_menu_btn_contents span:nth-of-type(2) {
  bottom: 0;
  margin: auto;
  top: 0;
}
.header .header_content #sp_menu_btn .sp_menu_btn_contents span:nth-of-type(3) {
  bottom: 0;
}
.header .main_menu {
  background: #ffffff;
  overflow: hidden;
}
@media screen and (max-width: 768px) {
  .header .main_menu {
    display: block;
    max-height: 0;
    overflow: hidden;
    position: fixed;
    top: 60px;
    -webkit-transition: max-height 0.1s;
    transition: max-height 0.1s;
    width: 100%;
    z-index: 100;
  }
}
@media screen and (min-width: 769px) {
  .header .main_menu li {
    float: left;
    width: 14.285%;
  }
}
@media screen and (max-width: 768px) {
  .header .main_menu li {
    width: 100%;
  }
}
@media screen and (min-width: 769px) {
  .header .main_menu li:first-child a::before {
    display: none;
  }
}
.header .main_menu li a {
  display: block;
  position: relative;
}
@media screen and (min-width: 769px) {
  .header .main_menu li a {
    padding: 10px 0 42px 0;
    text-align: center;
  }
}
@media screen and (max-width: 768px) {
  .header .main_menu li a {
    padding: 10px 0 10px 0;
    text-align: left;
  }
}
@media screen and (min-width: 769px) and (max-width: 1200px) {
  .header .main_menu li a {
    padding: 0 0 38px 0;
  }
}
.header .main_menu li a::before {
  content: "";
  position: absolute;
}
@media screen and (min-width: 769px) {
  .header .main_menu li a::before {
    border-right: 1px dashed #c5c5c5;
    bottom: 0;
    height: 75px;
    left: 0;
    margin: auto;
    top: 0;
    width: 1px;
  }
}
@media screen and (max-width: 768px) {
  .header .main_menu li a::before {
    border-bottom: 1px dashed #c5c5c5;
    bottom: 0;
    height: 1px;
    left: 0;
    margin: auto;
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .header .main_menu li a .imgbox {
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    width: 80px;
  }
}
.header .main_menu li a .imgbox img {
  height: 46px;
  width: auto;
}
@media screen and (max-width: 768px) {
  .header .main_menu li a .imgbox img {
    display: inline-block;
    height: 25px;
    vertical-align: middle;
  }
}
@media screen and (min-width: 769px) and (max-width: 1200px) {
  .header .main_menu li a .imgbox img {
    height: 35px;
  }
}
.header .main_menu li a span {
  display: block;
}
@media screen and (min-width: 769px) {
  .header .main_menu li a span {
    font-size: 18px;
    margin-top: 3px;
  }
}
@media screen and (max-width: 768px) {
  .header .main_menu li a span {
    display: inline-block;
    margin-left: 10px;
    vertical-align: middle;
  }
}
@media screen and (min-width: 769px) and (max-width: 1200px) {
  .header .main_menu li a span {
    font-size: 16px;
  }
}
@media screen and (max-width: 768px) {
  .header .main_menu li:nth-child(5) a img {
    height: 22px;
  }
}
.header .main_menu li.bg a {
  background: #eeeeee;
}
@media screen and (max-width: 768px) {
  .header .main_menu li.bg a span {
    margin-left: 95px;
  }
}
.header .main_menu.open {
  border-top: 1px dashed #c5c5c5;
  max-height: 480px;
}
.header .page_ttl {
  background: #56b298;
  color: #ffffff;
  text-align: center;
}
@media screen and (min-width: 769px) {
  .header .page_ttl {
    padding: 38px 0 50px;
  }
}
@media screen and (max-width: 768px) {
  .header .page_ttl .page_ttl_txt {
    padding: 20px 0;
  }
}
.header .page_ttl .pankuzu {
  text-align: center;
}
@media screen and (max-width: 768px) {
  .header .page_ttl .pankuzu {
    display: none;
  }
}
.header .page_ttl .pankuzu li {
  display: inline-block;
  font-size: 14px;
  position: relative;
}
.header .page_ttl .pankuzu li::after {
  content: ">";
  margin: 0 8px 0 11px;
}
.header .page_ttl .pankuzu li:last-child {
  margin-right: 0;
}
.header .page_ttl .pankuzu li:last-child::after {
  display: none;
}
.header .page_ttl .pankuzu li a {
  color: #ffffff;
  font-size: 14px;
}

/* ==================================================
  カラー
================================================== */
/* ==================================================
  シャドウ
================================================== */
/* ==================================================
  ブレイクポイント
================================================== */
/* ==================================================
  プレースホルダーの色
================================================== */
/* ==================================================
  ボタン：ベース
================================================== */
/* ==================================================
  メディアクエリ
================================================== */
/* ==================================================
  カラー
================================================== */
/* ==================================================
  シャドウ
================================================== */
/* ==================================================
  ブレイクポイント
================================================== */
/* ==================================================
   ページネーション
================================================== */
.pagenation {
  align-items: center;
  background-color: #f7f7f7;
  display: flex;
  justify-content: center;
  padding: 15px 0;
}
.pagenation a, .pagenation span {
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  color: #777777;
  display: block;
  line-height: 100%;
  text-align: center;
}
@media screen and (min-width: 769px) {
  .pagenation a, .pagenation span {
    padding: 12px 13px 11px;
  }
}
@media screen and (max-width: 768px) {
  .pagenation a, .pagenation span {
    font-size: 14px;
    padding: 12px 10px 11px;
  }
}
@media screen and (max-width: 768px) {
  .pagenation a, .pagenation span {
    font-size: 12px;
    padding: 8px 9px 7px;
  }
}
.pagenation a:not(:first-child), .pagenation span:not(:first-child) {
  margin-left: 5px;
}
.pagenation a {
  outline: none;
  text-decoration: none;
  transition: background-color ease-in-out 0.2s, box-shadow ease-in-out 0.2s;
}
.pagenation a:hover, .pagenation a:focus {
  background-color: #f1f1f1;
}
.pagenation a:active {
  box-shadow: none;
}
.pagenation .current {
  background-color: #35b597;
  color: #ffffff;
}
.pagenation .prev,
.pagenation .next {
  position: relative;
}
@media screen and (min-width: 769px) {
  .pagenation .prev,
.pagenation .next {
    height: 39px;
    width: 37px;
  }
}
@media screen and (max-width: 768px) {
  .pagenation .prev,
.pagenation .next {
    height: 27px;
    width: 27px;
  }
}
.pagenation .prev::before,
.pagenation .next::before {
  background-repeat: no-repeat;
  background-size: cover;
  content: "";
  height: 10px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 6px;
}
.pagenation .prev::before {
  background-image: url("../../../images/admin/icon/icon_prev.svg");
  left: calc(50% - 1px);
}
.pagenation .next::before {
  background-image: url("../../../images/admin/icon/icon_next.svg");
  left: 50%;
}
.pagenation .total {
  background-color: #f1f1f1;
  border: 1px solid #dddddd;
  border-radius: 5px;
  color: #777777;
  display: block;
  line-height: 100%;
  text-align: center;
}
@media screen and (min-width: 769px) {
  .pagenation .total {
    font-size: 16px;
    padding: 11px 13px 10px;
  }
}
@media screen and (max-width: 768px) {
  .pagenation .total {
    font-size: 12px;
    padding: 7px;
  }
}

/* ==================================================
  カラー
================================================== */
/* ==================================================
  シャドウ
================================================== */
/* ==================================================
  ブレイクポイント
================================================== */
/* ==================================================
  プレースホルダーの色
================================================== */
/* ==================================================
  ボタン：ベース
================================================== */
/* ==================================================
  メディアクエリ
================================================== */
/* ==================================================
  カラー
================================================== */
/* ==================================================
  シャドウ
================================================== */
/* ==================================================
  ブレイクポイント
================================================== */
/* Pannellum 2.5.6, https://github.com/mpetroff/pannellum */
.pnlm-container {
  background: #f4f4f4 url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2267%22%20height%3D%22100%22%20viewBox%3D%220%200%2067%20100%22%3E%0A%3Cpath%20stroke%3D%22%23ccc%22%20fill%3D%22none%22%20d%3D%22M33.5%2C50%2C0%2C63%2C33.5%2C75%2C67%2C63%2C33.5%2C50m-33.5-50%2C67%2C25m-0.5%2C0%2C0%2C75m-66.5-75%2C67-25m-33.5%2C75%2C0%2C25m0-100%2C0%2C50%22%2F%3E%0A%3C%2Fsvg%3E%0A") repeat;
  contain: content;
  cursor: default;
  font-family: Helvetica, "Nimbus Sans L", "Liberation Sans", Arial, sans-serif;
  height: 100%;
  line-height: 1.4;
  margin: 0;
  outline: 0;
  overflow: hidden;
  padding: 0;
  position: relative;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
}

.pnlm-container * {
  box-sizing: content-box;
}

.pnlm-ui {
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 1;
}

.pnlm-grab {
  cursor: grab;
  cursor: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20height%3D%2226%22%20width%3D%2226%22%3E%0A%3Cpath%20stroke%3D%22%23000%22%20stroke-width%3D%221px%22%20fill%3D%22%23fff%22%20d%3D%22m15.3%2020.5s6.38-6.73%204.64-8.24-3.47%201.01-3.47%201.01%203.61-5.72%201.41-6.49c-2.2-0.769-3.33%204.36-3.33%204.36s0.873-5.76-1.06-5.76-1.58%205.39-1.58%205.39-0.574-4.59-2.18-4.12c-1.61%200.468-0.572%205.51-0.572%205.51s-1.58-4.89-2.93-3.79c-1.35%201.11%200.258%205.25%200.572%206.62%200.836%202.43%202.03%202.94%202.17%205.55%22%2F%3E%0A%3C%2Fsvg%3E%0A") 12 8, default;
}

.pnlm-grabbing {
  cursor: grabbing;
  cursor: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20height%3D%2226%22%20width%3D%2226%22%3E%0A%3Cpath%20stroke%3D%22%23000%22%20stroke-width%3D%221px%22%20fill%3D%22%23fff%22%20d%3D%22m15.3%2020.5s5.07-5.29%203.77-6.74c-1.31-1.45-2.53%200.14-2.53%200.14s2.74-3.29%200.535-4.06c-2.2-0.769-2.52%201.3-2.52%201.3s0.81-2.13-1.12-2.13-1.52%201.77-1.52%201.77-0.261-1.59-1.87-1.12c-1.61%200.468-0.874%202.17-0.874%202.17s-0.651-1.55-2-0.445c-1.35%201.11-0.68%202.25-0.365%203.62%200.836%202.43%202.03%202.94%202.17%205.55%22%2F%3E%0A%3C%2Fsvg%3E%0A") 12 8, default;
}

.pnlm-sprite {
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2226%22%20height%3D%22208%22%3E%0A%3Ccircle%20fill-opacity%3D%22.78%22%20cy%3D%22117%22%20cx%3D%2213%22%20r%3D%2211%22%20fill%3D%22%23fff%22%2F%3E%0A%3Ccircle%20fill-opacity%3D%22.78%22%20cy%3D%22143%22%20cx%3D%2213%22%20r%3D%2211%22%20fill%3D%22%23fff%22%2F%3E%0A%3Ccircle%20cy%3D%22169%22%20cx%3D%2213%22%20r%3D%227%22%20fill%3D%22none%22%20stroke%3D%22%23000%22%20stroke-width%3D%222%22%2F%3E%0A%3Ccircle%20cy%3D%22195%22%20cx%3D%2213%22%20r%3D%227%22%20fill%3D%22none%22%20stroke%3D%22%23000%22%20stroke-width%3D%222%22%2F%3E%0A%3Ccircle%20cx%3D%2213%22%20cy%3D%22195%22%20r%3D%222.5%22%2F%3E%0A%3Cpath%20d%3D%22m5%2083v6h2v-4h4v-2zm10%200v2h4v4h2v-6zm-5%205v6h6v-6zm-5%205v6h6v-2h-4v-4zm14%200v4h-4v2h6v-6z%22%2F%3E%0A%3Cpath%20d%3D%22m13%20110a7%207%200%200%200%20-7%207%207%207%200%200%200%207%207%207%207%200%200%200%207%20-7%207%207%200%200%200%20-7%20-7zm-1%203h2v2h-2zm0%203h2v5h-2z%22%2F%3E%0A%3Cpath%20d%3D%22m5%2057v6h2v-4h4v-2zm10%200v2h4v4h2v-6zm-10%2010v6h6v-2h-4v-4zm14%200v4h-4v2h6v-6z%22%2F%3E%0A%3Cpath%20d%3D%22m17%2038v2h-8v-2z%22%2F%3E%0A%3Cpath%20d%3D%22m12%209v3h-3v2h3v3h2v-3h3v-2h-3v-3z%22%2F%3E%0A%3Cpath%20d%3D%22m13%20136-6.125%206.125h4.375v7.875h3.5v-7.875h4.375z%22%2F%3E%0A%3Cpath%20d%3D%22m10.428%20173.33v-5.77l5-2.89v5.77zm1-1.73%203-1.73-3.001-1.74z%22%2F%3E%0A%3C%2Fsvg%3E%0A");
}

.pnlm-container:-moz-full-screen {
  height: 100%;
  position: static;
  width: 100%;
}

.pnlm-container:-webkit-full-screen {
  height: 100%;
  position: static;
  width: 100%;
}

.pnlm-container:-ms-fullscreen {
  height: 100%;
  position: static;
  width: 100%;
}

.pnlm-container:fullscreen {
  height: 100%;
  position: static;
  width: 100%;
}

.pnlm-render-container {
  cursor: inherit;
  height: 100%;
  position: absolute;
  width: 100%;
}

.pnlm-controls {
  background-color: #ffffff;
  border: 1px solid #999999;
  border-color: rgba(0, 0, 0, 0.4);
  border-radius: 3px;
  cursor: pointer;
  margin-top: 4px;
  -webkit-transform: translateZ(9999px);
  transform: translateZ(9999px);
  z-index: 2;
}

.pnlm-control:hover {
  background-color: #f8f8f8;
}

.pnlm-controls-container {
  left: 4px;
  position: absolute;
  top: 0;
  z-index: 1;
}

.pnlm-zoom-controls {
  height: 52px;
  width: 26px;
}

.pnlm-zoom-in {
  border-radius: 3px 3px 0 0;
  height: 50%;
  position: absolute;
  top: 0;
  width: 100%;
}

.pnlm-zoom-out {
  background-position: 0 -26px;
  border-radius: 0 0 3px 3px;
  border-top: 1px solid #dddddd;
  border-top-color: rgba(0, 0, 0, 0.1);
  bottom: 0;
  height: 50%;
  position: absolute;
  width: 100%;
}

.pnlm-fullscreen-toggle-button,
.pnlm-orientation-button,
.pnlm-hot-spot-debug-indicator {
  height: 26px;
  width: 26px;
}

.pnlm-hot-spot-debug-indicator {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 13px;
  display: none;
  height: 26px;
  left: 50%;
  margin: -13px 0 0 -13px;
  position: absolute;
  top: 50%;
  width: 26px;
}

.pnlm-orientation-button-inactive {
  background-position: 0 -156px;
}

.pnlm-orientation-button-active {
  background-position: 0 -182px;
}

.pnlm-fullscreen-toggle-button-inactive {
  background-position: 0 -52px;
}

.pnlm-fullscreen-toggle-button-active {
  background-position: 0 -78px;
}

.pnlm-panorama-info {
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 0 3px 3px 0;
  bottom: 4px;
  color: #ffffff;
  display: none;
  padding-right: 10px;
  position: absolute;
  text-align: left;
  -webkit-transform: translateZ(9999px);
  transform: translateZ(9999px);
  z-index: 2;
}

.pnlm-title-box {
  display: table;
  font-size: 20px;
  margin-bottom: 3px;
  padding-left: 5px;
  position: relative;
}

.pnlm-author-box {
  display: table;
  font-size: 12px;
  padding-left: 5px;
  position: relative;
}

.pnlm-load-box {
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 3px;
  color: #ffffff;
  display: none;
  font-size: 20px;
  height: 150px;
  left: 50%;
  margin: -75px 0 0 -100px;
  position: absolute;
  text-align: center;
  top: 50%;
  width: 200px;
}

.pnlm-load-box p {
  margin: 20px 0;
}

.pnlm-lbox {
  display: none;
  height: 20px;
  left: 50%;
  margin: -10px 0 0 -10px;
  position: absolute;
  top: 50%;
  width: 20px;
}

.pnlm-loading {
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: pnlm-mv;
  animation-name: pnlm-mv;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  background-color: #ffffff;
  height: 10px;
  position: relative;
  width: 10px;
}

@keyframes pnlm-mv {
  from {
    left: 0;
    top: 0;
  }
  25% {
    left: 10px;
    top: 0;
  }
  50% {
    left: 10px;
    top: 10px;
  }
  75% {
    left: 0;
    top: 10px;
  }
  to {
    left: 0;
    top: 0;
  }
}
@keyframes pnlm-mv {
  from {
    left: 0;
    top: 0;
  }
  25% {
    left: 10px;
    top: 0;
  }
  50% {
    left: 10px;
    top: 10px;
  }
  75% {
    left: 0;
    top: 10px;
  }
  to {
    left: 0;
    top: 0;
  }
}
.pnlm-load-button {
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 3px;
  color: #ffffff;
  cursor: pointer;
  display: table;
  font-size: 20px;
  height: 100px;
  left: 50%;
  margin: -50px 0 0 -100px;
  position: absolute;
  text-align: center;
  top: 50%;
  width: 200px;
}

.pnlm-load-button:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.pnlm-load-button p {
  display: table-cell;
  vertical-align: middle;
}

.pnlm-info-box {
  background-color: #000000;
  border-radius: 3px;
  color: #ffffff;
  display: table;
  font-size: 15px;
  height: 150px;
  left: 50%;
  margin: -75px 0 0 -100px;
  position: absolute;
  table-layout: fixed;
  text-align: center;
  top: 50%;
  width: 200px;
}

.pnlm-info-box a,
.pnlm-author-box a {
  color: #ffffff;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.pnlm-info-box p {
  display: table-cell;
  padding: 0 5px 0 5px;
  vertical-align: middle;
}

.pnlm-error-msg {
  display: none;
}

.pnlm-about-msg {
  background: rgba(0, 0, 0, 0.7);
  border-radius: 3px;
  color: #ffffff;
  display: none;
  font-size: 11px;
  left: 50px;
  line-height: 11px;
  opacity: 0;
  padding: 5px 8px 5px 8px;
  position: absolute;
  top: 50px;
  -moz-transition: opacity 0.3s ease-in-out;
  -webkit-transition: opacity 0.3s ease-in-out;
  -o-transition: opacity 0.3s ease-in-out;
  -ms-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
  z-index: 1;
}

.pnlm-about-msg a:link,
.pnlm-about-msg a:visited {
  color: #ffffff;
}

.pnlm-about-msg a:hover,
.pnlm-about-msg a:active {
  color: #eeeeee;
}

.pnlm-hotspot-base {
  cursor: default;
  position: absolute;
  top: 0;
  vertical-align: middle;
  visibility: hidden;
  z-index: 1;
}

.pnlm-hotspot {
  border-radius: 13px;
  height: 26px;
  width: 26px;
}

.pnlm-hotspot:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.pnlm-hotspot.pnlm-info {
  background-position: 0 -104px;
}

.pnlm-hotspot.pnlm-scene {
  background-position: 0 -130px;
}

div.pnlm-tooltip span {
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 3px;
  color: #ffffff;
  cursor: default;
  margin-left: -220px;
  max-width: 200px;
  padding: 5px 10px;
  position: absolute;
  text-align: center;
  visibility: hidden;
}

div.pnlm-tooltip:hover span {
  visibility: visible;
}

div.pnlm-tooltip:hover span::after {
  border-color: rgba(0, 0, 0, 0.7) transparent transparent transparent;
  border-style: solid;
  border-width: 10px;
  bottom: -20px;
  content: "";
  height: 0;
  left: -10px;
  margin: 0 50%;
  position: absolute;
  width: 0;
}

.pnlm-compass {
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20height%3D%2250%22%20width%3D%2250%22%3E%0A%3Cpath%20d%3D%22m24.5078%206-3.2578%2018h7.5l-3.25781-18h-0.984376zm-3.2578%2020%203.2578%2018h0.9844l3.2578-18h-7.5zm1.19531%200.9941h5.10938l-2.5547%2014.1075-2.5547-14.1075z%22%2F%3E%0A%3C%2Fsvg%3E%0A");
  border-radius: 25px;
  bottom: 4px;
  cursor: default;
  display: none;
  height: 50px;
  position: absolute;
  right: 4px;
  width: 50px;
}

.pnlm-world {
  left: 50%;
  position: absolute;
  top: 50%;
}

.pnlm-face {
  position: absolute;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

.pnlm-dragfix,
.pnlm-preview-img {
  height: 100%;
  position: absolute;
  width: 100%;
}

.pnlm-preview-img {
  background-position: center;
  background-size: cover;
}

.pnlm-lbar {
  border: #ffffff 1px solid;
  height: 6px;
  margin: 0 auto;
  width: 150px;
}

.pnlm-lbar-fill {
  background: #ffffff;
  height: 100%;
  width: 0;
}

.pnlm-lmsg {
  font-size: 12px;
}

.pnlm-fade-img {
  left: 0;
  position: absolute;
  top: 0;
}

.pnlm-pointer {
  cursor: pointer;
}

/* ==================================================
  カラー
================================================== */
/* ==================================================
  シャドウ
================================================== */
/* ==================================================
  ブレイクポイント
================================================== */
/* ==================================================
  プレースホルダーの色
================================================== */
/* ==================================================
  ボタン：ベース
================================================== */
/* ==================================================
  メディアクエリ
================================================== */
/* ==================================================
  カラー
================================================== */
/* ==================================================
  シャドウ
================================================== */
/* ==================================================
  ブレイクポイント
================================================== */
#about .page_img {
  position: relative;
}
#about .page_img .txt_box {
  bottom: 18px;
  left: 50%;
  position: absolute;
  text-align: center;
  transform: translateX(-50%);
  width: 100%;
}
@media screen and (max-width: 768px) {
  #about .page_img .txt_box {
    margin-top: 15px;
    position: static;
    text-align: left;
    transform: unset;
  }
}
#about .page_img .txt_box .green_txt {
  color: #35b597;
  font-size: 28px;
  margin-bottom: 25px;
}
@media screen and (max-width: 768px) {
  #about .page_img .txt_box .green_txt {
    font-size: 18px;
    margin-bottom: 15px;
  }
}
#about .page_img .txt_box p {
  font-size: 15px;
}
@media screen and (min-width: 769px) {
  #about .graph_img {
    margin: 40px auto 60px auto;
    width: 840px;
  }
}
@media screen and (max-width: 768px) {
  #about .graph_img {
    margin: 20px auto 30px auto;
    width: 100%;
  }
}
#about .graph_img img {
  width: 100%;
}
#about .about_block:not(:first-child) {
  margin-top: 35px;
}
#about .green_bdr_txt {
  padding-left: 20px;
  position: relative;
}
#about .green_bdr_txt::before {
  background-color: #35b597;
  content: "";
  height: calc(1em * 1.42);
  left: 0;
  position: absolute;
  top: 0;
  width: 6px;
}
#about .about_contents {
  display: flex;
}
@media screen and (max-width: 768px) {
  #about .about_contents {
    flex-direction: column;
  }
}
#about .about_contents .about_contents_l {
  width: calc(100% - 280px);
}
@media screen and (max-width: 768px) {
  #about .about_contents .about_contents_l {
    order: 2;
    width: 100%;
  }
}
#about .about_contents .about_contents_r {
  margin-left: 40px;
  width: 240px;
}
@media screen and (max-width: 768px) {
  #about .about_contents .about_contents_r {
    margin-bottom: 10px;
    margin-left: 0;
    order: 1;
    width: 100%;
  }
}
#about .about_contents .about_contents_r .img_box .img_box_contents {
  text-align: center;
}
#about .g_frame_double {
  display: flex;
}
@media screen and (max-width: 768px) {
  #about .g_frame_double {
    flex-direction: column;
  }
}
#about .g_frame_double .g_frame {
  border: 7px solid #35b597;
  border-radius: 5px;
  border-top: 0;
  flex: 1;
  overflow: hidden;
}
@media screen and (min-width: 769px) {
  #about .g_frame_double .g_frame:not(:first-child) {
    margin-left: 20px;
  }
}
@media screen and (max-width: 768px) {
  #about .g_frame_double .g_frame:not(:first-child) {
    margin-top: 20px;
  }
}
#about .g_frame_double .g_frame h3 {
  background-color: #35b597;
  color: #ffffff;
  font-size: 40px;
  font-weight: bold;
  padding: 10px 0 10px 10px;
}
@media screen and (max-width: 768px) {
  #about .g_frame_double .g_frame h3 {
    font-size: 20px;
  }
}
#about .g_frame_double .g_frame h3 span {
  height: 42px;
  margin-right: 25px;
  vertical-align: text-top;
}
#about .g_frame_double .g_frame h3 span img {
  height: 42px;
  width: auto;
}
#about .g_frame_double .g_frame .txt_box {
  height: 240px;
  letter-spacing: -0.07em;
}
@media screen and (min-width: 769px) {
  #about .g_frame_double .g_frame .txt_box {
    padding: 20px 24px 30px 24px;
  }
}
@media screen and (max-width: 768px) {
  #about .g_frame_double .g_frame .txt_box {
    height: auto;
    padding: 20px;
  }
}
#about .g_frame_double .g_frame .txt_box .green_txt {
  font-size: 28px;
  letter-spacing: -1px;
  margin-bottom: 15px;
}
@media screen and (max-width: 768px) {
  #about .g_frame_double .g_frame .txt_box .green_txt {
    font-size: 23px;
  }
}
#about .g_frame_double .g_frame .btn-wrap {
  padding: 0 28px 30px;
}
@media screen and (max-width: 768px) {
  #about .g_frame_double .g_frame .btn-wrap {
    padding: 0 20px 20px 20px;
  }
}
#about .g_frame_double .g_frame .btn-wrap .btn-org-md {
  border-radius: 10px;
  position: relative;
}
@media screen and (min-width: 769px) {
  #about .g_frame_double .g_frame .btn-wrap .btn-org-md {
    font-size: 25px;
    height: 84px;
    max-width: 100%;
    padding: 28px 0 28px 40px;
  }
}
@media screen and (max-width: 768px) {
  #about .g_frame_double .g_frame .btn-wrap .btn-org-md {
    font-size: 14px;
  }
}
#about .g_frame_double .g_frame .btn-wrap .btn-org-md::before {
  background-repeat: no-repeat;
  background-size: auto 46px;
  content: "";
  height: 46px;
  left: 40px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 76px;
}
@media screen and (max-width: 768px) {
  #about .g_frame_double .g_frame .btn-wrap .btn-org-md::before {
    background-size: auto 20px;
    flex-direction: column;
    height: 20px;
    left: 12%;
  }
}
#about .g_frame_double .g_frame.buy .btn-wrap .btn-org-md::before {
  background-image: url("../../../images/portal/about/about_btn_icon01.png");
}
#about .g_frame_double .g_frame.sell .btn-wrap .btn-org-md::before {
  background-image: url("../../../images/portal/about/about_btn_icon02.png");
}

/* ==================================================
  カラー
================================================== */
/* ==================================================
  シャドウ
================================================== */
/* ==================================================
  ブレイクポイント
================================================== */
/* ==================================================
  プレースホルダーの色
================================================== */
/* ==================================================
  ボタン：ベース
================================================== */
/* ==================================================
  メディアクエリ
================================================== */
/* ==================================================
  カラー
================================================== */
/* ==================================================
  シャドウ
================================================== */
/* ==================================================
  ブレイクポイント
================================================== */
body {
  color: #1e1e1e;
  font-family: "Noto Sans JP", sans-serif;
  position: relative;
  -webkit-text-size-adjust: 100%;
  word-break: break-all;
}
@media screen and (min-width: 769px) {
  body {
    line-height: 1.7;
    min-width: 1100px;
  }
}
@media screen and (max-width: 768px) {
  body {
    font-size: 16px;
    line-height: 1.3;
    overflow-x: hidden;
  }
}

.wrapper {
  margin: 0 auto;
}
@media screen and (min-width: 769px) {
  .wrapper {
    max-width: 1100px;
  }
}
@media screen and (max-width: 768px) {
  .wrapper {
    padding: 0 10px 0;
    width: 100%;
  }
}

p, td, th, li {
  font-size: 16px;
}
@media screen and (max-width: 768px) {
  p, td, th, li {
    line-height: 1.5;
  }
}

p + p {
  margin-top: 1.6rem;
}

a {
  color: #1e1e1e;
  text-decoration: none;
}
a:hover {
  opacity: 0.5;
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

img {
  max-width: 100%;
}

input[type=submit],
input[type=button],
input[type=text],
button,
select,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  font-size: 16px;
  outline: none;
}

textarea {
  padding: 10px;
  resize: vertical;
  width: 100%;
}

input[type=text],
textarea {
  border: 1px solid #d9d9d9;
  border-radius: 0;
}
@media screen and (max-width: 768px) {
  input[type=text],
textarea {
    display: block;
    padding: 10px;
    width: 100%;
  }
}

table {
  width: 100%;
}
@media screen and (max-width: 768px) {
  table tr {
    border-left: solid 1px #dddddd;
    border-right: solid 1px #dddddd;
  }
}
@media screen and (max-width: 768px) {
  table tr:last-child {
    border-bottom: solid 1px #dddddd;
  }
}
table tr th, table tr td {
  vertical-align: middle;
}
@media screen and (max-width: 768px) {
  table tr th, table tr td {
    padding: 15px 15px;
    text-align: left;
  }
}
table tr th {
  background-color: #d8f8ef;
  font-size: 14px;
  font-weight: bold;
}
@media screen and (min-width: 769px) {
  table tr th {
    border: solid 1px #dddddd;
    padding: 12px 15px;
    text-align: left;
    width: 215px;
  }
}
@media screen and (max-width: 768px) {
  table tr th {
    border-bottom: solid 1px #dddddd;
    border-top: solid 1px #dddddd;
  }
}
table tr td {
  font-size: 16px;
  padding: 15px 15px;
}
@media screen and (min-width: 769px) {
  table tr td {
    border: solid 1px #dddddd;
    width: calc(50% - 214px);
  }
}
@media screen and (max-width: 768px) {
  table tr, table th, table td {
    display: block;
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  table.type_b tr {
    border-left: none;
    border-right: none;
  }
}
table.type_b th, table.type_b td {
  border-left: none;
  border-right: none;
}
@media screen and (max-width: 768px) {
  table.type_b th, table.type_b td {
    text-align: left;
  }
}
table.type_b th {
  background: none;
}
@media screen and (min-width: 769px) {
  table.type_b th {
    padding: 15px;
    vertical-align: top;
    width: 263px;
  }
}
table.type_b th span {
  position: relative;
}
@media screen and (min-width: 769px) {
  table.type_b th span {
    font-size: 18px;
    padding-left: 20px;
  }
}
@media screen and (max-width: 768px) {
  table.type_b th span {
    padding-left: 15px;
  }
}
table.type_b th span::before {
  background-color: #56b298;
  bottom: 0;
  content: "";
  left: 0;
  margin: auto;
  position: absolute;
  top: 0;
}
@media screen and (min-width: 769px) {
  table.type_b th span::before {
    height: 26px;
    width: 4px;
  }
}
@media screen and (max-width: 768px) {
  table.type_b th span::before {
    height: 20px;
    width: 3px;
  }
}
@media screen and (min-width: 769px) {
  table.type_b td {
    padding-left: 0;
  }
}
table.type_b td p + p {
  margin-top: 1.6rem;
}

@media screen and (min-width: 769px) {
  .pc {
    display: block;
  }
}
@media screen and (max-width: 768px) {
  .pc {
    display: none;
  }
}

@media screen and (min-width: 769px) {
  .sp {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .sp {
    display: block;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media screen and (min-width: 769px) {
  .block {
    margin-bottom: 45px;
  }
}
@media screen and (max-width: 768px) {
  .block {
    margin-bottom: 30px;
  }
}
.block:last-child {
  margin-bottom: 0;
}

.page_ttl_txt {
  color: #ffffff;
  font-weight: bold;
  letter-spacing: 0.7px;
  margin-bottom: 5px;
  text-align: center;
}
@media screen and (min-width: 769px) {
  .page_ttl_txt {
    font-size: 36px;
  }
}
@media screen and (max-width: 768px) {
  .page_ttl_txt {
    font-size: 24px;
  }
}

.obi_wrap {
  display: flex;
  width: 100%;
}
@media screen and (min-width: 769px) {
  .obi_wrap {
    border-bottom: 4px solid #dddddd;
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 768px) {
  .obi_wrap {
    border-bottom: 3px solid #dddddd;
    margin-bottom: 30px;
  }
}
.obi_wrap .main_ttl {
  color: #575757;
  display: inline-block;
  font-weight: bold;
  position: relative;
  vertical-align: middle;
}
@media screen and (min-width: 769px) {
  .obi_wrap .main_ttl {
    font-size: 28px;
    line-height: 28px;
  }
}
@media screen and (max-width: 768px) {
  .obi_wrap .main_ttl {
    font-size: 20px;
  }
}
.obi_wrap .obi_wrap_l {
  margin-right: 20px;
  padding-bottom: 15px;
  position: relative;
}
@media screen and (max-width: 768px) {
  .obi_wrap .obi_wrap_l {
    padding-bottom: 10px;
  }
}
.obi_wrap .obi_wrap_l::before {
  background: #56b298;
  content: "";
  left: 0;
  position: absolute;
  width: 100%;
}
@media screen and (min-width: 769px) {
  .obi_wrap .obi_wrap_l::before {
    bottom: -4px;
    height: 4px;
  }
}
@media screen and (max-width: 768px) {
  .obi_wrap .obi_wrap_l::before {
    bottom: -3px;
    height: 3px;
  }
}
.obi_wrap .obi_wrap_r {
  padding-bottom: 15px;
}
@media screen and (min-width: 769px) {
  .obi_wrap .obi_wrap_r {
    line-height: 28px;
    vertical-align: middle;
  }
}
.obi_wrap .sub_ttl {
  margin-right: 20px;
  vertical-align: middle;
}
@media screen and (min-width: 769px) {
  .obi_wrap .sub_ttl {
    font-size: 18px;
    line-height: 30px;
  }
}
@media screen and (max-width: 768px) {
  .obi_wrap .sub_ttl {
    display: block;
    font-size: 14px;
    margin-bottom: 5px;
  }
}
.obi_wrap .cat {
  display: inline-block;
}
.obi_wrap .cat span {
  background-color: #bdbdbd;
  border-radius: 4px;
  color: #ffffff;
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  line-height: 28px;
  margin-right: 3px;
  padding: 0 15px;
  vertical-align: middle;
}
@media screen and (max-width: 768px) {
  .obi_wrap .cat span {
    font-size: 12px;
    line-height: 20px;
  }
}
.obi_wrap .cat span:last-child {
  margin-right: 0;
}
@media screen and (min-width: 769px) {
  .obi_wrap .cat.sp {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .obi_wrap .cat.sp {
    display: block;
  }
}

.bg_txt {
  background: #f7f7f7;
  display: block;
  font-weight: bold;
}
@media screen and (min-width: 769px) {
  .bg_txt {
    font-size: 18px;
    margin-bottom: 30px;
    padding: 13px;
  }
}
@media screen and (max-width: 768px) {
  .bg_txt {
    font-size: 14px;
    margin-bottom: 20px;
    padding: 10px;
  }
}

.green_txt {
  color: #56b298;
  font-weight: bold;
  line-height: 1.46;
  margin-bottom: 10px;
}
@media screen and (min-width: 769px) {
  .green_txt {
    font-size: 24px;
  }
}
@media screen and (max-width: 768px) {
  .green_txt {
    font-size: 18px;
  }
}

.green_bdr_txt {
  border-bottom: 1px solid #dddddd;
  color: #56b298;
  font-weight: bold;
  line-height: 1.46;
}
@media screen and (min-width: 769px) {
  .green_bdr_txt {
    font-size: 24px;
    margin-bottom: 15px;
    padding-bottom: 15px;
  }
}
@media screen and (max-width: 768px) {
  .green_bdr_txt {
    font-size: 18px;
    margin-bottom: 10px;
    padding-bottom: 10px;
  }
}

.bold_txt {
  font-weight: bold;
  margin-bottom: 15px;
}

.select_wrapper {
  border: 1px solid #dadada;
  overflow: hidden;
  position: relative;
  vertical-align: middle;
}
@media screen and (min-width: 769px) {
  .select_wrapper {
    display: inline-block;
  }
}
@media screen and (max-width: 768px) {
  .select_wrapper {
    display: block;
  }
}
.select_wrapper select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: none transparent;
  border: 0;
  border-radius: 0;
  color: #1e1e1e;
  font-size: 16px;
  line-height: 46px;
  margin: 0;
  padding: 0;
  padding-left: 20px;
  vertical-align: middle;
  width: 100%;
}
.select_wrapper::before {
  background: #eeeeee;
  bottom: 0;
  content: "";
  height: 100%;
  margin: auto;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  width: 50px;
}
.select_wrapper::after {
  background-image: url("../../../images/portal/common/arrow.png");
  background-repeat: no-repeat;
  background-size: contain;
  content: "";
  height: 12px;
  position: absolute;
  right: 20px;
  top: 18px;
  transform: rotate(90deg);
  width: 8px;
  z-index: 10;
}

.contact_link_area {
  background-color: #fbfbfb;
  display: none;
  text-align: center;
}
@media screen and (min-width: 769px) {
  .contact_link_area {
    padding: 35px 0 45px 0;
  }
}
@media screen and (max-width: 768px) {
  .contact_link_area {
    padding: 30px 10px;
  }
}
.contact_link_area p {
  margin-bottom: 20px;
}
@media screen and (min-width: 769px) {
  .contact_link_area p {
    font-size: 18px;
  }
}
@media screen and (min-width: 769px) {
  .contact_link_area ul {
    margin: 0 auto;
    overflow: hidden;
    width: 975px;
  }
}
@media screen and (min-width: 769px) {
  .contact_link_area ul li {
    float: left;
    margin-right: 35px;
    width: 470px;
  }
}
@media screen and (max-width: 768px) {
  .contact_link_area ul li {
    margin-bottom: 10px;
  }
}
.contact_link_area ul li a {
  background: #ff6f21;
  color: #ffffff;
  display: block;
  font-weight: bold;
  position: relative;
}
@media screen and (min-width: 769px) {
  .contact_link_area ul li a {
    border-radius: 10px;
    font-size: 28px;
    line-height: 84px;
  }
}
@media screen and (max-width: 768px) {
  .contact_link_area ul li a {
    border-radius: 6px;
    font-size: 16px;
    line-height: 42px;
    padding-left: 20px;
  }
}
.contact_link_area ul li a::before {
  background-image: url("../../../images/portal/common/icn_mail.png");
  background-repeat: no-repeat;
  background-size: contain;
  bottom: 0;
  content: "";
  height: 20px;
  margin: auto;
  position: absolute;
  top: 0;
  width: 24px;
}
@media screen and (min-width: 769px) {
  .contact_link_area ul li a::before {
    left: 30px;
  }
}
@media screen and (max-width: 768px) {
  .contact_link_area ul li a::before {
    left: 12px;
  }
}
@media screen and (min-width: 769px) {
  .contact_link_area ul li:first-child a {
    padding-left: 20px;
  }
}
.contact_link_area ul li:last-child {
  margin-bottom: 0;
  margin-right: 0;
}
.contact_link_area ul li:last-child a::before {
  background-image: url("../../../images/portal/common/icn_tel.png");
  background-repeat: no-repeat;
  background-size: contain;
  height: 30px;
  width: 18px;
}
@media screen and (min-width: 769px) {
  .contact_link_area ul li:last-child a::before {
    left: 40px;
  }
}
@media screen and (max-width: 768px) {
  .contact_link_area ul li:last-child a::before {
    left: 15px;
  }
}

@media screen and (min-width: 769px) {
  .list_wrap.type_a ul li {
    margin-bottom: 80px;
  }
}
@media screen and (max-width: 768px) {
  .list_wrap.type_a ul li {
    margin-bottom: 50px;
  }
}
@media screen and (min-width: 769px) {
  .list_wrap.type_b ul li {
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 768px) {
  .list_wrap.type_b ul li {
    margin-bottom: 30px;
  }
}
.list_wrap.type_b ul li .txt_box {
  text-align: left;
}
.list_wrap ul {
  overflow: hidden;
}
@media screen and (min-width: 769px) {
  .list_wrap ul {
    margin-bottom: 60px;
  }
}
@media screen and (max-width: 768px) {
  .list_wrap ul {
    margin-bottom: 30px;
  }
}
@media screen and (min-width: 769px) {
  .list_wrap ul li {
    float: left;
    margin-right: 24px;
    width: 257px;
  }
}
.list_wrap ul li:nth-child(4n) {
  margin-right: 0;
}
@media screen and (max-width: 768px) {
  .list_wrap ul li:last-child {
    margin-bottom: 0;
  }
}
.list_wrap ul li.soldout a {
  pointer-events: none;
}
.list_wrap ul li.soldout a .img_box {
  background: #000000;
}
.list_wrap ul li.soldout a .img_box img {
  opacity: 0.5;
}
.list_wrap ul li a {
  display: block;
}
.list_wrap ul li .img_box {
  background: #dbdbdb;
  display: table;
  position: relative;
  width: 257px;
}
@media screen and (min-width: 769px) {
  .list_wrap ul li .img_box {
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 768px) {
  .list_wrap ul li .img_box {
    margin: 0 auto 10px;
  }
}
.list_wrap ul li .img_box .new {
  background: #ff0000;
  color: #ffffff;
  display: inline-block;
  font-size: 12px;
  left: 0;
  padding: 2px 10px;
  position: absolute;
  top: 0;
}
.list_wrap ul li .img_box .img_box_contents {
  display: table-cell;
  margin: 0 auto;
  text-align: center;
  vertical-align: middle;
  width: 100%;
}
@media screen and (min-width: 769px) {
  .list_wrap ul li .img_box .img_box_contents {
    height: 190px;
  }
}
@media screen and (max-width: 768px) {
  .list_wrap ul li .img_box .img_box_contents {
    height: 190px;
  }
}
.list_wrap ul li .img_box .img_box_contents img {
  vertical-align: middle;
}
@media screen and (min-width: 769px) {
  .list_wrap ul li .img_box .img_box_contents img {
    max-height: 191px;
    max-width: 257px;
  }
}
@media screen and (max-width: 768px) {
  .list_wrap ul li .img_box .img_box_contents img {
    max-height: 190px;
    max-width: 257px;
  }
}
.list_wrap ul li .txt_box {
  text-align: center;
}
.list_wrap ul li .txt_box .green_txt {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.38;
}
.list_wrap ul li .txt_box .info_txt {
  margin-bottom: 10px;
}
.list_wrap ul li .txt_box .info_txt .info_store_name {
  display: block;
  font-size: 14px;
}
.list_wrap ul li .txt_box .info_txt .info_item_name {
  display: block;
  font-size: 14px;
}
.list_wrap ul li .txt_box .price_txt {
  display: table;
  margin: 0 auto 10px;
  text-align: center;
}
.list_wrap ul li .txt_box .price_txt .icn_include_price,
.list_wrap ul li .txt_box .price_txt .icn_only_price {
  border-radius: 6px;
  color: #ffffff;
  display: table-cell;
  font-size: 11px;
  font-weight: bold;
  height: 38px;
  letter-spacing: -0.3px;
  line-height: 1.17;
  text-align: center;
  vertical-align: middle;
  width: 50px;
}
.list_wrap ul li .txt_box .price_txt .icn_include_price.icn_include_price,
.list_wrap ul li .txt_box .price_txt .icn_only_price.icn_include_price {
  background: #ff6f21;
}
.list_wrap ul li .txt_box .price_txt .icn_include_price.icn_only_price,
.list_wrap ul li .txt_box .price_txt .icn_only_price.icn_only_price {
  background: #56b298;
}
.list_wrap ul li .txt_box .price_txt .price {
  color: #ff6f21;
  display: inline-block;
  font-size: 30px;
  font-weight: bold;
  line-height: 38px;
  margin-left: 10px;
  vertical-align: middle;
}
.list_wrap ul li .txt_box .price_txt .price .tax {
  color: #1e1e1e;
  font-size: 12px;
  font-weight: normal;
  margin-left: 4px;
  vertical-align: middle;
}
.list_wrap ul li .txt_box .list_price {
  font-size: 12px;
  margin-bottom: 5px;
}
.list_wrap ul li .txt_box .cat_txt {
  display: inline-block;
}
.list_wrap ul li .txt_box .cat_txt span {
  background-color: #bdbdbd;
  border-radius: 4px;
  color: #ffffff;
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  line-height: 28px;
  margin: 3px;
  padding: 0 15px;
  vertical-align: middle;
}
@media screen and (max-width: 768px) {
  .list_wrap ul li .txt_box .cat_txt span {
    font-size: 12px;
    line-height: 20px;
  }
}
.list_wrap ul li .txt_box .cat_txt span:last-child {
  margin-right: 0;
}
.list_wrap ul a:nth-child(4n) li {
  margin-right: 0;
}
.list_wrap .btn-md {
  margin: 0 auto 0;
  max-width: 426px;
}

.modal_img_list.list_wrap ul li {
  margin-bottom: 30px;
}
.modal_img_list.list_wrap ul li:last-child {
  margin-bottom: 0;
}
@media screen and (min-width: 769px) {
  .modal_img_list.list_wrap ul a:nth-child(n+5) {
    margin-top: 30px;
  }
}
@media screen and (max-width: 768px) {
  .modal_img_list.list_wrap ul a {
    float: left;
    margin-bottom: 30px;
    width: 49%;
  }
  .modal_img_list.list_wrap ul a:nth-child(2n-1) {
    margin-right: 2%;
  }
  .modal_img_list.list_wrap ul a li .img_box {
    margin: 0;
    width: auto;
  }
  .modal_img_list.list_wrap ul a li .img_box .img_box_contents {
    height: calc(38vw - 20px);
  }
  .modal_img_list.list_wrap ul a li .img_box .img_box_contents img {
    width: 100%;
  }
}

.lg-backdrop.in {
  opacity: 0.7;
}

.lg-slide .lg-toolbar #lg-download {
  display: none;
}
.lg-slide .lg-actions .lg-prev, .lg-slide .lg-actions .lg-next {
  background-color: #000000;
  font-size: 30px;
  padding: 10px 10px 11px;
  top: 48.5%;
}
.lg-slide .lg-actions .lg-prev {
  left: calc(50% - 324px);
}
.lg-slide .lg-actions .lg-next {
  right: calc(50% - 324px);
}

.serch_sidebar {
  position: relative;
  z-index: 100;
}
.serch_sidebar .serch_sidebar_contents {
  background: #ffffff;
  position: fixed;
  right: -100%;
  top: 0;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  width: 615px;
  z-index: 2;
}
@media screen and (min-width: 769px) {
  .serch_sidebar .serch_sidebar_contents {
    height: 100vh;
    padding: 35px 40px;
    width: 615px;
  }
}
@media screen and (max-width: 768px) {
  .serch_sidebar .serch_sidebar_contents {
    max-height: calc(100vh - 20px);
    overflow-y: auto;
    padding: 20px;
    top: 10px;
    width: calc(100% - 20px);
  }
}
.serch_sidebar.active {
  display: block;
}
.serch_sidebar.active .serch_sidebar_bg {
  background-color: rgba(0, 0, 0, 0.75);
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
}
.serch_sidebar.active .serch_sidebar_contents {
  right: 0;
}
@media screen and (max-width: 768px) {
  .serch_sidebar.active .serch_sidebar_contents {
    left: 0;
    margin: 0 auto;
    top: 10px;
  }
}
@media screen and (min-width: 769px) {
  .serch_sidebar.active .serch_sidebar_contents ul {
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 768px) {
  .serch_sidebar.active .serch_sidebar_contents ul {
    margin-bottom: 30px;
  }
}
.serch_sidebar.active .serch_sidebar_contents ul li {
  overflow: hidden;
}
@media screen and (min-width: 769px) {
  .serch_sidebar.active .serch_sidebar_contents ul li {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 768px) {
  .serch_sidebar.active .serch_sidebar_contents ul li {
    margin-bottom: 15px;
  }
}
.serch_sidebar.active .serch_sidebar_contents ul li:last-child {
  margin-bottom: 0;
}
@media screen and (min-width: 769px) {
  .serch_sidebar.active .serch_sidebar_contents ul li span {
    float: left;
    font-size: 18px;
    line-height: 50px;
    width: calc(100% - 350px);
  }
}
@media screen and (max-width: 768px) {
  .serch_sidebar.active .serch_sidebar_contents ul li span {
    display: block;
    margin-bottom: 10px;
  }
}
.serch_sidebar.active .serch_sidebar_contents ul li input[type=text] {
  font-size: 16px;
  height: 50px;
  padding: 10px;
  vertical-align: middle;
}
.serch_sidebar.active .serch_sidebar_contents ul li .checkbox_wrapper label {
  margin-right: 10px;
}
@media screen and (min-width: 769px) {
  .serch_sidebar.active .serch_sidebar_contents ul li .checkbox_wrapper label {
    line-height: 50px;
  }
}
@media screen and (max-width: 768px) {
  .serch_sidebar.active .serch_sidebar_contents ul li .checkbox_wrapper label {
    line-height: 20px;
  }
}
.serch_sidebar.active .serch_sidebar_contents ul li .checkbox_wrapper label:last-child {
  margin-right: 0;
}
.serch_sidebar.active .serch_sidebar_contents ul li .checkbox_wrapper label input[type=radio] {
  margin: 0;
  margin-right: 5px;
}
@media screen and (min-width: 769px) {
  .serch_sidebar.active .serch_sidebar_contents ul li input[type=text], .serch_sidebar.active .serch_sidebar_contents ul li .select_wrapper, .serch_sidebar.active .serch_sidebar_contents ul li .checkbox_wrapper {
    display: inline-block;
    float: right;
    width: 350px;
  }
}
@media screen and (max-width: 768px) {
  .serch_sidebar.active .serch_sidebar_contents ul li input[type=text], .serch_sidebar.active .serch_sidebar_contents ul li .select_wrapper, .serch_sidebar.active .serch_sidebar_contents ul li .checkbox_wrapper {
    display: block;
    width: 100%;
  }
}
.serch_sidebar.active .btn-org-md {
  margin: 0 auto 20px;
}
.serch_sidebar.active .close_btn {
  display: block;
  text-align: right;
}
.serch_sidebar.active .close_btn span {
  background: #777777;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-size: 20px;
  font-weight: bold;
  line-height: 40px;
  text-align: center;
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  width: 40px;
}
.serch_sidebar.active .close_btn span:hover {
  opacity: 0.5;
}

@media screen and (min-width: 769px) {
  .store_block {
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 768px) {
  .store_block {
    margin-bottom: 20px;
  }
}
.store_block:last-child {
  margin-bottom: 0;
}
.store_block .store_contents {
  border: 1px solid #dddddd;
  margin-bottom: 20px;
  overflow: hidden;
}
@media screen and (min-width: 769px) {
  .store_block .store_contents {
    padding: 20px;
  }
}
@media screen and (max-width: 768px) {
  .store_block .store_contents {
    padding: 10px;
  }
}
.store_block .store_contents:last-child {
  margin-bottom: 0;
}
@media screen and (min-width: 769px) {
  .store_block .store_contents .store_contents_l {
    float: left;
    width: 320px;
  }
}
@media screen and (max-width: 768px) {
  .store_block .store_contents .store_contents_l {
    margin: 0 auto 10px;
    width: 225px;
  }
}
.store_block .store_contents .store_contents_l .img_box {
  background: #dddddd;
  display: table;
  margin-bottom: 10px;
  width: 100%;
}
.store_block .store_contents .store_contents_l .img_box .img_box_contents {
  display: table-cell;
  margin: 0 auto;
  position: relative;
  text-align: center;
  vertical-align: middle;
  width: 100%;
}
@media screen and (min-width: 769px) {
  .store_block .store_contents .store_contents_l .img_box .img_box_contents {
    height: 213px;
  }
}
@media screen and (max-width: 768px) {
  .store_block .store_contents .store_contents_l .img_box .img_box_contents {
    height: 150px;
  }
}
.store_block .store_contents .store_contents_l .img_box .img_box_contents img {
  vertical-align: middle;
}
@media screen and (min-width: 769px) {
  .store_block .store_contents .store_contents_l .img_box .img_box_contents img {
    max-height: 216px;
    max-width: 320px;
  }
}
@media screen and (max-width: 768px) {
  .store_block .store_contents .store_contents_l .img_box .img_box_contents img {
    max-height: 150px;
    max-width: 100%;
  }
}
@media screen and (min-width: 769px) {
  .store_block .store_contents .store_contents_r {
    float: left;
    padding-left: 30px;
    width: calc(100% - 320px);
  }
}
@media screen and (min-width: 769px) {
  .store_block .store_contents .store_contents_r .store_info {
    margin-bottom: 25px;
  }
}
@media screen and (max-width: 768px) {
  .store_block .store_contents .store_contents_r .store_info {
    margin-bottom: 20px;
  }
}
.store_block .store_contents .store_contents_r .btn-lg {
  max-width: 100%;
}
@media screen and (min-width: 769px) {
  .store_block .store_contents .store_contents_r .btn-lg {
    padding-left: 45px;
  }
}
@media screen and (max-width: 768px) {
  .store_block .store_contents .store_contents_r .btn-lg {
    font-size: 14px;
    line-height: 42px;
    margin-bottom: 15px;
    padding: 0 0 0 35px;
  }
}
.store_block .store_contents .store_contents_r .btn-lg span {
  position: relative;
}
.store_block .store_contents .store_contents_r .btn-lg span::before {
  background-image: url("../../../images/portal/common/icn_store.png");
  background-repeat: no-repeat;
  background-size: contain;
  bottom: 0;
  content: "";
  margin: auto;
  position: absolute;
  top: 0;
}
@media screen and (min-width: 769px) {
  .store_block .store_contents .store_contents_r .btn-lg span::before {
    height: 30px;
    left: -45px;
    width: 30px;
  }
}
@media screen and (max-width: 768px) {
  .store_block .store_contents .store_contents_r .btn-lg span::before {
    height: 20px;
    left: -30px;
    width: 20px;
  }
}
.store_block .no_store_txt {
  display: block;
  text-align: center;
}
@media screen and (min-width: 769px) {
  .store_block .no_store_txt {
    font-size: 18px;
    margin-top: 30px;
  }
}

@media screen and (min-width: 769px) {
  .store_info ul li {
    margin-bottom: 0;
    overflow: hidden;
  }
}
@media screen and (max-width: 768px) {
  .store_info ul li {
    margin-bottom: 10px;
  }
}
.store_info ul li span {
  font-weight: bold;
}
@media screen and (min-width: 769px) {
  .store_info ul li span {
    float: left;
    margin-bottom: 0;
    width: 170px;
  }
}
@media screen and (max-width: 768px) {
  .store_info ul li span {
    display: block;
    margin-bottom: 5px;
  }
}
@media screen and (min-width: 769px) {
  .store_info ul li p {
    float: left;
    padding-left: 20px;
    width: calc(100% - 170px);
  }
}

@media screen and (min-width: 769px) {
  .freetxt_area {
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 768px) {
  .freetxt_area {
    margin-bottom: 30px;
  }
}

.price_type {
  display: block;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
}
@media screen and (max-width: 768px) {
  .price_type {
    margin-top: 15px;
  }
}
.price_type:last-of-type {
  margin-top: 15px;
}
.price_type.partner {
  color: #ff6f21;
}

.caution {
  border: 1px solid #dddddd;
  margin-bottom: 20px;
  overflow: hidden;
}
@media screen and (min-width: 769px) {
  .caution {
    margin-bottom: 50px;
    padding: 20px;
    text-align: center;
  }
}
@media screen and (max-width: 768px) {
  .caution {
    margin-bottom: 30px;
    padding: 10px;
  }
}
.caution .bold_txt {
  color: #56b298;
  font-size: 14px;
  margin-bottom: 10px;
}
.caution p {
  font-size: 12px;
}

/* ==================================================
  カラー
================================================== */
/* ==================================================
  シャドウ
================================================== */
/* ==================================================
  ブレイクポイント
================================================== */
/* ==================================================
  プレースホルダーの色
================================================== */
/* ==================================================
  ボタン：ベース
================================================== */
/* ==================================================
  メディアクエリ
================================================== */
/* ==================================================
  カラー
================================================== */
/* ==================================================
  シャドウ
================================================== */
/* ==================================================
  ブレイクポイント
================================================== */
#flow_purchase .page_img_wrap .txt_box {
  margin-bottom: 24px;
  padding-top: 16px;
}
#flow_purchase .page_img_wrap .txt_box p {
  font-size: 15px;
}
#flow_purchase .flow_purchase_block {
  margin-bottom: 50px;
}
#flow_purchase .g_frame_double .g_frame,
#flow_purchase .g_frame_single .g_frame {
  border: 7px solid #35b597;
  border-radius: 5px;
  border-top: 0;
  flex: 1;
  overflow: hidden;
}
@media screen and (min-width: 769px) {
  #flow_purchase .g_frame_double .g_frame:not(:first-child),
#flow_purchase .g_frame_single .g_frame:not(:first-child) {
    margin-left: 20px;
  }
}
@media screen and (max-width: 768px) {
  #flow_purchase .g_frame_double .g_frame:not(:first-child),
#flow_purchase .g_frame_single .g_frame:not(:first-child) {
    margin-top: 20px;
  }
}
#flow_purchase .g_frame_double .g_frame h3,
#flow_purchase .g_frame_single .g_frame h3 {
  background-color: #35b597;
  color: #ffffff;
  font-size: 40px;
  font-weight: bold;
  padding: 10px 10px 6px 10px;
}
@media screen and (max-width: 768px) {
  #flow_purchase .g_frame_double .g_frame h3,
#flow_purchase .g_frame_single .g_frame h3 {
    font-size: 26px;
  }
}
#flow_purchase .g_frame_double .g_frame h3 span,
#flow_purchase .g_frame_single .g_frame h3 span {
  margin-right: 25px;
  vertical-align: middle;
}
@media screen and (max-width: 768px) {
  #flow_purchase .g_frame_double .g_frame h3 span,
#flow_purchase .g_frame_single .g_frame h3 span {
    margin-right: 5px;
  }
}
#flow_purchase .g_frame_double .g_frame h3 span img,
#flow_purchase .g_frame_single .g_frame h3 span img {
  height: 42px;
  width: auto;
}
@media screen and (max-width: 768px) {
  #flow_purchase .g_frame_double .g_frame h3 span img,
#flow_purchase .g_frame_single .g_frame h3 span img {
    height: 35px;
  }
}
@media screen and (min-width: 769px) {
  #flow_purchase .g_frame_double .g_frame .txt_box,
#flow_purchase .g_frame_single .g_frame .txt_box {
    padding: 20px 24px 30px 24px;
  }
}
@media screen and (max-width: 768px) {
  #flow_purchase .g_frame_double .g_frame .txt_box,
#flow_purchase .g_frame_single .g_frame .txt_box {
    padding: 20px;
  }
}
#flow_purchase .g_frame_double .g_frame .txt_box .green_txt,
#flow_purchase .g_frame_single .g_frame .txt_box .green_txt {
  font-size: 28px;
  margin-bottom: 15px;
}
@media screen and (max-width: 768px) {
  #flow_purchase .g_frame_double .g_frame .txt_box .green_txt,
#flow_purchase .g_frame_single .g_frame .txt_box .green_txt {
    font-size: 23px;
  }
}
#flow_purchase .g_frame_double .g_frame .txt_box ul li,
#flow_purchase .g_frame_single .g_frame .txt_box ul li {
  margin-top: 0;
  padding-left: 1em;
  text-indent: -1em;
}
@media screen and (min-width: 769px) {
  #flow_purchase .g_frame_double .g_frame .txt_box ul li,
#flow_purchase .g_frame_single .g_frame .txt_box ul li {
    letter-spacing: -0.8px;
  }
}
#flow_purchase .g_frame_double {
  display: flex;
}
@media screen and (max-width: 768px) {
  #flow_purchase .g_frame_double {
    flex-direction: column;
  }
}
#flow_purchase .g_frame_single .g_frame {
  border-radius: 20px;
}
@media screen and (min-width: 769px) {
  #flow_purchase .g_frame_single .g_frame h3 {
    font-size: 53px;
    text-align: center;
  }
}
@media screen and (max-width: 768px) {
  #flow_purchase .g_frame_single .g_frame h3 {
    font-size: 23px;
  }
}
@media screen and (min-width: 769px) {
  #flow_purchase .green_bdr_txt {
    margin-bottom: 40px;
  }
}
#flow_purchase .flow_purchase_list ul {
  display: flex;
}
@media screen and (max-width: 768px) {
  #flow_purchase .flow_purchase_list ul {
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
#flow_purchase .flow_purchase_list ul .flow_purchase_item:not(:first-child) {
  margin-left: 15px;
}
@media screen and (max-width: 768px) {
  #flow_purchase .flow_purchase_list ul .flow_purchase_item:not(:first-child) {
    margin-left: 0;
  }
}
@media screen and (max-width: 768px) {
  #flow_purchase .flow_purchase_list ul .flow_purchase_item:nth-of-type(n+5) {
    margin-top: 15px;
  }
}
@media screen and (max-width: 768px) {
  #flow_purchase .flow_purchase_list ul .flow_purchase_item {
    width: calc(25% - 7.5px);
  }
}
#flow_purchase .flow_purchase_list ul .flow_purchase_item p {
  text-align: center;
}
@media screen and (max-width: 768px) {
  #flow_purchase .flow_purchase_list ul .flow_purchase_item p {
    font-size: 13px;
  }
}
#flow_purchase .flow_purchase_list .txt_box {
  color: #ce0000;
  margin-top: 30px;
}
#flow_purchase .flow_purchase_list .txt_box p {
  font-size: 15.5px;
}
#flow_purchase .flow_purchase_row {
  display: flex;
}
@media screen and (max-width: 768px) {
  #flow_purchase .flow_purchase_row {
    flex-direction: column;
  }
}
#flow_purchase .flow_purchase_exteria {
  flex: 1;
  text-align: center;
}
@media screen and (min-width: 769px) {
  #flow_purchase .flow_purchase_exteria:not(:first-child) {
    margin-left: 23px;
  }
}
@media screen and (max-width: 768px) {
  #flow_purchase .flow_purchase_exteria:not(:first-child) {
    margin-top: 20px;
  }
}
@media screen and (min-width: 769px) {
  #flow_purchase .flow_purchase_exteria .green_txt {
    font-size: 28px;
    margin-bottom: 20px;
  }
}
#flow_purchase .flow_purchase_exteria .img_box .img_box_contents img {
  vertical-align: bottom;
}
#flow_purchase .flow_purchase_exteria .g_bg_list ul li {
  align-items: center;
  background-color: #c1f0e3;
  border-radius: 5px;
  display: flex;
  font-weight: bold;
  height: 77px;
  justify-content: center;
  vertical-align: baseline;
}
#flow_purchase .flow_purchase_exteria .g_bg_list ul li:not(:first-child) {
  margin-top: 10px;
}
@media screen and (max-width: 768px) {
  #flow_purchase .flow_purchase_exteria .g_bg_list ul li {
    height: 90px;
    padding: 0 10px;
  }
}
#flow_purchase .btn_c {
  display: flex;
  justify-content: center;
  margin-top: 70px;
}
#flow_purchase .btn_c .btn-org-md {
  border-radius: 10px;
  position: relative;
}
@media screen and (min-width: 769px) {
  #flow_purchase .btn_c .btn-org-md {
    font-size: 25px;
    height: 84px;
    max-width: 470px;
    padding: 28px 0 28px 40px;
  }
}
@media screen and (max-width: 768px) {
  #flow_purchase .btn_c .btn-org-md {
    padding: 12px 0;
  }
}
#flow_purchase .btn_c .btn-org-md::before {
  background-image: url("../../../images/portal/flow_purchase/flow_purchase_btn_icon.png");
  background-repeat: no-repeat;
  background-size: auto 47px;
  content: "";
  height: 46px;
  left: 33px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 46px;
}
@media screen and (max-width: 768px) {
  #flow_purchase .btn_c .btn-org-md::before {
    background-size: auto 20px;
    flex-direction: column;
    height: 20px;
    left: 27%;
  }
}
#flow_purchase .question_list li {
  pointer-events: none;
}
@media screen and (min-width: 769px) {
  #flow_purchase .question_list li {
    padding-top: 12px;
  }
}
#flow_purchase .question_list li h4 {
  opacity: 1;
  padding-left: 2em;
  text-indent: -2em;
}
@media screen and (min-width: 769px) {
  #flow_purchase .question_list li h4 {
    font-size: 23px;
    letter-spacing: -0.5px;
  }
}

/* ==================================================
  カラー
================================================== */
/* ==================================================
  シャドウ
================================================== */
/* ==================================================
  ブレイクポイント
================================================== */
/* ==================================================
  プレースホルダーの色
================================================== */
/* ==================================================
  ボタン：ベース
================================================== */
/* ==================================================
  メディアクエリ
================================================== */
/* ==================================================
  カラー
================================================== */
/* ==================================================
  シャドウ
================================================== */
/* ==================================================
  ブレイクポイント
================================================== */
#flow_shopping .square_step ol {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}
@media screen and (min-width: 769px) {
  #flow_shopping .square_step ol {
    width: 1028px;
  }
}
@media screen and (max-width: 768px) {
  #flow_shopping .square_step ol {
    flex-direction: column;
  }
}
#flow_shopping .square_step ol li {
  background-color: #35b597;
  border-radius: 7px;
  color: #ffffff;
  font-weight: bold;
  position: relative;
}
@media screen and (min-width: 769px) {
  #flow_shopping .square_step ol li {
    font-size: 22px;
    height: 181px;
    padding-top: 57px;
    text-align: center;
    width: 185px;
  }
}
@media screen and (max-width: 768px) {
  #flow_shopping .square_step ol li {
    font-size: 18px;
    height: auto;
    margin-bottom: 15px;
    padding: 14px 14px 14px 50px;
    text-align: left;
    width: 100%;
  }
}
#flow_shopping .square_step ol li span {
  border: 2px solid #ffffff;
  border-radius: 100%;
  position: absolute;
  text-align: center;
  transform: translateX(-50%);
}
@media screen and (min-width: 769px) {
  #flow_shopping .square_step ol li span {
    height: 30px;
    left: 50%;
    line-height: 25px;
    top: 18px;
    width: 30px;
  }
}
@media screen and (max-width: 768px) {
  #flow_shopping .square_step ol li span {
    height: 25px;
    left: 10px;
    line-height: 20px;
    top: 15px;
    transform: unset;
    width: 25px;
  }
}
#flow_shopping .flow_shopping_block:not(:first-child) {
  margin-top: 55px;
}
@media screen and (max-width: 768px) {
  #flow_shopping .flow_shopping_block {
    margin-top: 35px;
  }
}
#flow_shopping .flow_shopping_block .green_bdr_txt {
  font-weight: bold;
}
#flow_shopping .flow_shopping_block .img_box {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
}
@media screen and (max-width: 768px) {
  #flow_shopping .flow_shopping_block .img_box {
    flex-direction: column;
    margin: 0 auto;
    width: 70%;
  }
}
#flow_shopping .flow_shopping_block .img_box .img_box_contents {
  width: 349px;
}
@media screen and (max-width: 768px) {
  #flow_shopping .flow_shopping_block .img_box .img_box_contents {
    text-align: center;
    width: 100%;
  }
  #flow_shopping .flow_shopping_block .img_box .img_box_contents:not(:first-child) {
    margin-top: 15px;
  }
}
#flow_shopping .flow_shopping_block .txt_box {
  letter-spacing: -0.5px;
  margin-top: 26px;
}
#flow_shopping .flow_shopping_block .txt_box .txt_indent {
  margin-top: 0;
  padding-left: 2em;
  text-indent: -1em;
}
#flow_shopping .flow_shopping_block .txt_box .mt0 {
  margin-top: 0;
}
#flow_shopping .flow_shopping_block .txt_box:not(:first-child) {
  margin-top: 15px;
}

/* ==================================================
  カラー
================================================== */
/* ==================================================
  シャドウ
================================================== */
/* ==================================================
  ブレイクポイント
================================================== */
/* ==================================================
  プレースホルダーの色
================================================== */
/* ==================================================
  ボタン：ベース
================================================== */
/* ==================================================
  メディアクエリ
================================================== */
/* ==================================================
  カラー
================================================== */
/* ==================================================
  シャドウ
================================================== */
/* ==================================================
  ブレイクポイント
================================================== */
@media screen and (min-width: 769px) {
  .post-page {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 40px;
  }
}
@media screen and (max-width: 768px) {
  .post-page {
    padding-bottom: 30px;
  }
}
.post-page .obi_wrap {
  margin-bottom: 30px;
}
.post-page .wp-pagenavi .pages {
  display: none;
}
.post-page .wp-pagenavi span {
  border: none;
}
.post-page .wp-pagenavi span.current,
.post-page .wp-pagenavi a {
  background-color: #bbbbbb;
  border: 0;
  border-radius: 5px;
  color: #ffffff;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  height: 30px;
  line-height: 80%;
  min-width: 30px;
  padding: 9px 10px;
  text-align: center;
  text-decoration: none;
  transition: background-color ease-in-out 0.2s, box-shadow ease-in-out 0.2s;
  white-space: nowrap;
}
.post-page .wp-pagenavi span.current.current,
.post-page .wp-pagenavi a.current {
  background-color: #35b597;
}

@media screen and (min-width: 769px) {
  .article-list {
    width: calc(100% - 350px);
  }
}
.article-list-item {
  border-bottom: 1px solid #dddddd;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  padding-bottom: 20px;
}
@media screen and (max-width: 768px) {
  .article-list-item {
    margin-bottom: 11px;
  }
}
.article-list-item-thum {
  display: block;
  margin-right: 15px;
}
@media screen and (min-width: 769px) {
  .article-list-item-thum {
    height: 120px;
    width: 120px;
  }
}
@media screen and (max-width: 768px) {
  .article-list-item-thum {
    height: 70px;
    margin-top: 10px;
    width: 70px;
  }
}
.article-list-item-thum img {
  height: auto;
  width: 100%;
}
.article-list-item article {
  flex: 1;
  width: 100%;
}
.article-list-item-time {
  display: block;
}
.article-list-item-time time {
  margin-right: 1em;
}
.article-list-item-time time,
.article-list-item-time p {
  display: inline-block;
  font-size: 14px;
}
.article-list-item-title {
  font-size: 18px;
  line-height: 28px;
}
.article-list-item-title a {
  display: block;
  text-decoration: none;
}
.article-list-item-cat {
  color: #1e1e1e;
  display: inline-block;
  font-size: 12px;
  line-height: 25px;
  margin-left: 10px;
  vertical-align: bottom;
}
.article-list-item-text {
  line-height: 23px;
  margin-bottom: 30px;
  margin-top: 15px;
}
@media screen and (max-width: 768px) {
  .article-list-item-text {
    margin-bottom: 20px;
    margin-top: 0;
  }
}
.article-list-item-button {
  font-size: 14px;
  margin-left: auto;
  padding: 10px 0;
}
@media screen and (min-width: 769px) {
  .article-list-item-button {
    max-width: 180px;
  }
}
@media screen and (max-width: 768px) {
  .article-list-item-button {
    width: 100%;
  }
}
.article-list-item-button a {
  color: #ffffff;
  content: "";
  display: block;
}
@media screen and (min-width: 769px) {
  .article-list-item-button a {
    margin-left: auto;
  }
}
@media screen and (max-width: 768px) {
  .article-list-item-button a {
    margin: 0 auto;
  }
}

@media screen and (min-width: 769px) {
  .info_sidebar {
    margin-left: 50px;
    width: 300px;
  }
}
@media screen and (max-width: 768px) {
  .info_sidebar {
    margin-top: 50px;
    width: 100%;
  }
}
.info_sidebar-title {
  font-size: 24px;
  margin-bottom: 30px;
}
@media screen and (max-width: 768px) {
  .info_sidebar-title {
    margin-bottom: 15px !important;
  }
}
.info_sidebar-title span {
  color: #575757;
  font-size: 24px;
  font-weight: bold;
  line-height: 30px;
  padding-bottom: 17px !important;
}
@media screen and (max-width: 768px) {
  .info_sidebar-title span {
    font-size: 20px;
    padding-bottom: 10px !important;
  }
}
.info_sidebar-title:not(:first-child) {
  margin-top: 60px;
}
@media screen and (max-width: 768px) {
  .info_sidebar-title:not(:first-child) {
    margin-top: 40px;
  }
}
.info_sidebar-list {
  border-bottom: 1px solid #dfdfdf;
  width: 100%;
}
.info_sidebar-list-item,
.info_sidebar-list li {
  border-top: 1px solid #dfdfdf;
}
.info_sidebar-list-item:first-child,
.info_sidebar-list li:first-child {
  border-top: 0;
}
.info_sidebar-list-item a,
.info_sidebar-list li a {
  display: block;
  font-size: 14px;
  line-height: 40px;
  position: relative;
}
.info_sidebar-list-item a::before,
.info_sidebar-list li a::before {
  background-image: url("../../../images/portal/common/arrow.png");
  background-repeat: no-repeat;
  background-size: contain;
  bottom: 0;
  content: "";
  height: 10px;
  margin: auto;
  position: absolute;
  right: 15px;
  top: 0;
  width: 7px;
}

@media screen and (min-width: 769px) {
  .post-single .article {
    width: calc(100% - 350px);
  }
}
.post-single .article .obi_wrap {
  margin-bottom: 10px;
}
.post-single .article-info {
  font-size: 14px;
  margin-bottom: 40px;
}
@media screen and (min-width: 769px) {
  .post-single .article-info {
    margin-bottom: 30px;
  }
}
.post-single .article-info time,
.post-single .article-info p {
  display: inline-block;
}
.post-single .article-thumbnail {
  display: block;
  margin-bottom: 15px;
  width: 100%;
}
@media screen and (min-width: 769px) {
  .post-single .article-thumbnail {
    height: 400px;
    position: relative;
  }
}
@media screen and (min-width: 769px) {
  .post-single .article-thumbnail img {
    height: auto;
    left: 50%;
    max-height: 100%;
    max-width: 100%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: auto;
  }
}
@media screen and (max-width: 768px) {
  .post-single .article-thumbnail img {
    height: auto;
    width: 100%;
  }
}
.post-single .article-content {
  font-size: 16px;
}
.post-single .article-content p {
  display: block;
  margin-bottom: 1em;
  overflow: hidden;
}
.post-single .article-content p img {
  margin-bottom: 20px !important;
}
.post-single .article-content p img:only-child {
  margin-bottom: 0 !important;
}
.post-single .article-content img {
  height: auto;
  max-height: 480px;
  max-width: 640px;
  width: auto;
}
.post-single .article-content strong {
  font-size: 1em;
  font-weight: bold;
}
.post-single .article-content em {
  font-style: italic;
}
.post-single .article-content blockquote {
  display: block;
  -webkit-margin-after: 1em;
  -webkit-margin-before: 1em;
  -webkit-margin-end: 40px;
  -webkit-margin-start: 40px;
}
.post-single .article-content .aligncenter {
  margin: 0 auto;
}
.post-single .article-content .alignright {
  float: right;
}
.post-single .article-content .alignleft {
  float: left;
}
.post-single .article-content .wp-caption {
  font-size: 12px;
  text-align: right;
}
.post-single .article-content .wp-block-image {
  overflow: hidden;
}
@media screen and (min-width: 769px) {
  .post-single .article-content .wp-block-image {
    margin-bottom: 26px;
  }
}
@media screen and (max-width: 768px) {
  .post-single .article-content .wp-block-image {
    margin-bottom: 20px;
  }
}
.post-single .article-content img[class*=wp-image-],
.post-single .article-content img[class*=attachment-] {
  height: auto;
  max-width: 100%;
}
.post-single .article-content h1 {
  border-bottom: 1px solid #dddddd;
  color: #56b298;
  font-size: 32px;
  font-weight: bold;
  line-height: 1.46;
  padding-left: 20px;
  position: relative;
}
@media screen and (min-width: 769px) {
  .post-single .article-content h1 {
    margin-bottom: 15px;
    padding-bottom: 15px;
  }
}
@media screen and (max-width: 768px) {
  .post-single .article-content h1 {
    margin-bottom: 10px;
    padding-bottom: 10px;
  }
}
.post-single .article-content h1::before {
  background-color: #35b597;
  content: "";
  height: calc(100% - 15px);
  left: 0;
  position: absolute;
  top: 0;
  width: 6px;
}
@media screen and (max-width: 768px) {
  .post-single .article-content h1::before {
    height: calc(100% - 10px);
  }
}
.post-single .article-content h2 {
  color: #56b298;
  font-size: 30px;
  font-weight: bold;
  line-height: 1.46;
  margin-bottom: 10px;
}
.post-single .article-content h3 {
  font-size: 28px;
}
.post-single .article-content h4 {
  font-size: 24px;
}
.post-single .article-content h5 {
  font-size: 20px;
}
.post-single .article-content h6 {
  font-size: 18px;
}
.post-single .article-content h1,
.post-single .article-content h2,
.post-single .article-content h3,
.post-single .article-content h4,
.post-single .article-content h5,
.post-single .article-content h6 {
  font-weight: bold;
  margin-bottom: 20px;
}
.post-single .article-content ul {
  list-style: disc;
  padding-left: 2em;
}
.post-single .article-content ul li {
  list-style: inherit;
}
.post-single .article-content ol {
  list-style: decimal;
  padding-left: 1.2em;
}
.post-single .article-content ol li {
  list-style: inherit;
}
.post-single .article-btn-c {
  margin-top: 50px;
}
.post-single .article-btn-c .btn-c {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 768px) {
  .post-single .article-btn-c .btn-c {
    margin-top: 15px;
  }
}
.post-single .article-btn-c .btn-c a:not(:first-child) {
  margin-left: 15px;
}
.post-single .article-btn-c .btn-c a.arrow, .post-single .article-btn-c .btn-c a.arrow-l {
  position: relative;
}
.post-single .article-btn-c .btn-c a.arrow::before, .post-single .article-btn-c .btn-c a.arrow-l::before {
  content: "";
  width: 12px;
  height: 12px;
  display: block;
  position: absolute;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  right: 20px;
  top: 50%;
  transform: rotate(45deg) translateY(-50%);
}
.post-single .article-btn-c .btn-c a.arrow {
  padding-right: 20px;
}
.post-single .article-btn-c .btn-c a.arrow-l {
  padding-left: 20px;
}
.post-single .article-btn-c .btn-c a.arrow-l::before {
  left: 20px;
  border-right: 0;
  border-left: 2px solid #fff;
  transform: rotate(-45deg) translateY(-50%);
}
.post-single .article #pagination {
  margin-top: 40px;
}
.post-single .article #pagination li {
  width: 220px;
}
.post-single .article #pagination li.next {
  margin-left: 10px;
}

/* ==================================================
  カラー
================================================== */
/* ==================================================
  シャドウ
================================================== */
/* ==================================================
  ブレイクポイント
================================================== */
/* ==================================================
  プレースホルダーの色
================================================== */
/* ==================================================
  ボタン：ベース
================================================== */
/* ==================================================
  メディアクエリ
================================================== */
/* ==================================================
  カラー
================================================== */
/* ==================================================
  シャドウ
================================================== */
/* ==================================================
  ブレイクポイント
================================================== */
#information .obi_wrap {
  margin-bottom: 20px;
}
@media screen and (min-width: 769px) {
  #information .block ul {
    padding-left: 20px;
  }
}
@media screen and (max-width: 768px) {
  #information .block ul {
    padding-left: 10px;
  }
}
#information .block ul li {
  padding-left: 2em;
  text-indent: -1.8em;
}

/* ==================================================
  カラー
================================================== */
/* ==================================================
  シャドウ
================================================== */
/* ==================================================
  ブレイクポイント
================================================== */
/* ==================================================
  プレースホルダーの色
================================================== */
/* ==================================================
  ボタン：ベース
================================================== */
/* ==================================================
  メディアクエリ
================================================== */
/* ==================================================
  カラー
================================================== */
/* ==================================================
  シャドウ
================================================== */
/* ==================================================
  ブレイクポイント
================================================== */
.detail_info {
  margin-bottom: 25px;
  overflow: hidden;
}
@media screen and (min-width: 769px) {
  .detail_info .detail_info_l {
    float: left;
    margin-top: 10px;
    width: 540px;
  }
}
@media screen and (max-width: 768px) {
  .detail_info .detail_info_l {
    margin: 0 auto 20px;
    width: 100%;
  }
}
.detail_info .detail_info_l .img_box {
  background: #dddddd;
  display: table;
  margin-bottom: 10px;
  width: 100%;
}
@media screen and (max-width: 768px) {
  .detail_info .detail_info_l .img_box {
    margin: 0 auto 20px;
    width: 271px;
  }
}
.detail_info .detail_info_l .img_box .img_box_contents {
  display: table-cell;
  margin: 0 auto;
  position: relative;
  text-align: center;
  vertical-align: middle;
  width: 100%;
}
@media screen and (min-width: 769px) {
  .detail_info .detail_info_l .img_box .img_box_contents {
    height: 360px;
  }
}
@media screen and (max-width: 768px) {
  .detail_info .detail_info_l .img_box .img_box_contents {
    height: 200px;
  }
}
.detail_info .detail_info_l .img_box .img_box_contents .new {
  background: #ff0000;
  color: #ffffff;
  display: inline-block;
  left: 0;
  padding: 2px 10px;
  position: absolute;
  top: 0;
}
@media screen and (min-width: 769px) {
  .detail_info .detail_info_l .img_box .img_box_contents .new {
    font-size: 20px;
    width: 90px;
  }
}
@media screen and (max-width: 768px) {
  .detail_info .detail_info_l .img_box .img_box_contents .new {
    font-size: 16px;
  }
}
.detail_info .detail_info_l .img_box .img_box_contents img {
  vertical-align: middle;
}
@media screen and (min-width: 769px) {
  .detail_info .detail_info_l .img_box .img_box_contents img {
    max-height: 405px;
    max-width: 540px;
  }
}
@media screen and (max-width: 768px) {
  .detail_info .detail_info_l .img_box .img_box_contents img {
    max-height: 200px;
    max-width: 100%;
  }
}
@media screen and (min-width: 769px) {
  .detail_info .detail_info_r {
    float: right;
    padding-left: 60px;
    width: calc(100% - 540px);
  }
}
.detail_info .detail_info_r .txt_box .green_txt {
  margin-bottom: 5px;
}
@media screen and (min-width: 769px) {
  .detail_info .detail_info_r .txt_box .no_txt {
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 768px) {
  .detail_info .detail_info_r .txt_box .no_txt {
    margin-bottom: 20px;
  }
}
.detail_info .detail_info_r .txt_box .cat_box {
  margin-bottom: 20px;
}
.detail_info .detail_info_r .txt_box .cat_box .bold_txt {
  margin-bottom: 10px;
}
@media screen and (min-width: 769px) {
  .detail_info .detail_info_r .txt_box .cat_box .bold_txt {
    font-size: 18px;
  }
}
@media screen and (min-width: 769px) {
  .detail_info .detail_info_r .txt_box .cat_box ul {
    display: table;
  }
}
@media screen and (max-width: 768px) {
  .detail_info .detail_info_r .txt_box .cat_box ul {
    display: block;
    overflow: hidden;
  }
}
.detail_info .detail_info_r .txt_box .cat_box ul:first-of-type {
  margin-bottom: 20px;
}
.detail_info .detail_info_r .txt_box .cat_box ul li {
  background: #f7f7f7;
  border-radius: 6px;
  line-height: 1.14;
  text-align: center;
  vertical-align: middle;
}
@media screen and (min-width: 769px) {
  .detail_info .detail_info_r .txt_box .cat_box ul li {
    display: inline-block;
    font-size: 14px;
    margin-right: 4px;
  }
}
@media screen and (max-width: 768px) {
  .detail_info .detail_info_r .txt_box .cat_box ul li {
    display: table;
    float: left;
    font-size: 12px;
    margin-bottom: 1.25%;
    margin-right: 1.25%;
    width: 32.5%;
  }
}
@media screen and (max-width: 768px) {
  .detail_info .detail_info_r .txt_box .cat_box ul li:nth-child(3n) {
    margin-right: 0;
  }
}
.detail_info .detail_info_r .txt_box .cat_box ul li span {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}
@media screen and (min-width: 769px) {
  .detail_info .detail_info_r .txt_box .cat_box ul li span {
    height: 53px;
    width: 80px;
  }
}
@media screen and (max-width: 768px) {
  .detail_info .detail_info_r .txt_box .cat_box ul li span {
    height: 40px;
    width: 100%;
  }
}
.detail_info .detail_info_r .txt_box .cat_box ul li:last-child {
  margin-right: 0;
}
.detail_info .detail_info_r .txt_box .cat_box ul li.on {
  background: #56b298;
  color: #ffffff;
  font-weight: bold;
}
@media screen and (min-width: 769px) {
  .detail_info .detail_info_r .txt_box .list_price {
    font-size: 18px;
    margin-bottom: 5px;
  }
}
@media screen and (max-width: 768px) {
  .detail_info .detail_info_r .txt_box .list_price {
    margin-bottom: 5px;
  }
}
.detail_info .detail_info_r .txt_box .price_txt {
  display: table;
  text-align: left;
}
.detail_info .detail_info_r .txt_box .price_txt .icn_include_price,
.detail_info .detail_info_r .txt_box .price_txt .icn_only_price {
  border-radius: 10px;
  color: #ffffff;
  display: table-cell;
  font-weight: bold;
  letter-spacing: -0.5px;
  line-height: 1.17;
  text-align: center;
  vertical-align: middle;
}
@media screen and (min-width: 769px) {
  .detail_info .detail_info_r .txt_box .price_txt .icn_include_price,
.detail_info .detail_info_r .txt_box .price_txt .icn_only_price {
    font-size: 14px;
    height: 60px;
    width: 65px;
  }
}
@media screen and (max-width: 768px) {
  .detail_info .detail_info_r .txt_box .price_txt .icn_include_price,
.detail_info .detail_info_r .txt_box .price_txt .icn_only_price {
    font-size: 12px;
    height: 50px;
    width: 55px;
  }
}
.detail_info .detail_info_r .txt_box .price_txt .icn_include_price.icn_include_price,
.detail_info .detail_info_r .txt_box .price_txt .icn_only_price.icn_include_price {
  background: #ff6f21;
}
.detail_info .detail_info_r .txt_box .price_txt .icn_include_price.icn_only_price,
.detail_info .detail_info_r .txt_box .price_txt .icn_only_price.icn_only_price {
  background: #56b298;
}
.detail_info .detail_info_r .txt_box .price_txt .price {
  color: #ff6f21;
  display: inline-block;
  font-weight: bold;
  margin-left: 15px;
  vertical-align: middle;
}
@media screen and (min-width: 769px) {
  .detail_info .detail_info_r .txt_box .price_txt .price {
    font-size: 50px;
    line-height: 60px;
  }
}
@media screen and (max-width: 768px) {
  .detail_info .detail_info_r .txt_box .price_txt .price {
    font-size: 36px;
    line-height: 50px;
  }
}
.detail_info .detail_info_r .txt_box .price_txt .price .tax {
  color: #1e1e1e;
  font-size: 14px;
  font-weight: normal;
  margin-left: 10px;
  vertical-align: top;
}

@media screen and (min-width: 769px) {
  #panorama {
    margin-bottom: 60px;
  }
}
@media screen and (max-width: 768px) {
  #panorama {
    margin-bottom: 30px;
  }
}
#panorama .panorama_block {
  background: #dddddd;
}
#panorama .panorama_block:not(:first-of-type) {
  margin-top: 30px;
}
#panorama .panorama_block div[id*=viewer] {
  margin: 0 auto;
}
@media screen and (min-width: 769px) {
  #panorama .panorama_block div[id*=viewer] {
    height: 450px;
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  #panorama .panorama_block div[id*=viewer] {
    height: 200px;
    width: 100%;
  }
}
#panorama .panorama_block p {
  background-color: #f7f7f7;
  font-size: 14px;
  padding: 10px 0;
  text-align: center;
}

@media screen and (min-width: 769px) {
  #item_detail .store_block {
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 768px) {
  #item_detail .store_block {
    margin-bottom: 30px;
  }
}
#item_detail .store_block.last {
  margin-bottom: 0;
}
#item_detail .contact_link_area {
  display: block;
}
@media screen and (min-width: 769px) {
  #item_detail .contact_link_area {
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 768px) {
  #item_detail .contact_link_area {
    margin-bottom: 30px;
  }
}
#item_detail .contact_link_area.prev_footer {
  margin-bottom: 0;
}
@media screen and (min-width: 769px) {
  #item_detail .contact_link_area.prev_footer {
    margin-bottom: -100px;
    margin-top: 80px;
  }
}
@media screen and (max-width: 768px) {
  #item_detail .contact_link_area.prev_footer {
    margin-bottom: -60px;
    margin-top: 30px;
  }
}
#item_detail table {
  table-layout: fixed;
}
@media screen and (min-width: 769px) {
  #item_detail table {
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 768px) {
  #item_detail table {
    margin-bottom: 30px;
  }
}
@media screen and (min-width: 769px) {
  #item_detail table td {
    width: calc(50% - 215px);
  }
}
@media screen and (max-width: 768px) {
  #item_detail .price_type {
    margin-bottom: 10px;
  }
}

/* ==================================================
  カラー
================================================== */
/* ==================================================
  シャドウ
================================================== */
/* ==================================================
  ブレイクポイント
================================================== */
/* ==================================================
  プレースホルダーの色
================================================== */
/* ==================================================
  ボタン：ベース
================================================== */
/* ==================================================
  メディアクエリ
================================================== */
/* ==================================================
  カラー
================================================== */
/* ==================================================
  シャドウ
================================================== */
/* ==================================================
  ブレイクポイント
================================================== */
.form_flow {
  text-align: center;
}
@media screen and (min-width: 769px) {
  .form_flow {
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 768px) {
  .form_flow {
    margin-bottom: 30px;
  }
}
@media screen and (min-width: 769px) {
  .form_flow p {
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 768px) {
  .form_flow p {
    margin-bottom: 30px;
  }
}
.form_flow ul {
  display: inline-block;
  overflow: hidden;
}
@media screen and (max-width: 768px) {
  .form_flow ul {
    width: 100%;
  }
}
.form_flow ul li {
  background: #acacac;
  border: 0;
  border-radius: 5px;
  color: #ffffff;
  float: left;
  font-weight: bold;
  position: relative;
  text-align: center;
}
@media screen and (min-width: 769px) {
  .form_flow ul li {
    display: block;
    font-size: 18px;
    line-height: 46px;
    margin-right: 65px;
    width: 280px;
  }
}
@media screen and (max-width: 768px) {
  .form_flow ul li {
    display: none;
  }
}
.form_flow ul li.active {
  background: #56b298;
}
@media screen and (max-width: 768px) {
  .form_flow ul li.active {
    display: block;
    line-height: 43px;
    width: 100%;
  }
}
.form_flow ul li::before {
  background-image: url("../../../images/portal/common/arrow.png");
  background-repeat: no-repeat;
  background-size: contain;
  bottom: 0;
  content: "";
  height: 29px;
  margin: auto;
  position: absolute;
  right: -43px;
  top: 0;
  width: 18px;
}
.form_flow ul li:last-child {
  margin-right: 0;
}
.form_flow ul li:last-child::before {
  display: none;
}

@media screen and (min-width: 769px) {
  .item_block {
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 768px) {
  .item_block {
    margin-bottom: 30px;
  }
}
.item_block .item_contents {
  border: 1px solid #dddddd;
  margin-bottom: 2px;
  overflow: hidden;
}
@media screen and (min-width: 769px) {
  .item_block .item_contents {
    padding: 20px;
  }
}
@media screen and (max-width: 768px) {
  .item_block .item_contents {
    padding: 10px;
  }
}
.item_block .item_contents:last-child {
  margin-bottom: 0;
}
@media screen and (min-width: 769px) {
  .item_block .item_contents .item_contents_l {
    float: left;
    width: 320px;
  }
}
@media screen and (max-width: 768px) {
  .item_block .item_contents .item_contents_l {
    margin: 0 auto 10px;
    width: 225px;
  }
}
.item_block .item_contents .item_contents_l .img_box {
  background: #dddddd;
  display: table;
  margin-bottom: 10px;
  width: 100%;
}
.item_block .item_contents .item_contents_l .img_box .img_box_contents {
  display: table-cell;
  margin: 0 auto;
  position: relative;
  text-align: center;
  vertical-align: middle;
  width: 100%;
}
@media screen and (min-width: 769px) {
  .item_block .item_contents .item_contents_l .img_box .img_box_contents {
    height: 213px;
  }
}
@media screen and (max-width: 768px) {
  .item_block .item_contents .item_contents_l .img_box .img_box_contents {
    height: 150px;
  }
}
.item_block .item_contents .item_contents_l .img_box .img_box_contents img {
  vertical-align: middle;
}
@media screen and (min-width: 769px) {
  .item_block .item_contents .item_contents_l .img_box .img_box_contents img {
    max-height: 215px;
    max-width: 320px;
  }
}
@media screen and (max-width: 768px) {
  .item_block .item_contents .item_contents_l .img_box .img_box_contents img {
    max-height: 150px;
    max-width: 100%;
  }
}
@media screen and (min-width: 769px) {
  .item_block .item_contents .item_contents_r {
    float: left;
    padding-left: 30px;
    width: calc(100% - 320px);
  }
}
.item_block .item_contents .item_contents_r .txt_box {
  text-align: left;
}
.item_block .item_contents .item_contents_r .txt_box .green_txt {
  margin-bottom: 5px;
}
.item_block .item_contents .item_contents_r .txt_box .info_txt {
  margin-bottom: 10px;
}
.item_block .item_contents .item_contents_r .txt_box .info_txt .info_store_name {
  display: inline;
  font-size: 14px;
}
.item_block .item_contents .item_contents_r .txt_box .info_txt .info_item_name {
  display: inline;
  font-size: 14px;
}
.item_block .item_contents .item_contents_r .txt_box .price_txt {
  display: table;
  margin: 0 0 10px;
  text-align: left;
}
.item_block .item_contents .item_contents_r .txt_box .price_txt .icn_include_price,
.item_block .item_contents .item_contents_r .txt_box .price_txt .icn_only_price {
  border-radius: 6px;
  color: #ffffff;
  display: table-cell;
  font-size: 11px;
  font-weight: bold;
  height: 38px;
  letter-spacing: -0.3px;
  line-height: 1.17;
  text-align: center;
  vertical-align: middle;
  width: 50px;
}
.item_block .item_contents .item_contents_r .txt_box .price_txt .icn_include_price.icn_include_price,
.item_block .item_contents .item_contents_r .txt_box .price_txt .icn_only_price.icn_include_price {
  background: #ff6f21;
}
.item_block .item_contents .item_contents_r .txt_box .price_txt .icn_include_price.icn_only_price,
.item_block .item_contents .item_contents_r .txt_box .price_txt .icn_only_price.icn_only_price {
  background: #56b298;
}
.item_block .item_contents .item_contents_r .txt_box .price_txt .price {
  color: #ff6f21;
  display: inline-block;
  font-size: 34px;
  font-weight: bold;
  line-height: 38px;
  margin-left: 10px;
  vertical-align: middle;
}
.item_block .item_contents .item_contents_r .txt_box .price_txt .price .tax {
  color: #1e1e1e;
  font-size: 16px;
  font-weight: normal;
  margin-left: 4px;
  vertical-align: top;
}
.item_block .item_contents .item_contents_r .txt_box .list_price {
  font-size: 16px;
  margin-bottom: 5px;
}
.item_block .item_contents .item_contents_r .txt_box .cat_txt {
  display: inline-block;
}
.item_block .item_contents .item_contents_r .txt_box .cat_txt span {
  background-color: #bdbdbd;
  border-radius: 4px;
  color: #ffffff;
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  line-height: 28px;
  margin: 3px;
  padding: 0 15px;
  vertical-align: middle;
}
@media screen and (max-width: 768px) {
  .item_block .item_contents .item_contents_r .txt_box .cat_txt span {
    font-size: 12px;
    line-height: 20px;
  }
}
.item_block .item_contents .item_contents_r .txt_box .cat_txt span:last-child {
  margin-right: 0;
}

@media screen and (min-width: 769px) {
  .form_block table {
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 768px) {
  .form_block table {
    margin-bottom: 20px;
  }
}
.form_block table tr th {
  text-align: center;
}
@media screen and (min-width: 769px) {
  .form_block table tr th {
    font-size: 18px;
    width: 290px;
  }
}
.form_block table tr th div {
  position: relative;
}
.form_block table tr th div span.required {
  background-color: #ff6f21;
  border-radius: 3px;
  color: #ffffff;
  position: absolute;
  right: 0;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
}
@media screen and (min-width: 769px) {
  .form_block table tr th div span.required {
    font-size: 14px;
    height: 28px;
    padding: 2px 0;
    width: 55px;
  }
}
@media screen and (max-width: 768px) {
  .form_block table tr th div span.required {
    font-size: 12px;
    padding: 3px;
  }
}
@media screen and (min-width: 769px) {
  .form_block table tr td {
    padding: 12px 25px 12px 35px;
  }
}
@media screen and (min-width: 769px) {
  .form_block table tr td input[type=text] {
    margin-right: 28px;
    padding: 10px;
    width: 314px;
  }
}
.form_block table tr td .input[type=radio],
.form_block table tr td .input[type=checkbox] {
  margin-right: 6px;
}
.form_block table tr td textarea {
  font-size: 16px;
  height: 183px;
}
@media screen and (min-width: 769px) {
  .form_block table tr td .form-malti-select {
    padding: 10px 0;
  }
}
@media screen and (max-width: 768px) {
  .form_block table tr td .form-malti-select {
    text-align: left;
  }
}
@media screen and (max-width: 768px) {
  .form_block table tr td .form-malti-select br {
    display: none;
  }
}
.form_block table tr td .form-malti-select label {
  line-height: 25px;
}
@media screen and (max-width: 768px) {
  .form_block table tr td .form-malti-select label {
    display: block;
  }
}
.form_block table tr td .form-malti-select label input {
  margin-right: 10px;
}
@media screen and (min-width: 769px) {
  .form_block table tr td.address_inner input[type=text].w168 {
    margin-left: 15px;
    width: 168px;
  }
}
@media screen and (max-width: 768px) {
  .form_block table tr td.address_inner input[type=text].w168 {
    display: inline-block;
    margin-left: 10px;
    width: calc(100% - 26px);
  }
}
.form_block table tr td.address_inner input[type=text].w400 {
  margin-left: 10px;
}
@media screen and (min-width: 769px) {
  .form_block table tr td.address_inner input[type=text].w400 {
    width: 400px;
  }
}
@media screen and (max-width: 768px) {
  .form_block table tr td.address_inner input[type=text].w400 {
    display: inline-block;
    width: calc(100% - 51px);
  }
}
.form_block table tr td.address_inner input[type=text].w440 {
  width: 440px;
}
@media screen and (max-width: 768px) {
  .form_block table tr td.address_inner input[type=text].w440 {
    display: inline-block;
    margin-left: 10px;
    width: calc(100% - 51px);
  }
}
@media screen and (min-width: 769px) {
  .form_block table tr td.address_inner div {
    display: inline-block;
  }
}
@media screen and (max-width: 768px) {
  .form_block table tr td.address_inner div {
    display: block;
  }
}
.form_block table tr td.address_inner div:not(:last-child) {
  margin-bottom: 10px;
}
@media screen and (max-width: 768px) {
  .form_block table tr td .sample {
    display: block;
    font-size: 14px;
    margin-top: 5px;
  }
}
.form_block .btn-c {
  text-align: center;
}
.form_block .btn-c .form_btn1 {
  position: relative;
}
@media screen and (min-width: 769px) {
  .form_block .btn-c .form_btn1 {
    margin: 0 auto;
    width: 456px;
  }
}
@media screen and (min-width: 769px) {
  .form_block .btn-c .form_btn1 .btn-lg {
    max-width: 100%;
  }
}
.form_block .btn-c .form_btn2 {
  overflow: hidden;
}
@media screen and (min-width: 769px) {
  .form_block .btn-c .form_btn2 {
    margin: 0 auto;
    width: 600px;
  }
}
.form_block .btn-c .form_btn2 li {
  position: relative;
}
@media screen and (min-width: 769px) {
  .form_block .btn-c .form_btn2 li {
    float: left;
    margin-right: 2%;
    width: 49%;
  }
}
@media screen and (max-width: 768px) {
  .form_block .btn-c .form_btn2 li {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .form_block .btn-c .form_btn2 li:first-child {
    margin-bottom: 10px;
  }
}
.form_block .btn-c .form_btn2 li:last-child {
  margin-right: 0;
}
.form_block .btn-c .form_btn2 li input {
  max-width: 100%;
}
.form_block .btn-c .form_btn1::before,
.form_block .btn-c .form_btn2 li::before {
  color: #ffffff;
  content: "＞";
  margin: 0 auto;
  position: absolute;
}
@media screen and (min-width: 769px) {
  .form_block .btn-c .form_btn1::before,
.form_block .btn-c .form_btn2 li::before {
    left: 44px;
    pointer-events: none;
    top: 18px;
    transform: scaleY(1.8);
  }
}
@media screen and (max-width: 768px) {
  .form_block .btn-c .form_btn1::before,
.form_block .btn-c .form_btn2 li::before {
    bottom: 0;
    left: 20px;
    top: 8px;
    transform: scaleY(1);
  }
}

.accept {
  background: #f7f7f7;
  overflow: hidden;
  text-align: center;
}
@media screen and (min-width: 769px) {
  .accept {
    margin-bottom: 60px;
    padding: 20px;
  }
}
@media screen and (max-width: 768px) {
  .accept {
    margin-bottom: 30px;
    padding: 10px;
  }
}
@media screen and (max-width: 768px) {
  .accept label {
    display: flex;
    justify-content: center;
    text-align: center;
  }
}
@media screen and (max-width: 768px) {
  .accept label input {
    margin-top: 6px;
  }
}
.accept label p {
  display: inline-block;
}
@media screen and (max-width: 768px) {
  .accept label p {
    padding-left: 10px;
    text-align: left;
  }
}
.accept label p a {
  text-decoration: underline;
}

/* ==================================================
  カラー
================================================== */
/* ==================================================
  シャドウ
================================================== */
/* ==================================================
  ブレイクポイント
================================================== */
/* ==================================================
  プレースホルダーの色
================================================== */
/* ==================================================
  ボタン：ベース
================================================== */
/* ==================================================
  メディアクエリ
================================================== */
/* ==================================================
  カラー
================================================== */
/* ==================================================
  シャドウ
================================================== */
/* ==================================================
  ブレイクポイント
================================================== */
.item_list_serch_menu {
  text-align: right;
}
@media screen and (min-width: 769px) {
  .item_list_serch_menu {
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 768px) {
  .item_list_serch_menu {
    margin-bottom: 40px;
  }
}
@media screen and (min-width: 769px) {
  .item_list_serch_menu p,
.item_list_serch_menu .btn-org-md,
.item_list_serch_menu .btn-gry-md {
    display: inline-block;
  }
}
@media screen and (max-width: 768px) {
  .item_list_serch_menu p,
.item_list_serch_menu .btn-org-md,
.item_list_serch_menu .btn-gry-md {
    margin-bottom: 10px;
  }
}
.item_list_serch_menu .btn-org-md, .item_list_serch_menu .btn-gry-md {
  font-weight: normal;
}
@media screen and (min-width: 769px) {
  .item_list_serch_menu .btn-org-md, .item_list_serch_menu .btn-gry-md {
    line-height: 54px;
    margin-left: 10px;
    padding: 0;
    width: 270px;
  }
}

/* ==================================================
  カラー
================================================== */
/* ==================================================
  シャドウ
================================================== */
/* ==================================================
  ブレイクポイント
================================================== */
/* ==================================================
  プレースホルダーの色
================================================== */
/* ==================================================
  ボタン：ベース
================================================== */
/* ==================================================
  メディアクエリ
================================================== */
/* ==================================================
  カラー
================================================== */
/* ==================================================
  シャドウ
================================================== */
/* ==================================================
  ブレイクポイント
================================================== */
#privacy .obi_wrap {
  margin-bottom: 20px;
}
@media screen and (min-width: 769px) {
  #privacy .block ul {
    padding-left: 20px;
  }
}
@media screen and (max-width: 768px) {
  #privacy .block ul {
    padding-left: 10px;
  }
}
#privacy .block ul li {
  padding-left: 2em;
  text-indent: -1.8em;
}

/* ==================================================
  カラー
================================================== */
/* ==================================================
  シャドウ
================================================== */
/* ==================================================
  ブレイクポイント
================================================== */
/* ==================================================
  プレースホルダーの色
================================================== */
/* ==================================================
  ボタン：ベース
================================================== */
/* ==================================================
  メディアクエリ
================================================== */
/* ==================================================
  カラー
================================================== */
/* ==================================================
  シャドウ
================================================== */
/* ==================================================
  ブレイクポイント
================================================== */
.question_unker_list {
  overflow: hidden;
}
@media screen and (min-width: 769px) {
  .question_unker_list {
    margin: 0 auto 50px;
    width: 980px;
  }
}
@media screen and (max-width: 768px) {
  .question_unker_list {
    margin-bottom: 20px;
  }
}
.question_unker_list li {
  border-radius: 6px;
  float: left;
  overflow: hidden;
}
@media screen and (min-width: 769px) {
  .question_unker_list li {
    margin-right: 40px;
    width: 300px;
  }
}
@media screen and (max-width: 768px) {
  .question_unker_list li {
    margin-bottom: 10px;
    width: 100%;
  }
}
.question_unker_list li:last-child {
  margin-right: 0;
}
.question_unker_list li a {
  background-color: #56b298;
  color: #ffffff;
  display: block;
  font-weight: bold;
  text-align: center;
}
@media screen and (min-width: 769px) {
  .question_unker_list li a {
    font-size: 18px;
    line-height: 46px;
  }
}
@media screen and (max-width: 768px) {
  .question_unker_list li a {
    line-height: 40px;
  }
}

.question_ttl {
  margin-bottom: 20px;
}

.question_list li {
  border-bottom: 1px solid #dddddd;
  cursor: pointer;
  padding-bottom: 12px;
  padding-top: 10px;
}
.question_list li:hover .green_txt {
  opacity: 0.5;
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}
.question_list li .green_txt {
  display: block;
  margin-bottom: 0;
}
@media screen and (max-width: 768px) {
  .question_list li .green_txt {
    padding-left: 1.8em;
    text-indent: -1.8em;
  }
}
.question_list li p {
  display: none;
  margin-bottom: 5px;
  margin-top: 15px;
}
.question_list li:first-child {
  padding-top: 0;
}
.question_list li.active p {
  display: block;
}

@media screen and (min-width: 769px) {
  #question .block {
    margin-bottom: 60px;
  }
}

/* ==================================================
  カラー
================================================== */
/* ==================================================
  シャドウ
================================================== */
/* ==================================================
  ブレイクポイント
================================================== */
/* ==================================================
  プレースホルダーの色
================================================== */
/* ==================================================
  ボタン：ベース
================================================== */
/* ==================================================
  メディアクエリ
================================================== */
/* ==================================================
  カラー
================================================== */
/* ==================================================
  シャドウ
================================================== */
/* ==================================================
  ブレイクポイント
================================================== */
#store_detail .detail_info {
  margin-bottom: 35px;
}
@media screen and (min-width: 769px) {
  #store_detail .detail_info .detail_info_l .img_box .img_box_contents {
    height: 360px;
  }
}
@media screen and (max-width: 768px) {
  #store_detail .detail_info .detail_info_l .img_box .img_box_contents {
    height: 200px;
  }
}
@media screen and (min-width: 769px) {
  #store_detail .detail_info .detail_info_l .img_box .img_box_contents img {
    height: 360px;
  }
}
@media screen and (max-width: 768px) {
  #store_detail .detail_info .detail_info_l .img_box .img_box_contents img {
    height: 200px;
  }
}
#store_detail .detail_info .detail_info_r .store_info ul li {
  border-top: 1px solid #dddddd;
}
@media screen and (min-width: 769px) {
  #store_detail .detail_info .detail_info_r .store_info ul li {
    padding: 15px 0;
  }
}
@media screen and (max-width: 768px) {
  #store_detail .detail_info .detail_info_r .store_info ul li {
    padding: 10px 0;
  }
}
#store_detail .detail_info .detail_info_r .store_info ul li:last-child {
  border-bottom: 1px solid #dddddd;
}
#store_detail .detail_info .detail_info_r .store_info ul li span, #store_detail .detail_info .detail_info_r .store_info ul li p {
  display: block;
  float: none;
  width: auto;
}
#store_detail .detail_info .detail_info_r .store_info ul li p {
  padding-left: 0;
}
#store_detail .freetxt_area {
  margin-bottom: 70px;
}
#store_detail .store_block {
  margin-bottom: 40px;
}
#store_detail .contact_link_area {
  display: block;
}
@media screen and (min-width: 769px) {
  #store_detail .contact_link_area {
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 768px) {
  #store_detail .contact_link_area {
    margin-bottom: 30px;
  }
}
#store_detail .contact_link_area.prev_footer {
  margin-top: 80px;
}
@media screen and (min-width: 769px) {
  #store_detail .contact_link_area.prev_footer {
    margin-bottom: -100px;
  }
}
@media screen and (max-width: 768px) {
  #store_detail .contact_link_area.prev_footer {
    margin-bottom: -60px;
  }
}

/* ==================================================
  カラー
================================================== */
/* ==================================================
  シャドウ
================================================== */
/* ==================================================
  ブレイクポイント
================================================== */
/* ==================================================
  プレースホルダーの色
================================================== */
/* ==================================================
  ボタン：ベース
================================================== */
/* ==================================================
  メディアクエリ
================================================== */
/* ==================================================
  カラー
================================================== */
/* ==================================================
  シャドウ
================================================== */
/* ==================================================
  ブレイクポイント
================================================== */
.store_list_link {
  text-align: center;
}
@media screen and (min-width: 769px) {
  .store_list_link {
    margin-bottom: 45px;
  }
}
@media screen and (max-width: 768px) {
  .store_list_link {
    margin-bottom: 20px;
  }
}
.store_list_link ul {
  display: inline-block;
  overflow: hidden;
}
.store_list_link ul li {
  display: inline-block;
}
@media screen and (min-width: 769px) {
  .store_list_link ul li {
    margin-right: 50px;
  }
}
@media screen and (max-width: 768px) {
  .store_list_link ul li {
    float: left;
    margin-bottom: 5px;
    width: 50%;
  }
}
.store_list_link ul li:last-child {
  margin-right: 0;
}
.store_list_link ul li:last-child a::before {
  display: none;
}
@media screen and (max-width: 768px) {
  .store_list_link ul li:nth-child(2n) {
    margin-right: 0;
  }
}
@media screen and (max-width: 768px) {
  .store_list_link ul li:nth-child(2n) a::before {
    display: none;
  }
}
.store_list_link ul li a {
  display: block;
  letter-spacing: -0.5px;
  position: relative;
}
@media screen and (min-width: 769px) {
  .store_list_link ul li a {
    font-size: 18px;
  }
}
@media screen and (max-width: 768px) {
  .store_list_link ul li a {
    font-size: 14px;
  }
}
.store_list_link ul li a::before {
  background: #1e1e1e;
  bottom: 0;
  content: "";
  height: 18px;
  margin: auto;
  position: absolute;
  top: 0;
  width: 1px;
}
@media screen and (min-width: 769px) {
  .store_list_link ul li a::before {
    right: -25px;
  }
}
@media screen and (max-width: 768px) {
  .store_list_link ul li a::before {
    right: 0;
  }
}

@media screen and (min-width: 769px) {
  #store_list .bg_txt {
    margin-bottom: 10px;
  }
}

/* ==================================================
  カラー
================================================== */
/* ==================================================
  シャドウ
================================================== */
/* ==================================================
  ブレイクポイント
================================================== */
/* ==================================================
  プレースホルダーの色
================================================== */
/* ==================================================
  ボタン：ベース
================================================== */
/* ==================================================
  メディアクエリ
================================================== */
/* ==================================================
  カラー
================================================== */
/* ==================================================
  シャドウ
================================================== */
/* ==================================================
  ブレイクポイント
================================================== */
.white_border_box {
  background: #00000085;
  left: 50%;
  margin: auto;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  z-index: 10;
}
@media screen and (min-width: 769px) {
  .white_border_box {
    border: solid 8px #ffffff;
    width: 720px;
  }
}
@media screen and (max-width: 768px) {
  .white_border_box {
    border: solid 6px #ffffff;
    width: 280px;
  }
}
.white_border_box .white_border_box_ttl {
  color: #ffffff;
  font-weight: bold;
}
@media screen and (min-width: 769px) {
  .white_border_box .white_border_box_ttl {
    font-size: 66px;
    line-height: 82px;
    padding: 35px 0;
  }
}
@media screen and (max-width: 768px) {
  .white_border_box .white_border_box_ttl {
    display: inline-block;
    font-size: 30px;
    padding: 20px 0;
  }
}

.top_main_img {
  display: none;
  position: relative;
  width: 100%;
}
@media screen and (min-width: 769px) {
  .top_main_img {
    height: 460px;
  }
}
@media screen and (max-width: 768px) {
  .top_main_img {
    height: 300px;
  }
}
.top_main_img .swiper-slide {
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;
}
.top_main_img .swiper-slide.slider1 {
  background-image: url("../../../images/portal/top/main_img01.jpg");
}
.top_main_img .swiper-slide.slider2 {
  background-image: url("../../../images/portal/top/main_img02.jpg");
}
.top_main_img .swiper-slide.slider3 {
  background-image: url("../../../images/portal/top/main_img03.jpg");
}
.top_main_img .swiper-slide.slider4 {
  background-image: url("../../../images/portal/top/main_img04.jpg");
}
.top_main_img .swiper-slide.slider5 {
  background-image: url("../../../images/portal/top/main_img05.jpg");
}

.top_serch {
  background: #56b298;
  color: #ffffff;
  display: none;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .top_serch {
    padding: 30px 10px;
  }
}
@media screen and (min-width: 769px) {
  .top_serch {
    padding: 35px 0 56px;
  }
}
@media screen and (max-width: 768px) {
  .top_serch .page_ttl_txt {
    margin-bottom: 30px;
  }
}
.top_serch p {
  font-size: 18px;
  margin-bottom: 30px;
}
.top_serch .top_serch_contents {
  background: #ffffff;
  margin: 0 auto;
  width: 100%;
}
@media screen and (min-width: 769px) {
  .top_serch .top_serch_contents {
    border: 12px solid #dbdbdb;
    max-width: 1100px;
    padding: 40px 0;
  }
}
@media screen and (max-width: 768px) {
  .top_serch .top_serch_contents {
    border: 6px solid #dbdbdb;
    padding: 30px 10px;
  }
}
@media screen and (min-width: 769px) {
  .top_serch .top_serch_contents .select_area {
    display: inline-block;
    margin-right: 30px;
  }
}
@media screen and (max-width: 768px) {
  .top_serch .top_serch_contents .select_area {
    margin-bottom: 20px;
  }
}
.top_serch .top_serch_contents .select_area span {
  color: #1e1e1e;
  display: inline-block;
  vertical-align: middle;
}
@media screen and (min-width: 769px) {
  .top_serch .top_serch_contents .select_area span {
    line-height: 46px;
    margin-right: 15px;
  }
}
@media screen and (max-width: 768px) {
  .top_serch .top_serch_contents .select_area span {
    margin-bottom: 10px;
  }
}
@media screen and (min-width: 769px) {
  .top_serch .top_serch_contents .select_area .select_wrapper {
    width: 215px;
  }
}
@media screen and (min-width: 769px) {
  .top_serch .top_serch_contents .select_area:last-of-type {
    margin-right: 15px;
  }
}
@media screen and (max-width: 768px) {
  .top_serch .top_serch_contents .select_area:last-of-type {
    margin-bottom: 30px;
  }
}
@media screen and (min-width: 769px) {
  .top_serch .top_serch_contents .select_area:last-of-type .select_wrapper {
    width: 280px;
  }
}
.top_serch .top_serch_contents .btn-org-xs, .top_serch .top_serch_contents .btn-gry-sm {
  vertical-align: middle;
}
@media screen and (min-width: 769px) {
  .top_serch .top_serch_contents .btn-org-xs, .top_serch .top_serch_contents .btn-gry-sm {
    display: inline-block;
    line-height: 46px;
  }
}
@media screen and (max-width: 768px) {
  .top_serch .top_serch_contents .btn-org-xs, .top_serch .top_serch_contents .btn-gry-sm {
    display: block;
    max-width: 100%;
    width: 100%;
  }
}
@media screen and (min-width: 769px) {
  .top_serch .top_serch_contents .btn-org-xs {
    font-size: 18px;
    margin-right: 10px;
    max-width: 100px;
    padding: 0;
  }
}
@media screen and (max-width: 768px) {
  .top_serch .top_serch_contents .btn-org-xs {
    font-size: 16px;
    margin-bottom: 10px;
  }
}
@media screen and (min-width: 769px) {
  .top_serch .top_serch_contents .btn-gry-sm {
    font-size: 14px;
    padding: 0;
    width: 145px;
  }
}
@media screen and (max-width: 768px) {
  .top_serch .top_serch_contents .btn-gry-sm {
    font-size: 16px;
  }
}

.top_link_area {
  display: none;
  overflow: hidden;
}
@media screen and (min-width: 769px) {
  .top_link_area {
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 768px) {
  .top_link_area {
    margin-bottom: 30px;
  }
}
@media screen and (min-width: 769px) {
  .top_link_area .top_link_area_l, .top_link_area .top_link_area_r {
    float: left;
    width: 50%;
  }
}
@media screen and (max-width: 768px) {
  .top_link_area .top_link_area_l, .top_link_area .top_link_area_r {
    width: 100%;
  }
}
.top_link_area .top_link_area_l a, .top_link_area .top_link_area_r a {
  background-image: url("../../../images/portal/top/link_area_l.jpg");
  background-position: bottom center;
  background-size: cover;
  display: block;
  position: relative;
}
@media screen and (min-width: 769px) {
  .top_link_area .top_link_area_l a, .top_link_area .top_link_area_r a {
    height: 180px;
  }
}
@media screen and (max-width: 768px) {
  .top_link_area .top_link_area_l a, .top_link_area .top_link_area_r a {
    height: 180px;
  }
}
.top_link_area .top_link_area_l a .white_border_box, .top_link_area .top_link_area_r a .white_border_box {
  border: 8px solid #ffffff;
}
@media screen and (min-width: 769px) {
  .top_link_area .top_link_area_l a .white_border_box, .top_link_area .top_link_area_r a .white_border_box {
    width: 340px;
  }
}
@media screen and (max-width: 768px) {
  .top_link_area .top_link_area_l a .white_border_box, .top_link_area .top_link_area_r a .white_border_box {
    width: 300px;
  }
}
.top_link_area .top_link_area_l a .white_border_box .white_border_box_ttl, .top_link_area .top_link_area_r a .white_border_box .white_border_box_ttl {
  padding: 0;
}
@media screen and (min-width: 769px) {
  .top_link_area .top_link_area_l a .white_border_box .white_border_box_ttl, .top_link_area .top_link_area_r a .white_border_box .white_border_box_ttl {
    font-size: 24px;
    line-height: 68px;
  }
}
@media screen and (max-width: 768px) {
  .top_link_area .top_link_area_l a .white_border_box .white_border_box_ttl, .top_link_area .top_link_area_r a .white_border_box .white_border_box_ttl {
    font-size: 20px;
    line-height: 60px;
  }
}
.top_link_area .top_link_area_r a {
  background-image: url("../../../images/portal/top/link_area_r.jpg");
  background-position: bottom center;
  background-size: cover;
}

#top .header {
  margin-bottom: 0;
}
@media screen and (min-width: 769px) {
  #top .main_menu ul li a {
    padding: 42px 0;
  }
}
@media screen and (min-width: 769px) and (max-width: 1200px) {
  #top .main_menu ul li a {
    padding: 38px 0;
  }
}
#top .page_ttl {
  display: none;
}
#top .top_main_img, #top .top_serch, #top .top_link_area {
  display: block;
}

.topNews {
  margin-bottom: 50px;
}
.topNews_list {
  margin-bottom: 60px;
}
@media screen and (max-width: 768px) {
  .topNews_list {
    margin-bottom: 30px;
  }
}
.topNews_list_item {
  display: block;
  padding: 15px 0;
  border-bottom: 1px solid #dddddd;
  font-weight: bold;
}
.topNews_list_item:first-child {
  border-top: 1px solid #dddddd;
}
.topNews_list_item span {
  font-weight: normal;
  width: 160px;
  display: inline-block;
}
@media screen and (max-width: 768px) {
  .topNews_list_item span {
    display: block;
    font-size: 0.9em;
    margin-bottom: 5px;
  }
}
.topNews .btn-md {
  margin: 0 auto;
  max-width: 426px;
}

.sdg_logo {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  max-width: 1100px;
}
@media screen and (min-width: 769px) {
  .sdg_logo {
    margin-bottom: 50px;
    display: flex;
    align-items: center;
  }
}
@media screen and (max-width: 768px) {
  .sdg_logo {
    margin-bottom: 30px;
    padding: 0 10px;
  }
}
@media screen and (min-width: 769px) {
  .sdg_logo figure {
    margin-right: 30px;
    flex: 1;
  }
}
@media screen and (max-width: 768px) {
  .sdg_logo figure {
    margin-bottom: 10px;
  }
}