@import "../config/*";


/* ==================================================
   ボタン：ノーマル（グリーン）
================================================== */

.btn-lg {
  @include button($COLOR_PRIMARY);
  @include button-lg;
}

.btn-md {
  @include button($COLOR_PRIMARY);
  @include button-md;
}

.btn-sm {
  @include button($COLOR_PRIMARY);
  @include button-sm;
}

.btn-xs {
  @include button($COLOR_PRIMARY);
  @include button-xs;
}

/* ==================================================
   ボタン：ノーマル（オレンジ）
================================================== */

.btn-org-lg {
  @include button($COLOR_ORANGE);
  @include button-lg;
}

.btn-org-md {
  @include button($COLOR_ORANGE);
  @include button-md;
}

.btn-org-sm {
  @include button($COLOR_ORANGE);
  @include button-sm;
}

.btn-org-xs {
  @include button($COLOR_ORANGE);
  @include button-xs;
}

/* ==================================================
   ボタン：キャンセル系（グレー）
================================================== */

.btn-gry-lg {
  @include button($COLOR_GRAY);
  @include button-lg;
}

.btn-gry-md {
  @include button($COLOR_GRAY);
  @include button-md;
}

.btn-gry-sm {
  @include button($COLOR_GRAY);
  @include button-sm;
}

.btn-gry-xs {
  @include button($COLOR_GRAY);
  @include button-xs;
}
